import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

export default function CiviliteRadioGroupField({ control, name }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl>
          <FormLabel>Civilité</FormLabel>
          <RadioGroup row value={value ? value : ''} onChange={onChange}>
            <FormControlLabel
              value='Monsieur'
              control={<Radio />}
              label='Monsieur'
            />
            <FormControlLabel
              value='Madame'
              control={<Radio />}
              label='Madame'
            />
          </RadioGroup>
        </FormControl>
      )}
    />
  );
}

CiviliteRadioGroupField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};
