import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';

export default function AutoCompleteTextField({
  control,
  name,
  label,
  options,
  required,
  multiple,
  defaultValue,
  freeSolo,
  onInputChange,
  customOnChange,
  InputProps,
  inputProps,
  filterOptions,
  isOptionEqualToValue = (option, value) => option === value,
  getOptionLabel = (option) => {
    if (Array.isArray(option) && option.length === 0) {
      return '';
    }
    return option ? option : '';
  },
  disabled,
}) {

  const getTagLabel = (tagValue, getTagProps) => tagValue.map(
    (option, index) => (
      <Chip
        {...getTagProps({ index })}
        key={getOptionLabel(option)}
        label={getOptionLabel(option)}
      />
    )
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          disabled={disabled}
          required={Boolean(required)}
          multiple={multiple}
          onChange={(_, items) => {
            customOnChange?.(items);
            return onChange(items);
          }}
          loading={!options}
          defaultValue={defaultValue}
          getOptionLabel={getOptionLabel}
          options={options || []}
          isOptionEqualToValue={isOptionEqualToValue}
          value={value || null}
          renderTags={multiple ? getTagLabel : null}
          renderOption={(props, option) => (
            <li {...props} key={option.id || getOptionLabel(option)}>
              {getOptionLabel(option)}
            </li>
          )}
          freeSolo={freeSolo}
          onInputChange={onInputChange}
          filterOptions={filterOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label={label}
              error={!!error}
              value={value || ''}
              helperText={error ? error.message : null}
              required={Boolean(required)}
              inputProps={{
                ...params.inputProps,
                ...inputProps
              }}
              InputProps={{
                ...params.InputProps,
                ...InputProps,
              }}
            />
          )}
        />
      )}
    />
  );
}

AutoCompleteTextField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  freeSolo: PropTypes.bool,
  onInputChange: PropTypes.func,
  customOnChange: PropTypes.func,
  InputProps: PropTypes.object,
  inputProps: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  multiple: PropTypes.bool,
  isOptionEqualToValue: PropTypes.func,
  filterOptions: PropTypes.func,
  getOptionLabel: PropTypes.func,
  disabled: PropTypes.bool,
};
