import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import EssenceParcelleForm from '@/features/item-form/EssenceParcelleForm';

import Container from '@mui/material/Container';

export default function EssenceParcelle() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const emptyItem = {
    type: null,
    unite_gestion: null,
    essence: null,
    proportion: null,
    constats_reprises_essences_list: [],
    programmes_rd_list: [],
  };

  const item = useItem(itemId, 'peuplements_objectifs_essences', emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <EssenceParcelleForm item={item} />}
    </Container>
  );
}
