import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import EntrepriseForm from '@/features/item-form/EntrepriseForm';

import Container from '@mui/material/Container';

export default function Entreprise() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const itemType = 'entreprises';

  const emptyItem = {
    nom: null,
    adresse: null,
    commune: null,
    code_postal: null,
    telephone: null,
    siret: null,
    emploi_insertion: false,
    fonction: null,
    contacts_list: [],
  };

  const item = useItem(itemId, itemType, emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <EntrepriseForm item={item} />}
    </Container>
  );
}
