import React from 'react';

import PropTypes from 'prop-types';

import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import MultilineTextField from '@/components/controlled-fields/text-fields/MultilineTextField';
import TabPanelForm from '@/components/TabPanelForm';

import Grid from '@mui/material/Grid';

export default function EnjeuxEconomiquesTab({
  tabValue,
  propriete,
  form
}) {
  const { control } = form;
  return (
    <TabPanelForm currentValue={tabValue} index={3}>
      {propriete && (
        <form>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={12} md={6}>
              <MultilineTextField
                control={control}
                name='propriete.besoin_acces_supplementaire'
                label='Accessibilité et équipement de la forêt'
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
            <Grid item xs={12} sm={12} md={6}>
              <MultilineTextField
                control={control}
                name='propriete.commentaire_chasse_pression_gibier'
                label='Pression gibier constatée'
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
            <Grid item xs={12} sm={12} md={6}>
              <BaseTextField
                control={control}
                name='propriete.identite_detenteur_droit_chasse'
                maxLength={256}
                label='Identité du détenteur du droit de chasse'
              />
            </Grid>
          </Grid>
        </form>
      )}
    </TabPanelForm>
  );
}

EnjeuxEconomiquesTab.propTypes = {
  tabValue: PropTypes.number.isRequired,
  propriete: PropTypes.object,
  form: PropTypes.object.isRequired,
};
