import { useCallback, useEffect } from 'react';

export default function useIsDifferentAddress(
  fields,
  [setValue, fieldName],
  condition,
) {

  const prepareField = useCallback((field) => field?.toLowerCase().trim());

  useEffect(() => {
    if (!condition) {
      return;
    }

    const result = fields.some(
      ([proprietaireField, proprieteField]) => proprieteField && prepareField(proprietaireField) !== prepareField(proprieteField)
    );

    setValue(fieldName, result, { shouldValidate: true });
  }, [...fields.flat()]);
}