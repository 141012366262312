import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { clearItems, fetchAllItems } from '@/slices/itemSlice';

import useOnUnmount from './useOnUnmount';

export default function useItems(itemType) {
  const dispatch = useDispatch();

  const items = useSelector((store) => store.itemReducer.items[itemType]);

  useEffect(() => {
    !items && dispatch(fetchAllItems(itemType));
  }, [items]);

  useOnUnmount(() => dispatch(clearItems(itemType)));

  return items;
}
