import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import useOnUnmount from '@/hooks/useOnUnmount';

import { clearFilteredItems, fetchFilteredItems } from '@/slices/itemSlice';

export default function useFetchFilteredItems() {

  const dispatch = useDispatch();

  useOnUnmount(() => {
    dispatch(clearFilteredItems());
  });

  return useCallback(({ itemTypes, filter }) => {
    const promisePool = [];
    itemTypes.forEach((itemType) => {
      promisePool.push(
        dispatch(fetchFilteredItems({ itemType, filter }))
      );
    });

    return Promise.all(promisePool);
  });
}