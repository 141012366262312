import React, { useEffect, useState } from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import useDebounce from '@/hooks/useDebounce';

import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

const API_ADRESSE_URL = 'https://api-adresse.data.gouv.fr/search/';

async function getAdresses(search) {
  return axios.get(API_ADRESSE_URL, {
    params: { q: search, limit: 25 },
  });
}

export default function AdresseField({
  fieldName,
  relatedFiedlNames,
  label,
  control,
  required,
  setValue,
  disabled,
}) {
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    async function searchAdresse() {
      const { data } = await getAdresses(debouncedSearchTerm);

      if (!data) {
        return;
      }

      const features = data.features.filter(
        (feature) => feature.properties.type !== 'municipality'
      );

      if (features.length === 0) {
        setValue(fieldName, debouncedSearchTerm, {
          shouldValidate: true,
          shouldDirty: required,
        });
      } else {
        setResults(features);
      }

      setIsSearching(false);
    }

    if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
      setIsSearching(true);
      searchAdresse(debouncedSearchTerm);
    } else {
      setResults([]);
      setIsSearching(false);
    }
  }, [debouncedSearchTerm]);

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{
        maxLength: 256,
        required: required ? 'Adresse requise.' : false,
      }}
      required={required}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          value={value || ''}
          disabled={disabled}
          filterOptions={(options) => options}
          onBlur={(event) => {
            const newValue = event.target.value;
            if (newValue && newValue !== value) {
              onChange(newValue);
            }
          }}
          onChange={(_, feature) => {
            const featProps = feature?.properties;
            onChange(featProps?.name);
            if (featProps) {
              setValue(relatedFiedlNames.codePostal, featProps.postcode, {
                shouldValidate: true,
                shouldDirty: required,
              });
              setValue(relatedFiedlNames.commune, featProps.city, {
                shouldValidate: true,
                shouldDirty: required,
              });
              if (relatedFiedlNames.pays) {
                setValue(relatedFiedlNames.pays, 'France');
              }
            }
            setResults([]);
          }}
          freeSolo
          onInputChange={(event) => {
            if (!event) {
              return;
            }
            setSearchTerm(event.target.value);
          }}
          getOptionLabel={(option) =>
            typeof option === 'object' ? option.properties.name : option
          }
          renderOption={(props, option) => (
            <li {...props} key={option.properties.id}>
              {option.properties.label}
            </li>
          )}
          options={results}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              fullWidth
              required={required}
              value={value ? value : ''}
              error={!!error}
              helperText={error?.message}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isSearching && <CircularProgress size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
}

AdresseField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  relatedFiedlNames: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
