import React from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import useChoices from '@/hooks/useChoices';

import DatePickerField from '@/components/controlled-fields/DatePickerField';
import SelectField from '@/components/controlled-fields/SelectField';
import AreaTextField from '@/components/controlled-fields/text-fields/AreaTextField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

export default function DgdFormDialog({
  editItem,
  open,
  setOpen,
  onSubmit,
}) {
  const choices = useChoices('dgd');

  const DgdForm = ({ currentItem }) => {
    const {
      handleSubmit,
      control,
      formState: { isDirty, isValid },
    } = useForm({
      defaultValues: currentItem || {
        type: null,
        numero: null,
        surface_totale: null,
        date_debut: null,
        date_fin: null,
      },
      reValidateMode: 'onBlur',
      mode: 'onChange',
    });

    return (
      <form>
        <DialogTitle>
          {editItem ? 'Éditer' : 'Créer'} un DGD
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ p: 2 }} spacing={2} alignItems='center'>
            <Grid item xs={12}>
              <SelectField
                control={control}
                name='type'
                label='Type'
                required='Type DGD requis.'
                renderValue={(value) => value.nom}
                values={choices?.types}
                itemMapper={(item) => ({
                  key: item.id,
                  label: item.nom,
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AreaTextField
                control={control}
                name='surface_totale'
                label='Surface totale (ha)'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <BaseTextField
                control={control}
                name='numero'
                label='Numéro'
                maxLength={30}
                required='Numéro requis.'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePickerField
                name='date_debut'
                control={control}
                label='Date de début'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePickerField
                name='date_fin'
                control={control}
                label='Date de fin'
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Annuler</Button>
          <Button
            disabled={!isDirty || !isValid}
            onClick={handleSubmit(onSubmit)}
          >
            {editItem ? 'Éditer' : 'Créer'}
          </Button>
        </DialogActions>
      </form>
    );
  };

  DgdForm.propTypes = {
    currentItem: PropTypes.object,
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DgdForm currentItem={editItem} />
    </Dialog>
  );
}

DgdFormDialog.propTypes = {
  editItem: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};