import React from 'react';

import PropTypes from 'prop-types';

import EssenceFormDialog from './EssenceFormDialog';

export default function EssencePrincipaleFormDialog(props) {
  const essenceProps = { ...props };
  const value = essenceProps.editItem?.[essenceProps.valueProps.name];
  if (value) {
    essenceProps.totalValue -= value;
  }
  return <EssenceFormDialog {...essenceProps} type={'Principale'} />;
}

EssencePrincipaleFormDialog.propTypes = {
  editItem: PropTypes.object,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  totalProportion: PropTypes.number,
};
