import React from 'react';

import PropTypes from 'prop-types';

import MultilineTextField from '@/components/controlled-fields/text-fields/MultilineTextField';
import TabPanelForm from '@/components/TabPanelForm';

import Grid from '@mui/material/Grid';

export default function HistoriqueDeGestionTab({
  tabValue,
  propriete,
  form,
}) {
  const { control } = form;
  return (
    <TabPanelForm currentValue={tabValue} index={5}>
      {propriete && (
        <form>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={12} md={4}>
              <MultilineTextField
                control={control}
                name='propriete.historique_gestion'
                label='Historique de gestion sur la propriété'
              />
            </Grid>
          </Grid>
        </form>
      )}
    </TabPanelForm>
  );
}

HistoriqueDeGestionTab.propTypes = {
  tabValue: PropTypes.number.isRequired,
  propriete: PropTypes.object,
  form: PropTypes.object.isRequired,
};
