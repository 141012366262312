import React from 'react';

import useConfirmationDialog from '@/hooks/useConfirmationDialog';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmationDialog() {
  const { isOpened, confirm, decline, content, title, confirmButton } =
    useConfirmationDialog();

  return (
    <Dialog open={isOpened}>
      <DialogTitle> {title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ whiteSpace: 'pre-wrap' }}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={decline}>Annuler</Button>
        <Button onClick={confirm} autoFocus>
          {confirmButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
