import React from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import useChoices from '@/hooks/useChoices';

import AutoCompleteTextField from '@/components/controlled-fields/text-fields/AutoCompleteTextField';
import NumberTextField from '@/components/controlled-fields/text-fields/NumberTextField';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

export default function EssenceFormDialog({
  editItem,
  open,
  setOpen,
  onSubmit,
  type,
  valueProps,
  totalValue,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const choices = useChoices('essences');

  const EssenceParcelleForm = ({ item }) => {
    const {
      handleSubmit,
      control,
      formState: { isDirty, isValid },
    } = useForm({
      defaultValues: item || {
        type,
        essence: null,
      },
      reValidateMode: 'onBlur',
      mode: 'onChange',
    });

    return (
      <form>
        <DialogTitle>
          {item ? 'Éditer' : 'Créer'} une essence {type}
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ p: 2 }} spacing={2} alignItems='center'>
            <Grid item xs={12}>
              <AutoCompleteTextField
                control={control}
                name='essence'
                required='Essence requise'
                label='Essence'
                options={choices?.essences}
                defaultValue={editItem?.essence}
                getOptionLabel={(essence) =>
                  essence ? essence.nom_vernaculaire : ''
                }
              />
            </Grid>
            <Grid item xs={12}>
              <NumberTextField
                control={control}
                name={valueProps.name}
                pattern={{
                  value: /^\d+([.,]\d{0,2})?$/,
                  message: `${valueProps.verboseName} invalide.`,
                }}
                validate={(value) =>
                  totalValue + Number(value) > 100
                    ? 'La somme est supérieure à 100%.'
                    : null
                }
                label={valueProps.verboseName}
                decimal={2}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            disabled={!isDirty || !isValid}
            onClick={handleSubmit(onSubmit)}
          >
            {item ? 'Éditer' : 'Créer'}
          </Button>
        </DialogActions>
      </form>
    );
  };

  EssenceParcelleForm.propTypes = {
    item: PropTypes.object,
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
      <EssenceParcelleForm item={editItem} />
    </Dialog>
  );
}

EssenceFormDialog.propTypes = {
  editItem: PropTypes.object,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  valueProps: PropTypes.object.isRequired,
  totalValue: PropTypes.number,
};
