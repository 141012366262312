import React from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import useChoices from '@/hooks/useChoices';
import useFormItem from '@/hooks/useFormItem';

import SelectField from '@/components/controlled-fields/SelectField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import MultilineTextField from '@/components/controlled-fields/text-fields/MultilineTextField';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import APIService from '@/APIService';
import FormSubmitButtons from '../FormSubmitButtons';

export default function EssenceForm({ item }) {
  const itemType = 'essences';
  const itemPath = `/${itemType}`;

  const choices = useChoices(itemType);

  const { onCancel, onSubmit } = useFormItem(item.id, itemType, itemPath);

  const customOnSubmit = (value) => {
    APIService.clearCache(itemType);
    onSubmit(value);
  };

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: item,
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  return (
    <Box elevation={3} component={Paper} sx={{ width: '100%', p: 3 }}>
      <Typography mb={2} variant='h5' component='div'>
        {item.id ? 'Édition' : 'Création'} essence
      </Typography>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <BaseTextField
              name='nom_vernaculaire'
              label='Nom vernaculaire'
              control={control}
              maxLength={256}
              required='Nom vernaculaire requis.'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <BaseTextField
              name='nom_latin'
              label='Nom latin'
              control={control}
              maxLength={256}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SelectField
              control={control}
              name='type'
              label='Type'
              renderValue={(value) => value.nom}
              values={choices?.types}
              itemMapper={(type) => ({
                key: type.id,
                label: type.nom,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <MultilineTextField
              name='description'
              label='Description'
              control={control}
            />
          </Grid>
        </Grid>
        <FormSubmitButtons
          onSubmit={handleSubmit(customOnSubmit)}
          submitLabel={item.id ? 'Éditer' : 'Créer'}
          disabledSubmit={!isDirty || !isValid}
          onCancel={onCancel}
        />
      </form>
    </Box>
  );
}

EssenceForm.propTypes = {
  item: PropTypes.object.isRequired,
};
