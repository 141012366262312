import React from 'react';

import PropTypes from 'prop-types';

export default function TabPanel({ children, currentValue, index }) {
  return (
    currentValue === index && <>{children}</>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  currentValue: PropTypes.number.isRequired,
};
