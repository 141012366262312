import React from 'react';

import PropTypes from 'prop-types';

import AutoCompleteSearchField from '@/features/AutoCompleteSearchField';

export default function ProprieteAutocompleteSearchField({
  required,
  propriete,
  setPropriete,
}) {
  return (
    <AutoCompleteSearchField
      label='Propriété'
      required={required && 'Propriété requise.'}
      itemState={[propriete, setPropriete]}
      itemType='proprietes'
      getOptionLabel={(item) => item?.nom_commun || item?.optionLabel || item || ''}
      addItem={{
        getLabel: (value) => `Ajouter la propriété ${value}`,
      }}
    />
  );
}

ProprieteAutocompleteSearchField.propTypes = {
  required: PropTypes.bool,
  propriete: PropTypes.object,
  setPropriete: PropTypes.func.isRequired,
};