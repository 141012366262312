import React from 'react';

import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { downloadFromUrl } from '@/utils/file';

import Button from '@mui/material/Button';

import APIService from '@/APIService';

export default function CSVExportButton({ itemType, ...props }) {
  const handleCSVExport = () => {
    document.body.style.cursor = 'wait';
    APIService.get({
      url: `${itemType}/csv-export/`,
    })
      .then((res) => {
        const href = window.URL.createObjectURL(
          new Blob([res.data], { type: 'text/csv' })
        );
        const currentDatetime = format(new Date(), 'ddMMyyyy_HHmmss');
        downloadFromUrl(href, `${itemType}_${currentDatetime}.csv`);
        URL.revokeObjectURL(href);
      })
      .finally(() => (document.body.style.cursor = 'default'));
  };

  return (
    <Button variant='contained' onClick={handleCSVExport} {...props}>
      Export CSV
    </Button>
  );
}

CSVExportButton.propTypes = {
  itemType: PropTypes.string.isRequired,
};
