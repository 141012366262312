import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import EspecePlanChasseForm from '@/features/item-form/EspecePlanChasseForm';

import Container from '@mui/material/Container';

export default function EspecePlanChasse() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const itemType = 'especes_plan_chasse';

  const emptyItem = {
    espece: null,
    nb_bracelet: null,
    evolution: null,
    propriete: null,
  };

  const item = useItem(itemId, itemType, emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <EspecePlanChasseForm item={item} />}
    </Container>
  );
}
