import React from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { v4 as uuid4 } from 'uuid';

import useChoices from '@/hooks/useChoices';

import SelectField from '@/components/controlled-fields/SelectField';
import AutoCompleteTextField from '@/components/controlled-fields/text-fields/AutoCompleteTextField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import MultilineTextField from '@/components/controlled-fields/text-fields/MultilineTextField';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

export default function StationFormDialog({
  editItem,
  open,
  setOpen,
  onSubmit,
  catalogueStation,
}) {
  const choices = useChoices('stations');

  const handleClose = () => {
    setOpen(false);
  };

  const StationForestiereForm = ({ currentItem }) => {
    const {
      handleSubmit,
      control,
      formState: { isDirty, isValid },
    } = useForm({
      defaultValues: currentItem || {
        temp_uuid: uuid4(),
        numero: null,
        code: null,
        facteurs_limitants: null,
        essences_principales_adaptees: [],
        essences_secondaires_adaptees: [],
        exposition: null,
        topographie: null,
      },
      reValidateMode: 'onBlur',
      mode: 'onChange',
    });

    return (
      <form>
        <DialogTitle>
          {currentItem ? 'Éditer' : 'Créer'} une station
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ p: 2 }} spacing={2} alignItems='center'>
            <Grid item xs={12} sm={6}>
              <BaseTextField
                control={control}
                name='numero'
                maxLength={120}
                required='Numéro requis.'
                label='Numéro'
              />
            </Grid>
            {catalogueStation && (
              <Grid item xs={12} sm={6}>
                <BaseTextField
                  control={control}
                  name='code'
                  maxLength={256}
                  label='Code du catalogue de station ou description'
                />
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <SelectField
                control={control}
                name='exposition'
                required='Exposition requise.'
                baseId='expo'
                label='Exposition'
                renderValue={(value) => value.nom}
                values={choices?.expositions}
                itemMapper={(expo) => ({
                  key: expo.id,
                  label: expo.nom,
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectField
                control={control}
                name='topographie'
                required='Topographie requise.'
                label='Topographie'
                renderValue={(value) => value.nom}
                itemMapper={(topographie) => ({
                  key: topographie.id,
                  label: topographie.nom,
                })}
                values={choices?.topographies}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MultilineTextField
                control={control}
                name='facteurs_limitants'
                label='Facteurs limitants'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AutoCompleteTextField
                control={control}
                name='essences_principales_adaptees'
                required='Essences principales adaptées requises.'
                getOptionLabel={(essence) =>
                  essence ? essence.nom_vernaculaire : ''
                }
                options={choices?.essences}
                defaultValue={currentItem?.essences_principales_adaptees}
                label='Essences principales adaptées'
                multiple
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AutoCompleteTextField
                control={control}
                name='essences_secondaires_adaptees'
                getOptionLabel={(essence) =>
                  essence ? essence.nom_vernaculaire : ''
                }
                options={choices?.essences}
                defaultValue={currentItem?.essences_principales_adaptees}
                label='Essences secondaires adaptées'
                multiple
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            disabled={!isDirty || !isValid}
            onClick={handleSubmit(onSubmit)}
          >
            {currentItem ? 'Éditer' : 'Créer'}
          </Button>
        </DialogActions>
      </form>
    );
  };

  StationForestiereForm.propTypes = {
    currentItem: PropTypes.object,
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
      <StationForestiereForm currentItem={editItem} />
    </Dialog>
  );
}

StationFormDialog.propTypes = {
  editItem: PropTypes.object,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  catalogueStation: PropTypes.bool,
};
