import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import useDebounce from '@/hooks/useDebounce';
import useOnMount from '@/hooks/useOnMount';

import AutoCompleteTextField from '@/components/controlled-fields/text-fields/AutoCompleteTextField';

import { createFilterOptions } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import APIService from '@/APIService';

const FILTER = createFilterOptions();

export default function AutoCompleteSearchField({
  label,
  required,
  multiple,
  getOptionLabel,
  itemState,
  itemType,
  customFilter,
  disabled,
  addItem,
  withDetails = true,
}) {

  const fieldName = useMemo(() => `search_${itemType}`);
  const { getLabel, minLength = 0, maxLength = 200 } = addItem || {};

  const { control, setValue } = useForm({
    defaultValues: null,
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  const [item, setItem] = itemState;

  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);

  // Debounce search term
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const searchFunc = (searchTerm) => APIService.get({
    url: `${itemType}/search/`,
    opts: {
      params: { name: searchTerm, with_details: withDetails }
    }
  });

  // Effect for API call
  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearching(true);
      searchFunc(debouncedSearchTerm)
        .then(({ data }) => {
          setOptions(
            data.filter(customFilter ? customFilter : ((d) => multiple ? !item.map((v) => v.id).includes(d.id) : d.id !== item?.id))
          );
        })
        .finally(() => setIsSearching(false));
    } else {
      setOptions([]);
    }
  }, [debouncedSearchTerm, item]);

  useOnMount(() => {
    if (item) {
      const value = item.inputValue || getOptionLabel(item);
      setSearchTerm(value);
      setValue(fieldName, value, { shouldValidate: true });
    }
  });

  return (
    <AutoCompleteTextField
      disabled={disabled}
      control={control}
      name={fieldName}
      label={label}
      options={options}
      required={required}
      getOptionLabel={getOptionLabel}
      freeSolo
      multiple={multiple}
      onInputChange={(event) => {
        if (event?.type !== 'change') {
          return;
        }
        const inputValue = event.target.value;
        setSearchTerm(inputValue);

        if (!inputValue) {
          setItem(null);
        }
      }}
      customOnChange={(item) => {
        if (typeof item === 'string') {
          item = { inputValue: item, optionLabel: getLabel(item) };
        }
        setItem(item);
        setOptions([]);
        setSearchTerm(item?.inputValue || getOptionLabel(item));
      }}
      inputProps={{
        role: 'input',
        value: searchTerm,
      }}
      InputProps={{
        endAdornment: isSearching && <CircularProgress size={20} />,
      }}
      filterOptions={(options, params) => {
        const filtered = FILTER(options, params);

        if (!addItem) {
          return filtered;
        }

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === getOptionLabel(option)
        );

        if (
          inputValue !== '' &&
          inputValue.length > minLength &&
          inputValue.length < maxLength &&
          !isExisting
        ) {
          filtered.push({
            inputValue,
            optionLabel: getLabel(inputValue),
          });
        }
        return filtered;
      }}
    />
  );
}

AutoCompleteSearchField.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  multiple: PropTypes.bool,
  getOptionLabel: PropTypes.func.isRequired,
  itemState: PropTypes.array.isRequired,
  itemType: PropTypes.string.isRequired,
  customFilter: PropTypes.func,
  withDetails: PropTypes.bool,
  disabled: PropTypes.bool,
  addItem: PropTypes.shape({
    getLabel: PropTypes.func.isRequired,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
  }),
};