import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import useChoices from '@/hooks/useChoices';

import SelectField from '@/components/controlled-fields/SelectField';
import MultilineTextField from '@/components/controlled-fields/text-fields/MultilineTextField';
import UpperCaseTextField from '@/components/controlled-fields/text-fields/UpperCaseTextField';

import { incrementAlphabetChar } from '@/utils/string';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

export default function AccesOuvrageFormDialog({
  editItem,
  open,
  setOpen,
  onSubmit,
  items,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const choices = useChoices('acces_ouvrages');

  const AccesOuvrageForm = ({ item, items }) => {
    const {
      handleSubmit,
      control,
      formState: { isDirty, isValid },
      setValue,
    } = useForm({
      defaultValues: item || {
        code: null,
        nature: null,
        preco_gestion: null,
      },
      reValidateMode: 'onBlur',
      mode: 'onChange',
    });

    useEffect(() => {
      if (!items) {
        return;
      }
      setValue('code', incrementAlphabetChar(items.at(-1)?.code));
    }, [items?.length]);

    return (
      <form>
        <DialogTitle>
          {item ? 'Éditer' : 'Créer'} un accès et ouvrage de la propriété
        </DialogTitle>
        <DialogContent>
          {items && (
            <Grid container sx={{ p: 2 }} spacing={2} alignItems='center'>
              <Grid item xs={12} sm={6}>
                <UpperCaseTextField
                  control={control}
                  name='code'
                  maxLength={120}
                  required
                  label='Identifiant unique'
                  validate={(value) => {
                    let currentCodes = items.map((i) => i.code);

                    if (item) {
                      const codeIdx = currentCodes.indexOf(item.code);
                      currentCodes.splice(codeIdx, 1);
                    }

                    return (
                      !currentCodes.includes(value) ||
                    `L'identifiant "${value}" est déjà utilisé.`
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectField
                  control={control}
                  name='nature'
                  baseId='nature'
                  label='Nature'
                  required={'Nature requise.'}
                  renderValue={(value) => value.nom}
                  values={choices?.natures}
                  itemMapper={(nature) => ({
                    key: nature.id,
                    label: nature.nom,
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <MultilineTextField
                  control={control}
                  name='preco_gestion'
                  required='Préconisations de gestion requises.'
                  label='Préconisations de gestion'
                />
              </Grid>
              <Grid item xs={12}>
                <MultilineTextField
                  control={control}
                  name='commentaire'
                  label='Commentaire'
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            disabled={!isDirty || !isValid}
            onClick={handleSubmit(onSubmit)}
          >
            {item ? 'Éditer' : 'Créer'}
          </Button>
        </DialogActions>
      </form>
    );
  };

  AccesOuvrageForm.propTypes = {
    item: PropTypes.object,
    items: PropTypes.array,
    code: PropTypes.string,
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
      <AccesOuvrageForm item={editItem} items={items} />
    </Dialog>
  );
}

AccesOuvrageFormDialog.propTypes = {
  editItem: PropTypes.object,
  items: PropTypes.array,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
