import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import ContratProprietaireForm from '@/features/item-form/ContratProprietaireForm';

import Container from '@mui/material/Container';

export default function ContratProprietaireItem() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const emptyItem = {
    code_chantier: null,
    proprietaires_list: [],
    unites_gestion_list: [],
    date_signature_contrat_seing_prive: null,
    date_fin_contrat: null,
    date_signature_acte_notarie: null,
    montant_forfaitaire: null,
    pourcentage_services_environnementaux: null,
    montant_total_previsionnel_operations: null,
    date_paiement_forfaitaire: null,
    gestionnaire: null,
    etat: null,
    propriete: null,
    referent_technique: null,
    itineraires_sylvicoles_pressentis: null,
    date_premiere_visite_neosylva: null,
  };

  const item = useItem(itemId, 'contrats_proprietaires', emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <ContratProprietaireForm item={item} />}
    </Container>
  );
}
