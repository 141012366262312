import React, { useEffect, useState } from 'react';

import deepmerge from 'deepmerge';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import useChoices from '@/hooks/useChoices';
import useDownloadDocument from '@/hooks/useDownloadDocument';
import useFetchFilteredItems from '@/hooks/useFetchFilteredItems';
import useGoBackHistory from '@/hooks/useGoBackHistory';
import useOnUnmount from '@/hooks/useOnUnmount';
import useSetFormValues from '@/hooks/useSetFormValues';

import EnjeuxEconomiquesTab from '@/features/project-form/diagnostic-gestionnaire/EnjeuxEconomiquesTab';
import EnjeuxSocioEnvTab from '@/features/project-form/diagnostic-gestionnaire/EnjeuxSocioEnvTab';
import RenseignementsGenerauxTab from '@/features/project-form/diagnostic-gestionnaire/RenseignementsGenerauxTab';
import StationsForestieresTab from '@/features/project-form/diagnostic-gestionnaire/StationsForestieresTab';
import SubmitHandlerButton from '@/features/SubmitHandlerButton';

import { setIsLoading } from '@/slices/isLoadingSlice';
import { setMessage } from '@/slices/messageSlice';

import { createFileFromUrl } from '@/utils/file';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import APIService from '@/APIService';
import ProjetNeosylvaTab from './ProjetNeosylvaTab';

export default function DiagnosticGestionnaireForm() {
  const goBackHistory = useGoBackHistory();

  const dispatch = useDispatch();

  const fetchFilteredItems = useFetchFilteredItems();

  const downloadDocument = useDownloadDocument();

  const choices = useChoices('diagnostic_gestionnaire');

  const [propriete, setPropriete] = useState(null);

  const [proprietaire, setProprietaire] = useState(null);

  const [contratProprietaire, setContratProprietaire] = useState(null);

  const [gestionnaire, setGestionnaire] = useState(null);

  const [isIndivisaire, setIsIndivisaire] = useState(false);

  const isLoading = useSelector((store) => store.isLoadingReducer.isLoading);

  const isFetching = useSelector(
    (store) => store.itemReducer.pendingActions !== 0
  );

  const especesPlanChasseList = useSelector(
    (store) => store.itemReducer.filteredItems.especes_plan_chasse
  );

  const accesOuvragesList = useSelector(
    (store) => store.itemReducer.filteredItems.acces_ouvrages
  );

  const elementsBiodiversiteList = useSelector(
    (store) => store.itemReducer.filteredItems.elements_biodiversite
  );

  const stationsList = useSelector(
    (store) => store.itemReducer.filteredItems.stations
  );

  const unitesGestionList = useSelector(
    (store) => store.itemReducer.filteredItems.unites_gestion
  );

  const parcellesList = useSelector(
    (store) => store.itemReducer.filteredItems.parcelles_cadastrales_pressenties_sous_contrat
  );

  const documentsList = useSelector(
    (store) => store.itemReducer.filteredItems.documents
  );

  const indivisairesList = useSelector(
    (store) => store.itemReducer.filteredItems.indivisaires
  );

  const emptyContact = {
    nom: null,
    prenom: null,
    telephone_fixe: null,
    telephone_portable: null,
    email: null,
    adresse: null,
    commune: null,
    code_postal: null,
    departement: null,
  };

  const form1 = useForm({
    defaultValues: {
      proprietaire: {
        contact: emptyContact,
        personnalite_juridique: null,
        raison_sociale: null,
        siret: null,
        adresse_siege: null,
        code_postal_siege: null,
        commune_siege: null,
      },
      propriete: {
        cmf_neosylva: null,
        nom_commun: null,
        adresse: null,
        commune: null,
        code_postal: null,
        departement: null,
        surface_totale_propriete: null,
        existence_dgd: false,
        gestionnaire: null,
      },
      contrat_proprietaire: {
        gestionnaire: null,
      },
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const {
    setValue: setValue1,
    getValues: getValues1,
    formState: { isValid: isValid1 },
  } = form1;

  const form2 = useForm({
    defaultValues: {
      propriete: {
        besoin_acces_supplementaire: null,
        acces_massif: null,
        nb_place_depot: null,
        commentaire_chasse_pression_gibier: null,
        degats_constates: null,
        natures_degats_list: [],
        identite_detenteur_droit_chasse: null,
      },
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const {
    setValue: setValue2,
    getValues: getValues2,
    formState: { isValid: isValid2 },
  } = form2;

  const form3 = useForm({
    defaultValues: {
      propriete: {
        frequentation_public: null,
        importance_frequentation: null,
        zonages_identifies_list: [],
        zonages_identifies_commentaire: null,
      },
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const {
    setValue: setValue3,
    getValues: getValues3,
    formState: { isValid: isValid3 },
  } = form3;

  const form4 = useForm({
    defaultValues: {
      propriete: {
        catalogue_station: false,
        catalogue_station_detail: null,
      },
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const {
    setValue: setValue4,
    getValues: getValues4,
    formState: { isValid: isValid4 },
  } = form4;

  const form5 = useForm({
    defaultValues: {
      contrat_proprietaire: {
        surface_pressentie_sous_contrat: null,
        points_vigilance: null,
      }
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const {
    getValues: getValues5,
    formState: { isValid: isValid5 },
  } = form5;

  const proprieteFields = [
    ['nom_commun', true, setValue1],
    ['adresse', true, setValue1],
    ['commune', true, setValue1],
    ['code_postal', true, setValue1],
    ['departement', true, setValue1],
    ['surface_totale_propriete', false, setValue1],
    ['existence_dgd', true, setValue1],
    ['besoin_acces_supplementaire', true, setValue2],
    ['acces_massif', false, setValue2],
    ['acces_grumier', false, setValue2],
    ['nb_place_depot', false, setValue2],
    ['commentaire_chasse_pression_gibier', true, setValue2],
    ['degats_constates', false, setValue2],
    ['natures_degats_list', false, setValue2],
    ['identite_detenteur_droit_chasse', false, setValue2],
    ['frequentation_public', false, setValue3],
    ['importance_frequentation', false, setValue3],
    ['zonages_identifies_list', false, setValue3],
    ['zonages_identifies_commentaire', false, setValue3],
    ['catalogue_station', false, setValue4],
    ['catalogue_station_detail', false, setValue4],
    ['gestionnaire', true, setValue1],
    ['cmf_neosylva', true, setValue1],
  ];

  const setFormValues = useSetFormValues();

  useOnUnmount(() => {
    documentsList?.forEach((file) => URL.revokeObjectURL(file.local_url));
  });

  useEffect(() => {
    if (!propriete) {
      return;
    }

    fetchFilteredItems({
      itemTypes: [
        'parcelles_cadastrales_pressenties_sous_contrat',
        'especes_plan_chasse',
        'acces_ouvrages',
        'elements_biodiversite',
        'stations',
      ],
      filter: { propriete_id: propriete.id },
    });

    setFormValues(proprieteFields, propriete, 'propriete');
  }, [propriete]);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  const onCancel = () => {
    goBackHistory({ defaultPath: '/' });
  };

  const ActionBtn = () => {
    const isLastTab = tabValue === 4;
    const onSubmit = async (generateDocument) => {
      const formValuesList = [
        getValues1(),
        getValues2(),
        getValues3(),
        getValues4(),
        getValues5(),
      ];

      const formValues = deepmerge.all(formValuesList);

      formValues.contrat_proprietaire.id = contratProprietaire.id;
      formValues.propriete.gestionnaire = gestionnaire;
      formValues.proprietaire.id = proprietaire.id;
      formValues.proprietaire.contact.id = proprietaire.contact.id;
      formValues.propriete.id = propriete.id;
      formValues.parcelles_cadastrales_pressenties_sous_contrat_list = parcellesList;
      const formData = new FormData();
      const filesList = [];
      const sharepointsList = [];
      documentsList.forEach((document) =>
        (document.repository ? sharepointsList : filesList).push(document)
      );
      formValues.files_list = filesList;
      formValues.sharepoints_list = sharepointsList;
      formValues.especes_plan_chasse_list = especesPlanChasseList;
      formValues.acces_ouvrages_list = accesOuvragesList;
      formValues.elements_biodiversite_list = elementsBiodiversiteList;
      formValues.stations_list = stationsList;
      formValues.unites_gestion_list = unitesGestionList.map(unite_gestion => ({ ...unite_gestion, contrat_proprietaire: contratProprietaire }));
      if (
        formValues.proprietaire.personnalite_juridique?.code === 'PP' &&
        isIndivisaire
      ) {
        formValues.autres_indivisaires_list = indivisairesList.filter(
          (indivisaire) => !indivisaire.principale
        );
        formValues.proprietaire.indivisaire.id = proprietaire.indivisaire?.id;
        formValues.proprietaire.indivisaire.indivision.id =
          proprietaire.indivisaire?.indivision?.id;
      } else {
        formValues.autres_indivisaires_list = null;
        formValues.proprietaire.indivisaire = null;
      }
      formData.append('body', JSON.stringify(formValues));
      for (const file of filesList) {
        if (file.local_url && file.temp_uuid) {
          formData.append(
            'files',
            await createFileFromUrl(file.local_url, file.temp_uuid)
          );
        }
      }
      const options = {
        responseType: generateDocument ? 'blob' : 'json',
      };

      await APIService.post({
        url: 'diagnostic_gestionnaire',
        data: formData,
        opts: options,
        onError: (res) => {
          const message = res.data.message || res.data.detail;
          if (message) {
            dispatch(setMessage(message));
          }
        },
      }).then(({ data }) => {
        dispatch(setMessage(data.message));

        if (generateDocument) {
          APIService.get({
            url: `diagnostic_gestionnaire/download/${contratProprietaire.id}`,
            opts: {
              responseType: 'blob',
            },
            onError: (res) => {
              const message = res.data.message || res.data.detail;
              if (message) {
                dispatch(setMessage(message));
              }
            },
          }).then(downloadDocument).finally(() => dispatch(setIsLoading(false)));
        }
      }).finally(() => !generateDocument && dispatch(setIsLoading(false)));
    };

    const disabledSubmit =
      !isValid1 ||
      !isValid2 ||
      !isValid3 ||
      !isValid4 ||
      !isValid5 ||
      !contratProprietaire ||
      isFetching ||
      !unitesGestionList ||
      unitesGestionList.some((uniteGestion) => !uniteGestion.peuplement_initial);

    let disableNext;

    switch (tabValue) {
      case 0:
        disableNext = !isValid1 || !contratProprietaire;
        break;
      case 1:
        disableNext = !isValid2;
        break;
      case 2:
        disableNext = !isValid3;
        break;
      case 3:
        disableNext = !isValid4;
        break;
    }

    return (
      <Grid container sx={{ mt: 3, justifyContent: 'space-between' }}>
        <Grid item>
          <Button variant='outlined' sx={{ mb: 1, mr: 1 }} onClick={onCancel}>
            Fermer
          </Button>
          {!isLastTab && (
            <Button
              variant='contained'
              sx={{ mb: 1 }}
              disabled={disableNext}
              onClick={() => setTabValue(tabValue + 1)}
            >
              Suivant
            </Button>
          )}
        </Grid>
        <Grid item>
          <SubmitHandlerButton
            onSubmit={() => onSubmit(false)}
            label='Sauvegarder'
            disabledSubmit={disabledSubmit}
            sx={{ mb: 1 }}
            variant='contained'
          />
          <Button
            onClick={() => {
              dispatch(setIsLoading(true));
              onSubmit(true);
            }}
            disabled={disabledSubmit || isLoading}
            variant='contained'
            sx={{ mb: 1, ml: 1 }}
          >
            Sauvegarder et télécharger
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box elevation={3} component={Paper} sx={{ width: '100%', p: 3 }}>
      <Typography mb={2} variant='h5' component='div'>
        Diagnostic gestionnaire
      </Typography>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant='scrollable'
        scrollButtons
        allowScrollButtonsMobile
      >
        <Tab label='Renseignements généraux' />
        <Tab label='Enjeux économiques' disabled={!propriete} />
        <Tab label='Enjeux socio-environnementaux' disabled={!propriete} />
        <Tab label='Stations forestières' disabled={!propriete} />
        <Tab label='Projet Néosylva' disabled={!propriete} />
      </Tabs>
      <RenseignementsGenerauxTab
        form={form1}
        tabValue={tabValue}
        choices={choices}
        proprieteState={{ propriete, setPropriete }}
        gestionnaireState={{ gestionnaire, setGestionnaire }}
        contratProprietaireState={{
          contratProprietaire,
          setContratProprietaire,
        }}
        isIndivisaireState={{ isIndivisaire, setIsIndivisaire }}
        proprietaireState={{ proprietaire, setProprietaire }}
        indivisairesList={indivisairesList}
        parcellesList={parcellesList}
        emptyContact={emptyContact}
      />
      <EnjeuxEconomiquesTab
        form={form2}
        tabValue={tabValue}
        choices={choices}
        propriete={propriete}
        accesOuvragesList={accesOuvragesList}
        especesPlanChasseList={especesPlanChasseList}
      />
      <EnjeuxSocioEnvTab
        form={form3}
        tabValue={tabValue}
        choices={choices}
        propriete={propriete}
        elementsBiodiversiteList={elementsBiodiversiteList}
      />
      <StationsForestieresTab
        form={form4}
        tabValue={tabValue}
        propriete={propriete}
        stationsList={stationsList}
        unitesGestionList={unitesGestionList}
      />
      <ProjetNeosylvaTab
        form={form5}
        tabValue={tabValue}
        unitesGestionList={unitesGestionList}
        stationsList={stationsList}
        documentsList={documentsList}
        propriete={propriete}
        contratProprietaire={contratProprietaire}
      />
      <ActionBtn />
    </Box>
  );
}
