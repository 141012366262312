import React from 'react';

import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import TabPanel from './TabPanel';

export default function TabPanelForm({ children, currentValue, index }) {
  return (
    <Box sx={{ p: 3 }} hidden={currentValue !== index}>
      <TabPanel currentValue={currentValue} index={index}>{children}</TabPanel>
    </Box>
  );
}

TabPanelForm.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  currentValue: PropTypes.number.isRequired,
};
