import React from 'react';

import BaseTextField from './BaseTextField';

export default function MailTextField({ ...props }) {
  return (
    <BaseTextField
      pattern={{
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Adresse email invalide',
      }}
      maxLength={120}
      {...props}
    />
  );
}
