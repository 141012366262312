import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { fetchAllChoices } from '@/slices/itemSlice';

export default function useChoices(itemType) {
  const dispatch = useDispatch();

  const choices = useSelector((store) => store.itemReducer.choices[itemType]);

  useEffect(() => {
    if (!choices || itemType === 'essences') {
      dispatch(fetchAllChoices(itemType));
    }
  }, [choices]);

  return choices;
}
