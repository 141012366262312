import React from 'react';

import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useItems from '@/hooks/useItems';

import CSVExportButton from '@/components/CSVExportButton';
import CustomDataGrid from '@/components/CustomDataGrid';

import { deleteItem } from '@/slices/itemSlice';

import { fromISODateTime } from '@/utils/date';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';

export default function ContratsFinanceur() {
  const { open } = useConfirmationDialog();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const itemType = 'contrats_financeur';

  const items = useItems(itemType);

  const handleEditItem = (itemId) => {
    navigate(`/contrat-financeur/${itemId}`);
  };

  const handleDeleteItem = async (itemId) => {
    const isConfirmed = await open({
      title: 'Suppression',
      content: 'Confirmer la suppression du contrat ?',
    });

    if (isConfirmed) {
      dispatch(deleteItem({ itemId, itemType }));
    }
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<EditIcon />}
          label='Éditer'
          onClick={() => handleEditItem(params.id)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<DeleteIcon />}
          label='Supprimer'
          onClick={() => handleDeleteItem(params.id)}
        />,
      ],
    },
    {
      field: 'code',
      headerName: 'Code',
      width: 220,
      editable: false,
    },
    {
      field: 'financeur',
      headerName: 'Financeur',
      width: 220,
      valueGetter: (value) => value.nom, // For filtering ...
      renderCell: ({ row }) => (
        <Link
          component={RouterLink}
          to={`/financeur-carbone/${row.financeur.id}`}
        >
          {row.financeur.contact.nom}
        </Link>
      ),
    },
    {
      field: 'stock_carbone',
      headerName: 'Stock carbone',
      width: 220,
      valueGetter: (value) => value.nom_projet, // For filtering ...
      renderCell: ({ row }) => (
        <Link
          component={RouterLink}
          to={`/stock-carbone/${row.stock_carbone.id}`}
        >
          {row.stock_carbone.nom_projet}
        </Link>
      ),
    },
    {
      field: 'stock_vendu',
      headerName: 'Stock vendu',
      width: 220,
      editable: false,
    },
    {
      field: 'montant',
      headerName: 'Montant du contrat',
      width: 220,
      editable: false,
      valueFormatter: (value) => (value ? value.toFixed(2) : null),
    },
    {
      field: 'prix_vente',
      headerName: 'Prix de vente',
      width: 220,
      editable: false,
      valueFormatter: (value) => (value ? value.toFixed(2) : null),
    },
    {
      field: 'creation_datetime',
      headerName: 'Création',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
    {
      field: 'last_modification_datetime',
      headerName: 'Dernière modification',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
  ];

  const handleFormRedirect = () => {
    navigate('/contrat-financeur');
  };

  return (
    <Box component='main' mx={3} mt={2}>
      <Typography mb={1} variant='h5' component='div'>
        Contrats financeur
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm='auto'>
          <Button variant='contained' onClick={handleFormRedirect} fullWidth>
            Ajouter un contrat
          </Button>
        </Grid>
        <Grid item xs={12} sm='auto'>
          <CSVExportButton itemType={itemType} fullWidth />
        </Grid>
      </Grid>
      <CustomDataGrid columns={columns} rows={items} />
    </Box>
  );
}
