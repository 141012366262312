import React from 'react';

import DiagnosticNeosylvaForm from '@/features/project-form/diagnostic-neosylva/DiagnosticNeosylvaForm';

import Container from '@mui/material/Container';

export default function DiagnosticNeosylvaCreate() {
  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      <DiagnosticNeosylvaForm />
    </Container>
  );
}
