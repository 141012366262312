import React from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import useChoices from '@/hooks/useChoices';

import SelectField from '@/components/controlled-fields/SelectField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import MultilineTextField from '@/components/controlled-fields/text-fields/MultilineTextField';
import NumberTextField from '@/components/controlled-fields/text-fields/NumberTextField';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

export default function ElementBiodiversiteFormDialog({
  editItem,
  open,
  setOpen,
  onSubmit,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const choices = useChoices('elements_biodiversite');

  const ElementBiodiversiteForm = ({ item }) => {
    const {
      handleSubmit,
      control,
      formState: { isDirty, isValid },
    } = useForm({
      defaultValues: item
        ? item
        : {
          numero: null,
          nature: null,
          surface: null,
          preco_gestion: null,
        },
      reValidateMode: 'onBlur',
      mode: 'onChange',
    });

    return (
      <form>
        <DialogTitle>
          {item ? 'Éditer' : 'Créer'} un élément de biodiversité
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ p: 2 }} spacing={2} alignItems='center'>
            <Grid item xs={12} sm={6}>
              <BaseTextField
                control={control}
                name='numero'
                maxLength={120}
                required='Élément de biodiversité n° requis.'
                label='Élément de biodiversité n°'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectField
                control={control}
                name='nature'
                baseId='nature'
                label='Nature'
                renderValue={(value) => value.nom}
                values={choices?.natures}
                itemMapper={(nature) => ({
                  key: nature.id,
                  label: nature.nom,
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumberTextField
                control={control}
                name='surface'
                label='Surface en ha / linéaire en ml (si pertinent)'
                inputProps={{
                  inputMode: 'numeric',
                }}
                decimal={4}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MultilineTextField
                control={control}
                name='preco_gestion'
                required='Préconisations de gestion requises.'
                label='Préconisations de gestion'
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            disabled={!isDirty || !isValid}
            onClick={handleSubmit(onSubmit)}
          >
            {item ? 'Éditer' : 'Créer'}
          </Button>
        </DialogActions>
      </form>
    );
  };

  ElementBiodiversiteForm.propTypes = {
    item: PropTypes.object,
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
      <ElementBiodiversiteForm item={editItem} />
    </Dialog>
  );
}

ElementBiodiversiteFormDialog.propTypes = {
  editItem: PropTypes.object,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
