import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  clearCurrentItem,
  fetchItemById,
  setEmptyCurrentItem,
} from '@/slices/itemSlice';

import useOnUnmount from './useOnUnmount';

export default function useItem(itemId, itemType, emptyItem) {
  const dispatch = useDispatch();

  const item = useSelector((store) => store.itemReducer.currentItem);

  useEffect(() => {
    if (item) {
      return;
    }

    if (itemId) {
      dispatch(fetchItemById({ itemId, itemType }));
    } else {
      dispatch(setEmptyCurrentItem(emptyItem));
    }
  }, [item]);

  useOnUnmount(() => dispatch(clearCurrentItem()));

  return item;
}
