import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import CertificationForm from '@/features/item-form/CertificationForm';

import Container from '@mui/material/Container';

export default function Certification() {

  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const emptyItem = {
    type: null,
    surface: null,
    numero_adherent: null,
    propriete: null,
  };

  const item = useItem(itemId, 'certifications', emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <CertificationForm item={item} />}
    </Container>
  );
}
