import { getGridSingleSelectOperators } from '@mui/x-data-grid';

/**
 * Custom filter for many items cell.
 * If specified, the valueGetter function is used to map the value before any computation
 */
export const manyItemsFilterOperator = (valueGetter) => getGridSingleSelectOperators()
  .filter((operator) => operator.value === 'isAnyOf')
  .map((operator) => {
    const newOperator = { ...operator };
    const newGetApplyFilterFn = (filterItem) => {
      return (value) => {
        if (valueGetter) {
          value = value.map(valueGetter);
        }
        return (
          filterItem.value?.length === 0 ||
          filterItem.value?.some((filter) => value?.includes(filter))
        );
      };
    };
    newOperator.getApplyFilterFn = newGetApplyFilterFn;

    return newOperator;
  });
