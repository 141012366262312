import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import ParcelleCadastralePressentieSousContratForm from '@/features/item-form/ParcelleCadastralePressentieSousContratForm';

import Container from '@mui/material/Container';

export default function ParcelleCadastralePressentieSousContrat() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const emptyItem = {
    code_commune: null,
    nom_commune: null,
    prefixe: null,
    section: null,
    numero: null,
    contenance: null,
  };

  const item = useItem(itemId, 'parcelles_cadastrales_pressenties_sous_contrat', emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <ParcelleCadastralePressentieSousContratForm item={item} />}
    </Container>
  );
}
