import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import DepenseForm from '@/features/item-form/DepenseForm';

import Container from '@mui/material/Container';

export default function Depense() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const itemType = 'depenses';

  const emptyItem = {
    type: null,
    surface: null,
    numero_adherent: null,
    propriete: null,
  };

  const item = useItem(itemId, itemType, emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <DepenseForm item={item} />}
    </Container>
  );
}
