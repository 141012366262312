import React from 'react';

import PropTypes from 'prop-types';

import TableForm from '@/features/project-form/TableForm';

import EspecePlanChasseFormDialog from './EspecePlanChasseFormDialog';

export default function EspecePlanChasseTable({ items }) {
  const columns = [
    {
      field: 'espece',
      headerName: 'Espèce',
      width: 150,
      editable: false,
    },
    {
      field: 'nb_bracelet',
      headerName: 'Nombre de bracelets',
      width: 150,
      editable: false,
    },
    {
      field: 'evolution',
      headerName: 'Évolution',
      width: 200,
      editable: false,
      valueGetter: (value) => value?.nom,
    },
  ];

  return (
    <TableForm
      itemsList={items || []}
      columns={columns}
      itemType={'especes_plan_chasse'}
      tableTitle={'Espèce plan de chasse'}
      addBtnTxt={'Ajouter une espèce de plan de chasse'}
      ModalForm={EspecePlanChasseFormDialog}
    />
  );
}

EspecePlanChasseTable.propTypes = {
  items: PropTypes.array,
};
