import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import useConditionalRequired from '@/hooks/useConditionalRequired';
import useItems from '@/hooks/useItems';

import CiviliteRadioGroupField from '@/components/controlled-fields/CiviliteRadioGroupField';
import DatePickerField from '@/components/controlled-fields/DatePickerField';
import AutoCompleteTextField from '@/components/controlled-fields/text-fields/AutoCompleteTextField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import CodePostalTextField from '@/components/controlled-fields/text-fields/CodePostalTextField';
import MailTextField from '@/components/controlled-fields/text-fields/MailTextField';
import UpperCaseTextField from '@/components/controlled-fields/text-fields/UpperCaseTextField';
import TabPanelForm from '@/components/TabPanelForm';

import AdresseField from '@/features/AdresseField';
import DepartementField from '@/features/DepartementField';
import EntrepriseField from '@/features/EntrepriseField';

import Grid from '@mui/material/Grid';

export default function InformationsGeneralesTab({
  form,
  tabValue,
  choices,
  isPropState,
}) {
  const { control, setValue, trigger } = form;
  const { isProp, setIsProp } = isPropState;
  const entreprises = useItems('entreprises');

  const conditionalRequiredFields = [
    ['telephone_fixe'],
    ['telephone_portable'],
    ['email'],
    ['adresse', 'commune', 'code_postal'],
  ];

  const requiredMapper = useConditionalRequired(conditionalRequiredFields, control, trigger);

  const entreprisesWatch = useWatch({
    control,
    name: 'entreprises_list',
  });

  const activitesTypes = useWatch({
    control,
    name: 'activites_list',
  });

  useEffect(() => {
    setIsProp(activitesTypes.map((activite) => activite.code).includes('PROP'));
  }, [activitesTypes]);

  return (
    <TabPanelForm currentValue={tabValue} index={0}>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <AutoCompleteTextField
              control={control}
              name='activites_list'
              label='Activité(s)'
              multiple
              required='Au moins une activité est requise.'
              getOptionLabel={(p) => (p ? p.nom : '')}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={choices?.activites}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
          <Grid item xs={12} sm={12} md={3}>
            <CiviliteRadioGroupField control={control} name='civilite' />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <UpperCaseTextField
              control={control}
              name='nom'
              maxLength={256}
              required='Nom requis.'
              label='Nom'
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <BaseTextField
              control={control}
              name='prenom'
              maxLength={256}
              required='Prénom requis.'
              label='Prénom'
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <DatePickerField
              control={control}
              name='date_naissance'
              label='Date de naissance'
              disableFuture
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <EntrepriseField
              control={control}
              fieldName={'entreprises_list'}
              entreprises={entreprises}
              defaultValue={entreprisesWatch}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <BaseTextField
              control={control}
              name='profession'
              label='Profession'
              maxLength={256}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
          <Grid item xs={12} sm={6} md={2}>
            <BaseTextField
              control={control}
              name='telephone_fixe'
              label='Téléphone fixe'
              maxLength={16}
              required={requiredMapper.telephone_fixe && 'Téléphone fixe requis.'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <BaseTextField
              control={control}
              name='telephone_portable'
              maxLength={16}
              required={
                requiredMapper.telephone_portable && 'Téléphone portable requis.'
              }
              label='Téléphone portable'
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <MailTextField
              control={control}
              name='email'
              required={requiredMapper.email && 'E-mail requis.'}
              label='E-mail'
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
          <Grid item xs={12} sm={6} md={5}>
            <AdresseField
              control={control}
              fieldName={'adresse'}
              relatedFiedlNames={{
                commune: 'commune',
                codePostal: 'code_postal',
                pays: 'pays',
              }}
              required={requiredMapper.adresse}
              label={isProp ? 'Adresse propriétaire' : 'Adresse'}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <BaseTextField
              control={control}
              name='commune'
              maxLength={256}
              required={requiredMapper.commune && 'Commune requise.'}
              label={isProp ? 'Commune propriétaire' : 'Commune'}
            />
          </Grid>
          <Grid item xs={5} sm={4} md={2}>
            <CodePostalTextField
              control={control}
              name='code_postal'
              required={requiredMapper.code_postal && 'Code postal requis.'}
              label={isProp ? 'Code postal propriétaire' : 'Code postal'}
            />
          </Grid>
          <Grid item xs={7} sm={4} md={3}>
            <DepartementField
              label={!isProp ? 'Département' : 'Département propriétaire'}
              departements={choices?.departements}
              control={control}
              departementFieldName={'departement'}
              codePostalFieldName={'code_postal'}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <AutoCompleteTextField
              control={control}
              name='pays'
              label='Pays'
              options={choices?.pays}
            />
          </Grid>
        </Grid>
      </form>
    </TabPanelForm>
  );
}

InformationsGeneralesTab.propTypes = {
  form: PropTypes.object.isRequired,
  tabValue: PropTypes.number.isRequired,
  choices: PropTypes.object,
  isPropState: PropTypes.object.isRequired,
};