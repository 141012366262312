import React from 'react';

import BaseTextField from './BaseTextField';

export default function CodePostalTextField({ ...props }) {
  return (
    <BaseTextField
      fixedLength={{
        value: 5,
        message: 'Le code postal doit avoir 5 caractères.',
      }}
      pattern={{
        value: /^\d+$/,
        message: 'Le code postal est invalide.',
      }}
      {...props}
    />
  );
}
