import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { setFilteredItems } from '@/slices/itemSlice';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';

export default function TableForm({
  ModalForm,
  modalProps,
  itemsList,
  relatedItemsList,
  columns,
  itemType,
  addBtnTxt,
  tableTitle,
  onDuplicate,
  disabledEdit,
}) {
  const dispatch = useDispatch();

  const [editItem, setEditItem] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const rows = itemsList?.map((item, idx) => {
    const row = { ...item };
    row.rowId = idx + 1;
    return row;
  });

  if (!disabledEdit) {
    columns = [
      ...columns,
      {
        field: 'actions',
        type: 'actions',
        width: onDuplicate ? 110 : 80,
        getActions: (params) => {
          const actions = [];
          if (!params.row.disabledEdit) {
            actions.push(
              <GridActionsCellItem
                key={params.id}
                icon={<EditIcon />}
                label='Éditer'
                onClick={() => showForm(params.row)}
              />,
              <GridActionsCellItem
                key={params.id}
                icon={<DeleteIcon />}
                label='Supprimer'
                onClick={() => handleDelete(params.id)}
              />
            );
          }

          if (onDuplicate) {
            actions.push(
              <GridActionsCellItem
                key={params.id}
                icon={<LibraryAddIcon />}
                label='Dupliquer'
                onClick={() => duplicate(params.row)}
              />
            );
          }
          return actions;
        },
      },
    ];
  }

  const duplicate = (rowToDuplicate) => {
    const newRow = onDuplicate(rowToDuplicate);

    let updatedItemsList = [...rows, newRow];
    if (relatedItemsList) {
      updatedItemsList = [...relatedItemsList, ...updatedItemsList];
    }

    dispatch(
      setFilteredItems({
        itemsList: updatedItemsList,
        itemType,
      })
    );
  };

  const handleDelete = (rowId) => {
    let itemsToKeepList = rows.filter((row) => row.rowId !== rowId);

    if (relatedItemsList) {
      itemsToKeepList = [...relatedItemsList, ...itemsToKeepList];
    }

    dispatch(
      setFilteredItems({
        itemsList: itemsToKeepList,
        itemType,
      })
    );
  };

  const onFormSubmit = (data) => {
    let updatedItemsList;
    if (!editItem) {
      updatedItemsList = [...rows, data];
    } else {
      updatedItemsList = rows.map((p) => (p.rowId === data.rowId ? data : p));
    }

    if (relatedItemsList) {
      updatedItemsList = [...relatedItemsList, ...updatedItemsList];
    }

    setEditItem(null);

    setOpenModal(false);

    dispatch(
      setFilteredItems({
        itemsList: updatedItemsList,
        itemType,
      })
    );
  };

  const showForm = (item) => {
    setEditItem(item);
    setOpenModal(true);
  };

  modalProps = modalProps || {};

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const handlePaginationModelChange = (updatedPaginationModel) => {
    setPaginationModel(updatedPaginationModel);
  };

  return (
    <>
      {tableTitle && 
        <Typography mb={1} variant='subtitle1' component='div'>
          {tableTitle} :
        </Typography>
      }
      <Button
        size='small'
        variant='contained'
        disabled={disabledEdit}
        onClick={() => showForm()}
      >
        {addBtnTxt}
      </Button>
      <Box
        sx={{
          my: 2,
        }}
      >
        <DataGrid
          loading={!rows}
          autoHeight={true}
          getRowId={(row) => row.rowId}
          rows={rows ? rows : []}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={[10, 15, 30, 50]}
          checkboxSelection={false}
        />
      </Box>
      <ModalForm
        open={openModal}
        setOpen={setOpenModal}
        onSubmit={onFormSubmit}
        editItem={editItem}
        {...modalProps}
      />
    </>
  );
}

TableForm.propTypes = {
  ModalForm: PropTypes.elementType.isRequired,
  modalProps: PropTypes.object,
  itemsList: PropTypes.array,
  relatedItemsList: PropTypes.array,
  columns: PropTypes.array.isRequired,
  itemType: PropTypes.string.isRequired,
  addBtnTxt: PropTypes.string.isRequired,
  tableTitle: PropTypes.string,
  onDuplicate: PropTypes.func,
  disabledEdit: PropTypes.bool,
};
