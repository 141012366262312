import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import InterventionForm from '@/features/item-form/InterventionForm';

import Container from '@mui/material/Container';

export default function Intervention() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const emptyItem = {
    type: null,
    etat: null,
    unite_gestion: null,
    qualite_commercialisee: null,
    quantite_commercialisee_m3_stere: null,
    commentaire: null,
    annee_prevue: null,
    date_effective: null,
    surface: null,
  };

  const item = useItem(itemId, 'interventions', emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <InterventionForm item={item} />}
    </Container>
  );
}
