import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import confirmationReducer from './slices/confirmationSlice';
import datagridReducer from './slices/datagridSlice';
import isLoadingReducer from './slices/isLoadingSlice';
import itemReducer from './slices/itemSlice';
import messageReducer from './slices/messageSlice';

const thunkArguments = {};

const customizedMiddleware = (getDefaultMiddleware) =>
  getDefaultMiddleware({
    thunk: {
      extraArgument: thunkArguments,
    },
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  });

const authPersistConfig = {
  key: `auth-${process.env.REACT_APP_STORAGE_VERSION}`,
  storage: storage,
};
const persistAuthReducer = persistReducer(authPersistConfig, authReducer);

const datagridPersistConfig = {
  key: `datagrid-${process.env.REACT_APP_STORAGE_VERSION}`,
  storage: storage,
};
const persistDatagridReducer = persistReducer(
  datagridPersistConfig,
  datagridReducer
);

export const reducers = combineReducers({
  persistAuthReducer,
  persistDatagridReducer,
  itemReducer,
  messageReducer,
  confirmationReducer,
  isLoadingReducer,
});

const store = configureStore({
  middleware: customizedMiddleware,
  reducer: reducers,
});

persistStore(store);

thunkArguments.store = store;

export default store;
