import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import useConfirmationDialog from '@/hooks/useConfirmationDialog';

import CustomDataGrid from '@/components/CustomDataGrid';
import TabPanelForm from '@/components/TabPanelForm';

import { setFilteredItems } from '@/slices/itemSlice';

import { fromISODateTime } from '@/utils/date';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';

export default function ContratsNeosylvaTab({ tabValue, contratsProprietaireList }) {

  const { open } = useConfirmationDialog();
  const dispatch = useDispatch();

  const surfaceTotale = useMemo(() => {
    if (!contratsProprietaireList) {
      return;
    }

    const surfaceTotale = contratsProprietaireList
      .filter(contrat => contrat.etat.code === 'SIGNE')
      .reduce((surfaceTotale, contrat) => surfaceTotale + (contrat?.surface_pressentie_sous_contrat || 0), 0);
    return surfaceTotale.toFixed(4);
  }, [contratsProprietaireList]);

  const handleDeleteItem = async (itemId) => {
    dispatch(
      setFilteredItems({
        itemsList: contratsProprietaireList.filter(contrat => contrat.id !== itemId)
      })
    );
  };

  const columns = [
    {
      field: 'delete',
      type: 'actions',
      width: 80,
      getActions: ({ id }) => [
        <GridActionsCellItem
          key={id}
          icon={<EditIcon />}
          label='Éditer'
          onClick={() => console.warn('Not implemented yet')}
        />,
        <GridActionsCellItem
          key={id}
          icon={<DeleteIcon />}
          label='Supprimer'
          onClick={() => handleDeleteItem(id)}
        />
      ]
    },
    {
      field: 'code_chantier',
      headerName: 'Nom du contrat',
      width: 350,
      editable: false,
      valueGetter: (_, row) => row.code_chantier,
    },
    {
      field: 'avancement',
      headerName: 'Avancement',
      width: 250,
      editable: false,
      valueGetter: (_, row) => row.etat.nom,
    },
    {
      field: 'surface_sous_contrat',
      headerName: 'Surface sous contrat (ha)',
      width: 200,
      editable: false,
      valueGetter: (_, row) => row.surface_pressentie_sous_contrat,
    },
    {
      field: 'date_derniere_modif',
      headerName: 'Date de dernière modification',
      width: 200,
      editable: false,
      valueGetter: (_, row) => fromISODateTime(row.last_modification_datetime),
    },
  ];

  const openSaveDialog = async () => {
    await open({
      title: 'Redirection vers la fiche "Contrat"',
      content: 'Voulez-vous sauvegarder les modifications en cours de la fiche propriété ?',
      confirmButton: 'Sauvegarder',
    }) ? console.warn('Navigate to "Fiche Contrat"') : console.warn('Cancel');
  };

  return (
    <TabPanelForm currentValue={tabValue} index={6}>
      <form>
        <Button
          onClick={openSaveDialog}
          variant='contained'
          disabled
        >
          Créer un nouveau contrat
        </Button>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <CustomDataGrid
            columns={columns}
            rows={contratsProprietaireList}
            autoHeight
          />
        </Grid>
        <Typography variant='body2'>Surface totale sous contrat (signé) : {surfaceTotale} ha</Typography>
      </form>
    </TabPanelForm>
  );
}

ContratsNeosylvaTab.propTypes = {
  tabValue: PropTypes.number,
  contratsProprietaireList: PropTypes.array,
};