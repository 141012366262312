import React from 'react';

import PropTypes from 'prop-types';

import CertificationForestiereFormDialog from '@/features/project-form/certification-forestiere/CertificationForestiereFormDialog';
import TableForm from '@/features/project-form/TableForm';

import { fromISODate } from '@/utils/date';

export default function CertificationForestiereTable({
  certificationsList,
}) {

  const columns = [
    {
      headerName: 'Type',
      field: 'type',
      width: 300,
      editable: false,
      valueGetter: (type) => type.nom,
    },
    {
      headerName: 'N° adhérent',
      field: 'numero_adherent',
      width: 300,
      editable: false,
    },
    {
      headerName: 'Date de fin',
      field: 'date_fin',
      width: 150,
      editable: false,
      valueGetter: (date) => fromISODate(date)
    },
  ];

  return (
    <TableForm
      itemsList={certificationsList}
      columns={columns}
      itemType='certifications'
      addBtnTxt='Ajouter une certification'
      ModalForm={CertificationForestiereFormDialog}
    />
  );
}

CertificationForestiereTable.propTypes = {
  certificationsList: PropTypes.array,
};