import { frFR as coreFrFR } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { frFR as dataGridFrFR } from '@mui/x-data-grid/locales';

// A custom theme for this app
const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#678034',
      },
      secondary: {
        main: '#CE0030',
      },
    },
  },
  coreFrFR,
  dataGridFrFR
);

export default theme;
