import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';

const filter = createFilterOptions();

export default function EntrepriseField({
  control,
  defaultValue,
  entreprises,
  fieldName,
}) {

  const [selected, setSelected] = useState(defaultValue);
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          multiple
          loading={!entreprises}
          onChange={(_, newValue) => {
            newValue = newValue.map((v) =>  {
              if(typeof v === 'string') {
                return { nom: v };
              }

              return v.inputValue ? { nom: v.inputValue } : v;
            });
            newValue = [...new Map(newValue.map(e => [e.nom, e])).values()];
            setSelected(newValue);
            onChange(newValue);
          }}
          value={selected}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.nom
            );

            if (
              inputValue !== '' &&
              inputValue.length <= 200 &&
              !isExisting &&
              !!entreprises
            ) {
              filtered.push({
                inputValue,
                nom: `Ajouter l'entreprise "${inputValue}"`,
              });
            }

            return filtered;
          }}
          renderOption={(props, option) => <li {...props} key={option.nom}>{option.nom}</li>}
          renderTags={(tagValue, getTagProps) => 
            tagValue.map((option, index) => <Chip {...getTagProps({ index })} key={option.nom} label={option.nom}/>)
          }
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.nom;
          }}
          freeSolo
          options={entreprises || []}
          defaultValue={defaultValue}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label='Entreprises'
              error={!!error}
              value={value ? value : ''}
              helperText={error ? error.message : null}
            />
          )}
        />
      )}
    />
  );
}

EntrepriseField.propTypes = {
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.array,
  entreprises: PropTypes.array,
  fieldName: PropTypes.string.isRequired,
};
