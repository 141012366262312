import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

export default function useGoBackHistory() {
  const navigate = useNavigate();

  return useCallback(({ defaultPath = '/' }) => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate(defaultPath); // the current entry in the history stack will be replaced with the new one with { replace: true }
    }
  });
}
