import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

import useChoices from '@/hooks/useChoices';
import useFormItem from '@/hooks/useFormItem';

import AutocompleteAPI from '@/components/AutoCompleteAPI';
import DatePickerField from '@/components/controlled-fields/DatePickerField';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import APIService from '@/APIService';
import FormSubmitButtons from '../FormSubmitButtons';

export default function ContratProprietaireForm({ item }) {
  const itemType = 'contrats_proprietaires';

  const itemPath = '/contrats-proprietaires';

  const choices = useChoices(itemType);

  const { onCancel, onSubmit } = useFormItem(item.id, itemType, itemPath);

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: item,
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  const [propriete, setPropriete] = useState(item.propriete);

  return (
    <Box elevation={3} component={Paper} sx={{ width: '100%', p: 3 }}>
      <Typography mb={2} variant='h5' component='div'>
        {item.id ? 'Édition' : 'Création'} contrat propriétaire
      </Typography>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'code_chantier'}
              control={control}
              rules={{ required: 'Code chantier requis.', maxLength: 256 }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Code chantier'}
                  inputProps={{ maxLength: 256 }}
                  required={true}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              name={'eligibilite_presumee_lbc'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label='Éligibilité présumée au LBC'
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              name={'eligibilite_presumee_aide_publique'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label='Éligibilité présumée à une aide publique'
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DatePickerField
              name='date_signature_contrat_seing_prive'
              control={control}
              label='Date de signature du contrat sous seing privé'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DatePickerField
              name='date_fin_contrat'
              control={control}
              label='Date de fin du contrat'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DatePickerField
              name='date_signature_acte_notarie'
              control={control}
              label="Date de signature de l'acte notarié"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DatePickerField
              name='date_premiere_visite_neosylva'
              control={control}
              label='Date de première visite Néosylva'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DatePickerField
              control={control}
              name='date_visite_gestionnaire'
              label='Date de visite du Gestionnaire'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'montant_forfaitaire'}
              control={control}
              rules={{
                pattern: {
                  value: /^\d+(\.\d{0,2})?$/,
                  message: 'Montant invalide.',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Montant forfaitaire'}
                  type={'number'}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  onBlur={(e) => {
                    let val = parseFloat(e.target.value);
                    if (val) {
                      val = val.toFixed(2);
                    }
                    onChange(val);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'pourcentage_services_environnementaux'}
              control={control}
              rules={{
                pattern: {
                  value: /^\d+(\.\d{0,2})?$/,
                  message: 'Recette invalide.',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Pourcentage services environnementaux'}
                  type={'number'}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  onBlur={(e) => {
                    let val = parseFloat(e.target.value);
                    if (val) {
                      val = val.toFixed(2);
                    }
                    onChange(val);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'montant_total_previsionnel_operations'}
              control={control}
              rules={{
                pattern: {
                  value: /^\d+(\.\d{0,2})?$/,
                  message: 'Montant invalide.',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Montant total prévisionnel des opérations'}
                  type={'number'}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  onBlur={(e) => {
                    let val = parseFloat(e.target.value);
                    if (val) {
                      val = val.toFixed(2);
                    }
                    onChange(val);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DatePickerField
              control={control}
              name='date_paiement_forfaitaire'
              label='Date paiement forfaitaire'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'etat'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel id='etat-select-label'>État</InputLabel>
                  <Select
                    labelId='etat-select-label'
                    id='etat-select'
                    onChange={onChange}
                    value={value || ''}
                    label='État'
                    error={!!error}
                    renderValue={(value) => value.nom}
                  >
                    {choices &&
                      choices.etats.map((etat) => (
                        <MenuItem key={etat.id} value={etat}>
                          {etat.nom}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name={'referent_technique'}
              control={control}
              rules={{ required: 'Référent technique contractuel requis.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <AutocompleteAPI
                  getItemDescription={(item) => {
                    let description = item.nom;
                    if (item.prenom) {
                      description = `${item.prenom} ${description}`;
                    }
                    return description;
                  }}
                  label={'Référent technique contractuel'}
                  searchFunc={(searchTerm) =>
                    APIService.get({
                      url: 'contacts/search/',
                      opts: { params: { name: searchTerm } },
                    })
                  }
                  required={true}
                  multiple={false}
                  onChange={onChange}
                  error={error}
                  value={value}
                  defaultValue={item.referent_technique}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name={'proprietaires_list'}
              control={control}
              rules={{ required: 'Propriétaires requis.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <AutocompleteAPI
                  getItemDescription={(item) => {
                    let description = item.contact.nom;
                    if (item.contact.prenom) {
                      description = `${item.contact.prenom} ${description}`;
                    }
                    return description;
                  }}
                  label={'Propriétaires'}
                  searchFunc={(searchTerm) =>
                    APIService.get({
                      url: 'proprietaires/search/',
                      opts: { params: { name: searchTerm } },
                    })
                  }
                  required={true}
                  multiple={true}
                  onChange={onChange}
                  error={error}
                  value={value}
                  defaultValue={item.proprietaires_list}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name={'propriete'}
              control={control}
              rules={{ required: 'Propriété requise.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <AutocompleteAPI
                  required={true}
                  getItemDescription={(item) => item.nom_commun || ''}
                  label={'Propriété'}
                  searchFunc={(searchTerm) =>
                    APIService.get({
                      url: 'proprietes/search/',
                      opts: { params: { name: searchTerm } },
                    })
                  }
                  onChange={(val) => {
                    setPropriete(val);
                    return onChange(val);
                  }}
                  error={error}
                  value={value}
                  defaultValue={item.propriete}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name={'unites_gestion_list'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <AutocompleteAPI
                  onChange={onChange}
                  error={error}
                  value={value}
                  disabled={!propriete}
                  getItemDescription={(item) => item.nom || ''}
                  label={'Unités de gestion'}
                  searchFunc={(searchTerm) =>
                    APIService.get({
                      url: `unites_gestion/${propriete.id}/search/`,
                      opts: { params: { name: searchTerm } },
                    })
                  }
                  multiple={true}
                  defaultValue={item.unites_gestion_list}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name={'points_vigilance'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={
                    'Éléments pouvant conditionner la faisabilité du projet'
                  }
                  fullWidth
                  error={!!error}
                  multiline
                  minRows={3}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name={'itineraires_sylvicoles_pressentis'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={
                    'État actuel et itinéraire(s) sylvicole(s) pressenti(s)'
                  }
                  fullWidth
                  error={!!error}
                  multiline
                  minRows={3}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'surface_pressentie_sous_contrat'}
              control={control}
              rules={{
                pattern: {
                  value: /^\d+(\.\d{0,4})?$/,
                  message: 'Surface pressentie sous contrat invalide.',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Surface pressentie sous contrat (ha)'}
                  type={'number'}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  onBlur={(e) => {
                    let val = parseFloat(e.target.value);
                    if (val) {
                      val = val.toFixed(4);
                    }
                    onChange(val);
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <FormSubmitButtons
          onSubmit={handleSubmit(onSubmit)}
          submitLabel={item.id ? 'Éditer' : 'Créer'}
          disabledSubmit={!isDirty || !isValid}
          onCancel={onCancel}
        />
      </form>
    </Box>
  );
}

ContratProprietaireForm.propTypes = {
  item: PropTypes.object.isRequired,
};
