import React from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { setIsLoading } from '@/slices/isLoadingSlice';

import Button from '@mui/material/Button';

export default function SubmitHandlerButton({
  onSubmit,
  label,
  disabledSubmit,
  ...props
}) {
  const dispatch = useDispatch();
  const isLoading = useSelector((store) => store.isLoadingReducer.isLoading);
  const onSubmitHandler = () => {
    dispatch(setIsLoading(true));
    onSubmit();
  };
  return (
    <Button
      disabled={disabledSubmit || isLoading}
      onClick={onSubmitHandler}
      {...props}
    >
      {label}
    </Button>
  );
}

SubmitHandlerButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabledSubmit: PropTypes.bool.isRequired,
};
