import { useDispatch, useSelector } from 'react-redux';

import {
  confirmAction,
  declineAction,
  openDialogThunk,
} from '@/slices/confirmationSlice';

export default function useConfirmationDialog() {
  const dispatch = useDispatch();

  const { isOpened, content, title, confirmButton } = useSelector(
    (state) => state.confirmationReducer
  );

  const open = async (opts) =>
    dispatch(openDialogThunk(opts)).then(({ payload }) => payload);

  const confirm = () => dispatch(confirmAction());

  const decline = () => dispatch(declineAction());

  return {
    isOpened,
    open,
    confirm,
    decline,
    content,
    title,
    confirmButton,
  };
}
