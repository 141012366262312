import React from 'react';

import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import useChoices from '@/hooks/useChoices';
import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useItems from '@/hooks/useItems';

import CellExpand from '@/components/CellExpand';
import CustomDataGrid from '@/components/CustomDataGrid';
import ManyItemsCell from '@/components/ManyItemsCell';
import XLSXExportButton from '@/components/XLSXExportButton';

import { manyItemsFilterOperator } from '@/features/manyItemsFilterOperator';

import { deleteItem } from '@/slices/itemSlice';

import { fromISODateTime } from '@/utils/date';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';

export default function Proprietes() {
  const { open } = useConfirmationDialog();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const itemType = 'proprietes';

  const choices = useChoices(itemType);

  const items = useItems(itemType);

  const handleEditItem = (itemId) => {
    navigate(`/propriete/${itemId}`);
  };

  const handleDeleteItem = async (itemId, itemName) => {
    const isConfirmed = await open({
      title: 'Suppression',
      content: `Confirmer la suppression de ${itemName} ?`,
    });

    if (isConfirmed) {
      dispatch(deleteItem({ itemId, itemType }));
    }
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<EditIcon />}
          label='Éditer'
          onClick={() => handleEditItem(params.id)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<DeleteIcon />}
          label='Supprimer'
          onClick={() => handleDeleteItem(params.id, params.row.nom_commun)}
        />,
      ],
    },
    {
      field: 'nom_commun',
      headerName: 'Nom commun',
      width: 220,
      editable: false,
      renderCell: ({ row }) => <CellExpand value={row.nom_commun} />,
    },
    {
      field: 'proprietaires_list',
      headerName: 'Propriétaires',
      width: 260,
      valueGetter: (value) =>
        value.map((proprietaire) => proprietaire.contact.nom),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.proprietaires_list,
            getRelatedName: (proprietaire) => proprietaire.contact.nom,
            relatedPath: '/proprietaire',
            relatedVerboseName: 'propriétaire',
          }}
        />
      ),
    },
    {
      field: 'dgd_list',
      headerName: 'DGD',
      width: 260,
      valueGetter: (value) => value.map((dgd) => dgd.type.nom),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.dgd_list,
            getRelatedName: (dgd) => dgd.type.code,
            relatedPath: '/document-gestion-durable',
            relatedVerboseName: 'document',
          }}
        />
      ),
    },
    {
      field: 'adresse',
      headerName: 'Adresse',
      width: 220,
      editable: false,
    },
    {
      field: 'commune',
      headerName: 'Commune',
      width: 200,
      editable: false,
    },
    {
      field: 'code_postal',
      headerName: 'Code postal',
      width: 120,
      editable: true,
    },
    {
      field: 'departement',
      headerName: 'Département',
      type: 'singleSelect',
      valueOptions: choices?.departements,
      width: 100,
      editable: false,
    },
    {
      field: 'cmf_neosylva',
      headerName: 'CMF Néosylva',
      width: 160,
      editable: false,
      valueGetter: (value) => value?.username,
    },
    {
      field: 'adhesion_syndicat',
      headerName: 'Adhésion syndicat propriétaire privé',
      width: 140,
      type: 'boolean',
      editable: false,
    },
    {
      field: 'adhesion_syndicat_detail',
      headerName: 'Syndicat propriétaire privé',
      width: 220,
      editable: false,
    },
    {
      field: 'surface_totale_propriete',
      headerName: 'Surface totale propriété (ha)',
      width: 160,
      type: 'number',
      editable: false,
      valueFormatter: (value) => (value ? value.toFixed(4) : null),
    },
    {
      field: 'existence_dgd',
      headerName: 'Existence DGD',
      width: 160,
      type: 'boolean',
      editable: false,
    },
    {
      field: 'gestionnaire_preexistant',
      headerName: "Existence d'un gestionnaire",
      width: 160,
      type: 'boolean',
      editable: false,
    },
    {
      field: 'droits_usage_servitudes_divers',
      headerName: "Droits d'usage et servitudes diverses",
      width: 220,
      editable: false,
    },
    {
      field: 'objectifs_gestion_list',
      headerName: 'Objectifs gestion',
      type: 'singleSelect',
      valueOptions: choices?.objectifs_gestion_types,
      filterOperators: manyItemsFilterOperator(),
      width: 260,
      valueGetter: (value) => value?.join(', '),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.objectifs_gestion_list
              ? row.objectifs_gestion_list
              : [],
            getRelatedName: (objectif) => objectif,
            relatedVerboseName: 'objectif',
          }}
        />
      ),
    },
    {
      field: 'historique_gestion',
      headerName: 'Historique gestion',
      width: 220,
      editable: false,
    },
    {
      field: 'certification_forestiere',
      headerName: 'Certification forestière',
      width: 160,
      type: 'boolean',
      editable: false,
    },
    {
      field: 'acces_massif',
      headerName: 'Accès massif',
      type: 'singleSelect',
      valueOptions: choices?.acces_massif_types.map((value) => value.nom),
      width: 260,
      valueGetter: (value) => value?.nom,
    },
    {
      field: 'acces_grumier',
      headerName: 'Accès grumiers',
      type: 'singleSelect',
      valueOptions: choices?.acces_grumier_types.map((value) => value.nom),
      width: 260,
      valueGetter: (value) => value?.nom,
    },
    {
      field: 'nb_place_depot',
      headerName: 'Nombre de places de dépôt',
      width: 160,
      type: 'number',
      editable: false,
    },
    {
      field: 'besoin_acces_supplementaire',
      headerName: 'Accessibilité et équipement de la forêt',
      width: 220,
      editable: false,
    },
    {
      field: 'historique_gestion',
      headerName: 'Historique de gestion sur la propriété',
      width: 220,
      editable: false,
    },
    {
      field: 'description_filiere_bois',
      headerName: 'Description de la filière bois',
      width: 220,
      editable: false,
    },
    {
      field: 'charges',
      headerName: 'Charges',
      width: 220,
      editable: false,
    },
    {
      field: 'ressources',
      headerName: 'Ressources',
      width: 220,
      editable: false,
    },
    {
      field: 'equipement_defense_incendie',
      headerName: 'Équipements pour la défense contre les incendies',
      width: 220,
      editable: false,
    },
    {
      field: 'enjeux_economiques_gestion_forestiere',
      headerName:
        'Prise en compte des enjeux économiques de la gestion forestière',
      width: 220,
      editable: false,
    },
    {
      field: 'frequentation_public',
      headerName: 'Fréquentation public',
      width: 160,
      type: 'boolean',
      editable: false,
    },
    {
      field: 'mesure_accueil_public',
      headerName: 'Mesures pour accueillir et canaliser le public',
      width: 160,
      type: 'boolean',
      editable: false,
    },
    {
      field: 'mesure_reduction_frequentation',
      headerName: 'Mesures pour dissuader voire interdire la fréquentation',
      width: 160,
      type: 'boolean',
      editable: false,
    },
    {
      field: 'balisage_particulier',
      headerName: "Existence d'un balisage particulier",
      width: 160,
      type: 'boolean',
      editable: false,
    },
    {
      field: 'convention_ouverture_publique',
      headerName: "Existence d'une convention d'ouverture au public",
      width: 160,
      type: 'boolean',
      editable: false,
    },
    {
      field: 'importance_frequentation',
      headerName: 'Importance  et nature de la fréquentation',
      width: 220,
      editable: false,
    },
    {
      field: 'impact_frequentation_gestion',
      headerName:
        'Prise en compte des enjeux sociaux dans la gestion forestière',
      width: 220,
      editable: false,
    },
    {
      field: 'acca',
      headerName: 'ACCA',
      width: 160,
      type: 'boolean',
      editable: false,
    },
    {
      field: 'societe_chasse',
      headerName: 'Société de chasse',
      width: 160,
      type: 'boolean',
      editable: false,
    },
    {
      field: 'reservataire',
      headerName: 'Le propriétaire est réservataire (Alsace-Moselle)',
      width: 160,
      type: 'boolean',
      editable: false,
    },
    {
      field: 'plan_chasse',
      headerName: "Existence d'un plan de chasse",
      width: 160,
      type: 'boolean',
      editable: false,
    },
    {
      field: 'identite_detenteur_droit_chasse',
      headerName: 'Identité du détenteur du droit de chasse',
      width: 220,
      editable: false,
    },
    {
      field: 'degats_constates',
      headerName: 'Dégâts constatés',
      type: 'singleSelect',
      valueOptions: choices?.degats_constates_types.map((value) => value.nom),
      width: 260,
      valueGetter: (value) => value?.nom,
    },
    {
      field: 'natures_degats_list',
      headerName: 'Natures dégâts constatés',
      type: 'singleSelect',
      valueOptions: choices?.natures_degats_types,
      filterOperators: manyItemsFilterOperator(),
      width: 260,
      valueGetter: (value) => value?.join(', '),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.natures_degats_list
              ? row.natures_degats_list
              : [],
            getRelatedName: (nature) => nature,
            relatedVerboseName: 'nature',
          }}
        />
      ),
    },
    {
      field: 'commentaire_chasse_pression_gibier',
      headerName: 'Pression gibier constatée',
      width: 220,
      editable: false,
    },
    {
      field: 'especes_plan_chasse_list',
      headerName: 'Espèces plan de chasse',
      width: 260,
      valueGetter: (value) =>
        value.map(
          (espece_plan_chasse) => espece_plan_chasse.espece
        ),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.especes_plan_chasse_list,
            getRelatedName: (espece_plan_chasse) => espece_plan_chasse.espece,
            relatedPath: '/espece-plan-chasse',
            relatedVerboseName: 'espèce',
          }}
        />
      ),
    },
    {
      field: 'acces_ouvrages_list',
      headerName: 'Accès et ouvrages',
      width: 260,
      valueGetter: (value) => {
        return value.map(
          (acces_ouvrage) => acces_ouvrage.code
        );
      },
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.acces_ouvrages_list,
            getRelatedName: (acces_ouvrages) => acces_ouvrages.code,
            relatedVerboseName: 'accès et ouvrage',
          }}
        />
      ),
    },
    {
      field: 'catalogue_station',
      headerName: 'Catalogue station',
      width: 160,
      type: 'boolean',
      editable: false,
    },
    {
      field: 'catalogue_station_detail',
      headerName: 'Détail catalogue station',
      width: 220,
      editable: false,
    },
    {
      field: 'zonages_identifies_list',
      headerName: 'Zonages identifiés',
      type: 'singleSelect',
      valueOptions: choices?.zonages_identifies_types,
      filterOperators: manyItemsFilterOperator(),
      width: 260,
      valueGetter: (value) => value?.join(', '),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.zonages_identifies_list
              ? row.zonages_identifies_list
              : [],
            getRelatedName: (zonage) => zonage,
            relatedVerboseName: 'zonage',
          }}
        />
      ),
    },
    {
      field: 'zonages_identifies_commentaire',
      headerName: 'Autre(s) zonage(s)/ commentaires',
      width: 220,
      editable: false,
    },
    {
      field: 'elements_biodiversite_list',
      headerName: 'Éléments de biodiversité',
      width: 260,
      valueGetter: (value) =>
        value.map((elmnt) => elmnt.numero),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.elements_biodiversite_list,
            getRelatedName: (elmnt) => elmnt.numero,
            relatedPath: '/element-biodiversite',
            relatedVerboseName: 'élément',
          }}
        />
      ),
    },
    {
      field: 'stations_list',
      headerName: 'Stations',
      width: 260,
      valueGetter: (value) =>
        value.map((station) => station.numero),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.stations_list,
            getRelatedName: (station) => station.numero,
            relatedPath: '/station',
            relatedVerboseName: 'station',
          }}
        />
      ),
    },
    {
      field: 'gestionnaire',
      headerName: 'Gestionnaire professionnel',
      width: 260,
      valueGetter: (value) => value?.gestionnaire?.contact?.nom, // For filtering ...
      renderCell: ({ row }) =>
        row.gestionnaire && (
          <Link
            component={RouterLink}
            to={`/gestionnaire-pro/${row.gestionnaire.id}`}
          >
            {row.gestionnaire.contact.nom}
          </Link>
        ),
    },
    {
      field: 'contrats_proprietaires_list',
      headerName: 'Contrats proprietaires',
      width: 260,
      valueGetter: (value) =>
        value.map((contrat) => contrat.code_chantier),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.contrats_proprietaires_list,
            getRelatedName: (contrat) => contrat.code_chantier,
            relatedPath: '/contrat-proprietaire',
            relatedVerboseName: 'contrat',
          }}
        />
      ),
    },
    {
      field: 'certifications_list',
      headerName: 'Certifications',
      width: 260,
      valueGetter: (value) =>
        value.map((certification) => certification.type.nom),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.certifications_list,
            getRelatedName: (certification) => certification.type.nom,
            relatedPath: '/certification',
            relatedVerboseName: 'certification',
          }}
        />
      ),
    },
    {
      field: 'creation_datetime',
      headerName: 'Création',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
    {
      field: 'last_modification_datetime',
      headerName: 'Dernière modification',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
  ];

  const handleFormRedirect = () => {
    navigate('/propriete');
  };

  return (
    <Box component='main' mx={3} mt={2}>
      <Typography mb={1} variant='h5' component='div'>
        Propriétés
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm='auto'>
          <Button variant='contained' onClick={handleFormRedirect} fullWidth>
            Ajouter une propriété
          </Button>
        </Grid>
        <Grid item xs={12} sm='auto'>
          <XLSXExportButton itemType={itemType} fullWidth />
        </Grid>
      </Grid>
      <CustomDataGrid columns={columns} rows={items} />
    </Box>
  );
}