
export const titleCase = (str) =>
  `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`;

export const incrementAlphabetChar = (str) => {

  if (!str) {
    return 'A';
  }

  let arr = [...str];

  for (let i = arr.length - 1; i >= 0; i--) {
    if (arr[i] !== 'Z') {
      arr[i] = String.fromCharCode(arr[i].charCodeAt(0) + 1);
      return arr.join('');
    } else {
      arr[i] = 'A';
    }
  }

  // If all characters were 'Z', prepend 'A' and return
  arr.unshift('A');
  return arr.join('');
};
