import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import TextField from '@mui/material/TextField';

export default function BaseTextField({
  control,
  name,
  label,
  pattern,
  required,
  maxLength,
  minLength,
  fixedLength,
  onBlur,
  validate,
  disabled,
  inputMode,
  customOnChange,
  valueFormatter,
  ...textFieldProps
}) {
  if (fixedLength) {
    maxLength = {
      value: fixedLength.value,
      message: fixedLength.message,
    };
    minLength = {
      value: fixedLength.value,
      message: fixedLength.message,
    };
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required,
        maxLength,
        minLength,
        pattern,
        validate,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          onChange={({ target: { value } }) => {
            customOnChange?.(value);
            onChange(value);
          }}
          onBlur={(e) => onBlur?.(e, onChange)}
          value={valueFormatter?.(value) || value || ''}
          label={label}
          disabled={disabled}
          inputProps={{
            role: 'input',
            maxLength:
              typeof maxLength === 'object' ? maxLength.value : maxLength,
            minLength: minLength?.value,
            inputMode: inputMode,
          }}
          fullWidth={true}
          required={typeof required === 'string' ? Boolean(required) : required}
          error={!!error}
          helperText={error?.message}
          {...textFieldProps}
        />
      )}
    />
  );
}

BaseTextField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fixedLength: PropTypes.object,
  maxLength: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  minLength: PropTypes.object,
  pattern: PropTypes.object,
  onBlur: PropTypes.func,
  validate: PropTypes.func,
  inputMode: PropTypes.string,
  customOnChange: PropTypes.func,
  valueFormatter: PropTypes.func,
  disabled: PropTypes.bool,
};
