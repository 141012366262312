import React from 'react';

import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

import useChoices from '@/hooks/useChoices';
import useFormItem from '@/hooks/useFormItem';

import AutocompleteAPI from '@/components/AutoCompleteAPI';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import APIService from '@/APIService';
import FormSubmitButtons from '../FormSubmitButtons';

export default function EspecePlanChasseForm({ item }) {
  const itemType = 'especes_plan_chasse';

  const choices = useChoices(itemType);

  const itemPath = '/especes-plan-chasse';

  const { onCancel, onSubmit } = useFormItem(item.id, itemType, itemPath);

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: item,
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  return (
    <Box elevation={3} component={Paper} sx={{ width: '100%', p: 3 }}>
      <Typography mb={2} variant='h5' component='div'>
        {item.id ? 'Édition' : 'Création'} espèce plan de chasse
      </Typography>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'espece'}
              control={control}
              rules={{ maxLength: 256, required: 'Espèce requise.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Espèce'}
                  inputProps={{ maxLength: 256 }}
                  required={true}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'nb_bracelet'}
              control={control}
              rules={{
                pattern: {
                  value: /^\d+$/,
                  message: 'Nombre de bracelets invalide.',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Nombre de bracelets'}
                  fullWidth
                  type={'number'}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  onBlur={(e) => {
                    let val = parseFloat(e.target.value);
                    if (val) {
                      val = val.toFixed(0);
                    }
                    onChange(val);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'evolution'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel id='evolution-select-label'>Évolution</InputLabel>
                  <Select
                    labelId='evolution-select-label'
                    id='evolution-select'
                    onChange={onChange}
                    value={value || ''}
                    label='Évolution'
                    error={!!error}
                    renderValue={(value) => value.nom}
                  >
                    {choices &&
                      choices.evolutions_types.map((item) => (
                        <MenuItem key={item.id} value={item}>
                          {item.nom}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'propriete'}
              control={control}
              rules={{ required: 'Propriété requise.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <AutocompleteAPI
                  onChange={onChange}
                  error={error}
                  value={value}
                  getItemDescription={(item) => item.nom_commun || ''}
                  label={'Propriété'}
                  searchFunc={(searchTerm) =>
                    APIService.get({
                      url: 'proprietes/search/',
                      opts: { params: { name: searchTerm } },
                    })
                  }
                  required={true}
                  defaultValue={item.propriete}
                />
              )}
            />
          </Grid>
        </Grid>
        <FormSubmitButtons
          onSubmit={handleSubmit(onSubmit)}
          submitLabel={item.id ? 'Éditer' : 'Créer'}
          disabledSubmit={!isDirty || !isValid}
          onCancel={onCancel}
        />
      </form>
    </Box>
  );
}

EspecePlanChasseForm.propTypes = {
  item: PropTypes.object.isRequired,
};
