import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Controller, useForm, useWatch } from 'react-hook-form';

import useChoices from '@/hooks/useChoices';

import AutocompleteAPI from '@/components/AutoCompleteAPI';
import SelectField from '@/components/controlled-fields/SelectField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import CodePostalTextField from '@/components/controlled-fields/text-fields/CodePostalTextField';
import MailTextField from '@/components/controlled-fields/text-fields/MailTextField';

import AdresseField from '@/features/AdresseField';
import DepartementField from '@/features/DepartementField';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import APIService from '@/APIService';

export default function IndivisaireFormDialog({
  editItem,
  open,
  setOpen,
  onSubmit,
  othersIndivisairesContactsIdList,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const choices = useChoices('indivisaires');

  const IndivisaireForm = ({ item }) => {
    const {
      handleSubmit,
      control,
      setValue,
      formState: { isDirty, isValid },
    } = useForm({
      defaultValues: item
        ? item
        : {
          proprietaire: {
            contact: {
              nom: null,
              prenom: null,
              adresse: null,
              commune: null,
              code_postal: null,
              departement: null,
              email: null,
              telephone_fixe: null,
              telephone_portable: null,
            },
          },
          nature_indivisaire: null,
        },
      reValidateMode: 'onBlur',
      mode: 'onChange',
    });

    const { control: searchContactForm } = useForm({
      defaultValues: { contact: null },
    });

    const searchContactWatch = useWatch({
      control: searchContactForm,
      name: 'contact',
    });

    useEffect(() => {
      if (
        !searchContactWatch ||
        othersIndivisairesContactsIdList.includes(searchContactWatch?.id)
      ) {
        return;
      }

      // Reset invivisaire id
      setValue('id', null);

      // Set contact fields values
      setValue('proprietaire.contact.id', searchContactWatch.id);

      setValue('proprietaire.contact.nom', searchContactWatch.nom, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue('proprietaire.contact.prenom', searchContactWatch.prenom, {
        shouldValidate: true,
      });
      setValue('proprietaire.contact.adresse', searchContactWatch.adresse, {
        shouldValidate: true,
      });
      setValue('proprietaire.contact.commune', searchContactWatch.commune, {
        shouldValidate: true,
      });
      setValue(
        'proprietaire.contact.code_postal',
        searchContactWatch.code_postal,
        {
          shouldValidate: true,
        }
      );
      setValue(
        'proprietaire.contact.departement',
        searchContactWatch.departement,
        {
          shouldValidate: true,
          shouldDirty: true,
        }
      );
      setValue('proprietaire.contact.email', searchContactWatch.email, {
        shouldValidate: true,
      });
      setValue(
        'proprietaire.contact.telephone_fixe',
        searchContactWatch.telephone_fixe,
        {
          shouldValidate: true,
        }
      );
      setValue(
        'proprietaire.contact.telephone_portable',
        searchContactWatch.telephone_portable,
        {
          shouldValidate: true,
        }
      );
    }, [searchContactWatch]);

    return (
      <form>
        <DialogTitle>{item ? 'Éditer' : 'Créer'} un indivisaire</DialogTitle>
        <DialogContent>
          <Grid container sx={{ p: 2 }} spacing={2} alignItems='center'>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name={'contact'}
                control={searchContactForm}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <AutocompleteAPI
                    onChange={onChange}
                    error={error}
                    value={value}
                    getItemDescription={(item) => item.nom || ''}
                    label={'Contact existant'}
                    searchFunc={(searchTerm) =>
                      APIService.get({
                        url: 'contacts/search/',
                        opts: {
                          params: { name: searchTerm, with_details: true },
                        },
                      })
                    }
                    defaultValue={item?.proprietaire?.contact}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <BaseTextField
                control={control}
                name='proprietaire.contact.nom'
                label='Nom'
                required='Nom requis.'
                maxLength={256}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <BaseTextField
                control={control}
                name='proprietaire.contact.prenom'
                label='Prénom'
                maxLength={256}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <BaseTextField
                control={control}
                name='proprietaire.contact.telephone_fixe'
                label='Téléphone fixe'
                maxLength={16}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <BaseTextField
                control={control}
                name='proprietaire.contact.telephone_portable'
                label='Téléphone portable'
                maxLength={16}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <MailTextField
                control={control}
                name='proprietaire.contact.email'
                label='E-mail'
              />
            </Grid>
            <Grid item xs={12} sm={7} md={5}>
              <AdresseField
                control={control}
                fieldName={'proprietaire.contact.adresse'}
                relatedFiedlNames={{
                  commune: 'proprietaire.contact.commune',
                  codePostal: 'proprietaire.contact.code_postal',
                }}
                label={'Adresse'}
                setValue={setValue}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <BaseTextField
                control={control}
                name='proprietaire.contact.commune'
                label='Commune'
                maxLength={256}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <CodePostalTextField
                control={control}
                name='proprietaire.contact.code_postal'
                label='Code postal'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DepartementField
                label={'Département'}
                departements={choices?.departements}
                control={control}
                departementFieldName={'proprietaire.contact.departement'}
                codePostalFieldName={'proprietaire.contact.code_postal'}
                setValue={setValue}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectField
                control={control}
                name='nature_indivisaire'
                label='Nature indivisaire'
                values={choices?.natures}
                baseId='nature-indivisaire'
                renderValue={(value) => value.nom}
                itemMapper={(origine) => ({
                  key: origine.id,
                  label: origine.nom,
                })}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            disabled={!isDirty || !isValid}
            onClick={handleSubmit(onSubmit)}
          >
            {item ? 'Éditer' : 'Créer'}
          </Button>
        </DialogActions>
      </form>
    );
  };

  IndivisaireFormDialog.propTypes = {
    item: PropTypes.object,
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
      <IndivisaireForm item={editItem} />
    </Dialog>
  );
}

IndivisaireFormDialog.propTypes = {
  editItem: PropTypes.object,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  othersIndivisairesContactsIdList: PropTypes.array,
};
