import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import InterventionPasseeForm from '@/features/item-form/InterventionPasseeForm';

import Container from '@mui/material/Container';

export default function InterventionPassee() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const emptyItem = {
    type: null,
    dgd: null,
    annee_prevue: null,
    nom_ancienne_parcelle: null,
    commentaire: null,
  };

  const item = useItem(itemId, 'interventions_passees', emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <InterventionPasseeForm item={item} />}
    </Container>
  );
}
