import React, { useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import { useForm, useWatch } from 'react-hook-form';

import useChoices from '@/hooks/useChoices';

import DatePickerField from '@/components/controlled-fields/DatePickerField';
import SelectField from '@/components/controlled-fields/SelectField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

export default function CertificationForestiereFormDialog({
  editItem,
  open,
  setOpen,
  onSubmit,
}) {
  const choices = useChoices('certifications');

  const CertificationForestiereForm = ({ currentItem }) => {
    const {
      handleSubmit,
      control,
      formState: { isDirty, isValid },
      setValue,
    } = useForm({
      defaultValues: currentItem || {
        type: null,
        surface: null,
        numero_adherent: null,
      },
      reValidateMode: 'onBlur',
      mode: 'onChange',
    });

    const typeWatch = useWatch({
      control,
      name: 'type',
    });
    const isPEFC = useMemo(() => typeWatch?.nom === 'PEFC', [typeWatch?.nom]);
    useEffect(() => {
      if (!isPEFC && typeWatch) {
        setValue('date_fin', null, { shouldValidate: true });
      }
    }, [isPEFC]);

    return (
      <form>
        <DialogTitle>
          {editItem ? 'Éditer' : 'Créer'} une certification
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ p: 2 }} spacing={2} alignItems='center'>
            <Grid item xs={12}>
              <SelectField
                control={control}
                name='type'
                label='Type'
                required='Type requis.'
                renderValue={(value) => value.nom}
                values={choices?.types}
                itemMapper={(item) => ({
                  key: item.id,
                  label: item.nom,
                })}
              />
            </Grid>
            <Grid item xs={isPEFC ? 6 : 12}>
              <BaseTextField
                control={control}
                name='numero_adherent'
                label='Numéro adhérent'
                maxLength={30}
              />
            </Grid>
            {isPEFC && <Grid item xs={6}>
              <DatePickerField
                name='date_fin'
                control={control}
                label='Date de fin'
              />
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Annuler</Button>
          <Button
            disabled={!isDirty || !isValid}
            onClick={handleSubmit(onSubmit)}
          >
            {editItem ? 'Éditer' : 'Créer'}
          </Button>
        </DialogActions>
      </form>
    );
  };

  CertificationForestiereForm.propTypes = {
    currentItem: PropTypes.object,
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <CertificationForestiereForm currentItem={editItem} />
    </Dialog>
  );
}

CertificationForestiereFormDialog.propTypes = {
  editItem: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};