import { format } from 'date-fns';

export const toISODate = (dateStr) => {
  let date;
  try {
    date = dateStr ? format(new Date(dateStr), 'yyyy-MM-dd') : null;
  } catch {}

  return date;
};

export const fromISODate = (dateStr) => dateStr ? format(new Date(dateStr), 'dd/MM/yyyy') : null;

export const fromISODateTime = (datetimeStr) =>
  datetimeStr ? format(new Date(datetimeStr), 'dd/MM/yyyy HH:mm') : null;
