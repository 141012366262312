import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import useOnMount from '@/hooks/useOnMount';

import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';

import SubmitHandlerButton from '@/features/SubmitHandlerButton';

import { loginUser } from '@/slices/authSlice';
import { setIsLoading } from '@/slices/isLoadingSlice';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function Login() {
  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: { username: '', password: '' },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useOnMount(() => {
    if(history.state?.reload) {
      history.replaceState({ ...history.state, reload: false }, '');
      navigate(0);
    }
  });

  const onSubmit = (loginData) => {
    dispatch(loginUser(loginData))
      .then(() => {
        navigate('/');
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 2,
            textAlign: 'center',
          }}
        >
          <Typography mb={2} variant='h5' component='div'>
            Connexion
          </Typography>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <BaseTextField
                  name='username'
                  control={control}
                  label='Identifiant'
                  required={true}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <BaseTextField 
                  name='password'
                  control={control}
                  label='Mot de passe requis.'
                  required={true}
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <SubmitHandlerButton
              onSubmit={handleSubmit(onSubmit)}
              label='Se connecter'
              disabledSubmit={!isDirty || !isValid}
              sx={{ mt: 3, mb: 1 }}
              variant='contained'
              fullWidth
            />
          </form>
          <Link component={RouterLink} to='/password-reset-demand'>
            Mot de passe oublié ?
          </Link>
        </Paper>
      </Box>
    </Container>
  );
}
