import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import StockCarboneForm from '@/features/item-form/StockCarboneForm';

import Container from '@mui/material/Container';

export default function StockCarbone() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const emptyItem = {
    nom_projet: null,
    surface_eligible: null,
    methodologie_carbone: null,
    rea_total: null,
    rea_foret: null,
    rea_produit: null,
    rea_substitution: null,
    statut: null,
    date_notification_lbc: null,
    date_labellisation_lbc: null,
    date_verification_lbc: null,
    num_demarche_simplifiee: null,
    commentaire: null,
    unite_gestion: null,
  };

  const item = useItem(itemId, 'stocks_carbone', emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <StockCarboneForm item={item} />}
    </Container>
  );
}
