import { useCallback } from 'react';

import { downloadFromUrl } from '@/utils/file';

export default function useDownloadDocument() {
  return useCallback((response) => {
    if (!response) {
      return;
    }
    const resData = response?.data;
    const resHeader = response?.headers;
    document.body.style.cursor = 'wait';
    const href = window.URL.createObjectURL(new Blob([resData]));
    downloadFromUrl(href, resHeader['content-disposition'].split('=')[1]);
    URL.revokeObjectURL(href);
    document.body.style.cursor = 'default';
  });
}
