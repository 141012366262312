import React from 'react';

import PropTypes from 'prop-types';

import useItems from '@/hooks/useItems';

import SelectField from '@/components/controlled-fields/SelectField';
import SwitchField from '@/components/controlled-fields/SwitchField';
import MultilineTextField from '@/components/controlled-fields/text-fields/MultilineTextField';
import TabPanelForm from '@/components/TabPanelForm';

import Grid from '@mui/material/Grid';

export default function RelationNeosylvaTab({
  form,
  tabValue,
  choices,
  isProp,
  createPropriete,
}) {
  const { control } = form;

  const usersList = useItems('users');

  return (
    <TabPanelForm currentValue={tabValue} index={isProp ? 2 : 1}>
      <form>
        <Grid container spacing={2} alignItems='center'>
          {isProp && (
            <Grid item xs={12} sm={6} md={3}>
              <SelectField
                control={control}
                name='origine_commerciale_proprietaire'
                required='Origine commerciale requise.'
                label='Origine commerciale'
                baseId='origine-commerciale'
                renderValue={(value) => value.nom}
                values={choices?.origines_commerciales_types}
                itemMapper={(origine) => ({
                  key: origine.id,
                  label: origine.nom,
                })}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={3}>
            <SelectField
              control={control}
              name='contact_neosylva'
              required='Contact privilégié Néosylva requis.'
              label='Contact privilégié Néosylva'
              baseId='neosylva'
              renderValue={(value) =>
                `${value.first_name} ${value.last_name}`
              }
              values={
                usersList &&
                  usersList.filter((user) =>
                    ['neosylva', 'neosylva_cmf'].includes(user.role.name)
                  )
              }
              itemMapper={(user) => ({
                key: user.username,
                label: `${user.first_name} ${user.last_name}`,
              })}
            />
          </Grid>
          {isProp && (
            <Grid item xs={12} sm={5} md={3}>
              <SelectField
                control={control}
                name='propriete.cmf_neosylva'
                baseId='cmf-neosylva'
                required='CMF Néosylva requis.'
                label='CMF Néosylva'
                disabled={!createPropriete}
                renderValue={(value) =>
                  `${value.first_name} ${value.last_name}`
                }
                values={
                  usersList &&
                    usersList.filter(
                      (user) => user.role.name === 'neosylva_cmf'
                    )
                }
                itemMapper={(user) => ({
                  key: user.username,
                  label: `${user.first_name} ${user.last_name}`,
                })}
              />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
          <Grid item xs={12} sm={6} md={2}>
            <SwitchField
              control={control}
              name='relation_contractuelle_neosylva'
              label='Relation contractuelle avec Néosylva'
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
          <Grid item xs={6} sm={3} md={2}>
            <SwitchField
              control={control}
              name='newsletter'
              label='Newsletter'
            />
          </Grid>
          <Grid item xs={6} sm={5} md={2}>
            <SwitchField
              control={control}
              name='rendez_vous_annuel_pressenti'
              label='Rendez-vous annuel pressenti'
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <SelectField
              control={control}
              name='voeux'
              label='Vœux'
              renderValue={(value) => value.nom}
              baseId='voeux'
              values={choices?.voeux_types}
              itemMapper={(voeux) => ({
                key: voeux.id,
                label: voeux.nom,
              })}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
          <Grid item xs={12} sm={8} md={4}>
            <MultilineTextField
              control={control}
              name='commentaire'
              label='Commentaire'
            />
          </Grid>
        </Grid>
      </form>
    </TabPanelForm>
  );
}

RelationNeosylvaTab.propTypes = {
  form: PropTypes.object.isRequired,
  tabValue: PropTypes.number.isRequired,
  choices: PropTypes.object,
  isProp: PropTypes.bool,
  createPropriete: PropTypes.bool.isRequired,
};