import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import GestionnaireProForm from '@/features/item-form/GestionnaireProForm';

import Container from '@mui/material/Container';

export default function GestionnairePro() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const emptyItem = {
    contact: {
      civlite: null,
      nom: null,
      prenom: null,
      adresse: null,
      commune: null,
      code_postal: null,
      departement: null,
      pays: null,
      email: null,
      telephone_fixe: null,
      telephone_portable: null,
      profession: null,
      date_naissance: null,
      newsletter: false,
      rendez_vous_annuel_pressenti: false,
      voeux: null,
      commentaire: null,
      contact_neosylva: null,
      relation_contractuelle_neosylva: false,
      entreprises_list: [],
    },
  };

  const item = useItem(itemId, 'gestionnaires_pro', emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <GestionnaireProForm item={item} />}
    </Container>
  );
}
