import React from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import useChoices from '@/hooks/useChoices';

import SelectField from '@/components/controlled-fields/SelectField';
import MultilineTextField from '@/components/controlled-fields/text-fields/MultilineTextField';
import YearTextField from '@/components/controlled-fields/text-fields/YearTextField';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

export default function InterventionFormDialog({
  editItem,
  open,
  setOpen,
  onSubmit,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const choices = useChoices('interventions');

  const InterventionForm = ({ item }) => {
    const {
      handleSubmit,
      control,
      formState: { isDirty, isValid },
    } = useForm({
      defaultValues: item
        ? item
        : {
          type: null,
          annee_prevue: null,
          commentaire: null,
        },
      reValidateMode: 'onBlur',
      mode: 'onChange',
    });

    return (
      <form>
        <DialogTitle>{item ? 'Éditer' : 'Créer'} une intervention</DialogTitle>
        <DialogContent>
          <Grid container sx={{ p: 2 }} spacing={2} alignItems='center'>
            <Grid item xs={12} md={6}>
              <SelectField
                control={control}
                name='type'
                required='Type requis.'
                label='Type'
                values={choices?.types}
                itemMapper={(type) => ({
                  key: type.id,
                  label: `${type.code} ${type.nom}`,
                })}
                renderValue={(value) => value.nom}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <YearTextField
                control={control}
                name='annee_prevue'
                required='Année prévue requise'
                label='Année prévue'
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MultilineTextField
                control={control}
                name='commentaire'
                label='Commentaire'
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            disabled={!isDirty || !isValid}
            onClick={handleSubmit(onSubmit)}
          >
            {item ? 'Éditer' : 'Créer'}
          </Button>
        </DialogActions>
      </form>
    );
  };

  InterventionForm.propTypes = {
    item: PropTypes.object,
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
      <InterventionForm item={editItem} />
    </Dialog>
  );
}

InterventionFormDialog.propTypes = {
  editItem: PropTypes.object,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
