import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { clearMessage } from '@/slices/messageSlice';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';

export default function ToastMessage() {
  const dispatch = useDispatch();

  const message = useSelector((store) => store.messageReducer.message);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    message && setOpen(true);
  }, [message]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    dispatch(clearMessage());
  };

  const action = (
    <IconButton
      size='small'
      aria-label='close'
      color='inherit'
      onClick={handleClose}
    >
      <CloseIcon fontSize='small' />
    </IconButton>
  );

  return (
    <Snackbar
      sx={{ mt: 6 }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      action={action}
      message={message}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    />
  );
}
