import React from 'react';

import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import useChoices from '@/hooks/useChoices';
import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useItems from '@/hooks/useItems';

import CSVExportButton from '@/components/CSVExportButton';
import CustomDataGrid from '@/components/CustomDataGrid';
import ManyItemsCell from '@/components/ManyItemsCell';

import { deleteItem } from '@/slices/itemSlice';

import { fromISODate, fromISODateTime } from '@/utils/date';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';

export default function EssencesParcelles() {
  const { open } = useConfirmationDialog();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const itemType = 'peuplements_objectifs_essences';

  const choices = useChoices(itemType);

  const items = useItems(itemType);

  const handleEditItem = (itemId) => {
    navigate(`/essence-parcelle/${itemId}`);
  };

  const handleDeleteItem = async (itemId, essence) => {
    const isConfirmed = await open({
      title: 'Suppression',
      content: `Confirmer la suppression de l'essence ${essence} ?`,
    });

    if (isConfirmed) {
      dispatch(deleteItem({ itemId, itemType }));
    }
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<EditIcon />}
          label='Éditer'
          onClick={() => handleEditItem(params.id)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<DeleteIcon />}
          label='Supprimer'
          onClick={() =>
            handleDeleteItem(params.id, params.row.essence.nom_vernaculaire)
          }
        />,
      ],
    },
    {
      field: 'type',
      headerName: 'Type',
      type: 'singleSelect',
      valueOptions: ['Principale', 'Secondaire'],
      width: 220,
      editable: false,
      valueGetter: (value) => value,
    },
    {
      field: 'essence',
      headerName: 'Essence',
      type: 'singleSelect',
      valueOptions: choices?.essences.map((value) => value.nom_vernaculaire),
      width: 220,
      editable: false,
      valueGetter: (value) => value.nom_vernaculaire,
    },
    {
      field: 'proportion',
      headerName: 'Proportion (%)',
      width: 160,
      editable: false,
      valueFormatter: (value) => (value ? value.toFixed(2) : null),
    },
    {
      field: 'unite_gestion',
      headerName: 'Unité de gestion',
      width: 260,
      valueGetter: (value) => value.nom, // For filtering ...
      renderCell: ({ row }) => (
        <Link
          component={RouterLink}
          to={`/unite-gestion/${row.unite_gestion.id}`}
        >
          {row.unite_gestion.nom}
        </Link>
      ),
    },
    {
      field: 'propriete',
      headerName: 'Propriété',
      width: 260,
      valueGetter: (_, row) => row.unite_gestion.propriete.nom_commun, // For filtering ...
      renderCell: ({ row }) => (
        <Link
          component={RouterLink}
          to={`/propriete/${row.unite_gestion.propriete.id}`}
        >
          {row.unite_gestion.propriete.nom_commun}
        </Link>
      ),
    },
    {
      field: 'constats_reprises_essences_list',
      headerName: "Reprises d'essences",
      width: 260,
      valueGetter: (value) =>
        value?.map(
          (reprise) =>
            `${fromISODate(reprise.date_reprise)} - ${reprise.taux_reprise}`
        ),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.constats_reprises_essences_list
              ? row.constats_reprises_essences_list
              : [],
            getRelatedName: (reprise) =>
              `${fromISODate(reprise.date_reprise)} - ${reprise.taux_reprise}`,
            relatedVerboseName: 'reprise',
            relatedPath: '/constat-reprise-essence',
          }}
        />
      ),
    },
    {
      field: 'programmes_rd_list',
      headerName: 'Programmes R&D',
      width: 260,
      valueGetter: (value) =>
        value?.map((programme) => programme.nom),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.programmes_rd_list
              ? row.programmes_rd_list
              : [],
            getRelatedName: (programme) => programme.nom,
            relatedVerboseName: 'programme',
            relatedPath: '/programme-rd',
          }}
        />
      ),
    },
    {
      field: 'creation_datetime',
      headerName: 'Création',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
    {
      field: 'last_modification_datetime',
      headerName: 'Dernière modification',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
  ];

  const handleFormRedirect = () => {
    navigate('/essence-parcelle');
  };

  return (
    <Box component='main' mx={3} mt={2}>
      <Typography mb={1} variant='h5' component='div'>
        Essences de parcelles
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm='auto'>
          <Button variant='contained' onClick={handleFormRedirect} fullWidth>
            Ajouter une essence de parcelle
          </Button>
        </Grid>
        <Grid item xs={12} sm='auto'>
          <CSVExportButton itemType={itemType} fullWidth />
        </Grid>
      </Grid>
      <CustomDataGrid columns={columns} rows={items} />
    </Box>
  );
}
