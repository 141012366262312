import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import UserForm from '@/features/item-form/UserForm';

import Container from '@mui/material/Container';

export default function User() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const emptyItem = {
    username: null,
    email: null,
    password: null,
    role: null,
    first_name: null,
    last_name: null,
    phone: null,
    birthday_date: null,
    is_admin: null,
    gestionnaire: null,
  };

  const item = useItem(itemId, 'users', emptyItem);

  return (
    <>
      <Container component='main' maxWidth={false} sx={{ my: 2 }}>
        {item && <UserForm item={item} />}
      </Container>
    </>
  );
}
