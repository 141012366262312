import { useEffect, useRef } from 'react';

export default function useOnUnmount(callback) {
  const onUnmount = useRef(null);
  onUnmount.current = callback;

  useEffect(() => {
    return () => onUnmount.current?.();
  }, []);
}
