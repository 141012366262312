import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import ContratEtfForm from '@/features/item-form/ContratEtfForm';

import Container from '@mui/material/Container';

export default function ContratEtf() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const emptyItem = {
    code: null,
    responsable_etf: null,
    interventions_list: [],
  };

  const item = useItem(itemId, 'contrats_etf', emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <ContratEtfForm item={item} />}
    </Container>
  );
}
