import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import useDebounce from '@/hooks/useDebounce';

import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

export default function AutocompleteAPI({
  label,
  searchFunc,
  getItemDescription,
  onChange,
  error,
  value,
  defaultValue,
  multiple = false,
  disabled = false,
  required = false,
}) {
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);

  // Debounce search term
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  // Effect for API call
  useEffect(() => {
    async function fetchData() {
      const { data } = await searchFunc(debouncedSearchTerm);
      if (data) {
        setResults(
          data.filter((d) =>
            multiple
              ? !value.map((v) => v.id).includes(d.id)
              : d.id !== value?.id
          )
        );
      }
      setIsSearching(false);
    }

    if (debouncedSearchTerm) {
      setIsSearching(true);
      fetchData();
    } else {
      setResults([]);
      setIsSearching(false);
    }
  }, [debouncedSearchTerm, value]);

  return (
    <Autocomplete
      multiple={multiple}
      renderTags={multiple ? (tagValue, getTagProps) =>
        tagValue.map((option, index) => <Chip {...getTagProps({ index })} key={getItemDescription(option)} label={getItemDescription(option)} />)
        : null
      }
      freeSolo
      disableClearable
      options={results}
      getOptionLabel={getItemDescription}
      onChange={(_, item) => {
        onChange(item);
        setResults([]);
      }}
      defaultValue={defaultValue}
      onInputChange={(event) => {
        if (!event) {
          return;
        }
        const inputValue = event.target.value;
        setSearchTerm(inputValue);

        if (!inputValue) {
          onChange(null);
        }
      }}
      disabled={disabled}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {getItemDescription(option)}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          fullWidth
          required={
            multiple ? (required ? value.length === 0 : false) : required
          }
          inputProps={{
            ...params.inputProps,
            role: 'input',
            value:
              params.inputProps.value === '' && value && !multiple
                ? ''
                : params.inputProps.value,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isSearching && <CircularProgress size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          error={!!error}
          helperText={error ? error.message : null}
        />
      )}
    />
  );
}

AutocompleteAPI.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  searchFunc: PropTypes.func.isRequired,
  getItemDescription: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};
