import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import SelectField from '@/components/controlled-fields/SelectField';

export default function DepartementField({
  label,
  departements,
  control,
  departementFieldName,
  codePostalFieldName,
  setValue,
  required,
  ...props
}) {

  const codePostal = useWatch({
    control,
    name: codePostalFieldName,
  });
  

  useEffect(() => {
    if (codePostal?.length !== 5) {
      return;
    }

    let deptCode = codePostal.substring(0, 2);
    if (deptCode === '97') {
      deptCode = codePostal.substring(0, 3);
    }

    const departement = departements?.find((d) => d.startsWith(deptCode));

    setValue(departementFieldName, departement);
  }, [codePostal]);

  return (
    <SelectField
      name={departementFieldName}
      label={label}
      control={control}
      baseId='dept-select'
      values={departements}
      required={required}
      itemMapper={(item) => ({
        key: item,
        label: item,
      })}
      {...props}
    />
  );
}

DepartementField.propTypes = {
  label: PropTypes.string.isRequired,
  departements: PropTypes.array,
  control: PropTypes.object.isRequired,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  departementFieldName: PropTypes.string.isRequired,
  codePostalFieldName: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};
