import React from 'react';

import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

export default function NewProprieteButton() {

  const navigate = useNavigate();
  
  return (
    <Grid container>
      <Button 
        onClick={() => navigate('/fiche-propriete')}
        sx={{ mt: 3, mb: 1 }}
        fullWidth={false}
        variant='contained'
      >
        Créer une nouvelle propriété
      </Button>
    </Grid>
  );
}