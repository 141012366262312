import React from 'react';

import NouveauContactForm from '@/features/project-form/nouveau-contact/NouveauContactForm';

import Container from '@mui/material/Container';

export default function NouveauContact() {
  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      <NouveauContactForm />
    </Container>
  );
}
