import React from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';

import SubmitHandlerButton from '@/features/SubmitHandlerButton';

import { passwordReset } from '@/slices/authSlice';
import { setIsLoading } from '@/slices/isLoadingSlice';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function PasswordReset() {
  const {
    handleSubmit,
    control,
    getValues,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: { password: '', password_verif: '' },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  const dispatch = useDispatch();

  const params = useParams();

  const navigate = useNavigate();

  const onSubmit = (passwordResetData) => {
    dispatch(
      passwordReset({
        token: params.token,
        password: passwordResetData.password,
      })
    )
      .then(({ payload }) => {
        if (payload) {
          navigate('/');
        }
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };

  const onCancel = () => {
    navigate('/');
  };

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 2,
            textAlign: 'center',
          }}
        >
          <Typography mb={2} variant='h5' component='div'>
            Réinitialisation du mot de passe
          </Typography>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <BaseTextField 
                  name='password'
                  control={control}
                  label='Mot de passe'
                  required='Mot de passe requis.'
                  minLength={{
                    value: 8,
                    message: 'Le mot de passe doit avoir au moins 8 caractères.',
                  }}
                  type='password'
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <BaseTextField 
                  name='password_verif'
                  control={control}
                  label='Confirmation du mot de passe'
                  required='Confirmation du mot de passe requise.'
                  validate={(value) => value === getValues('password') || 'Les mots de passe de correspondent pas.'}
                  type='password'
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 3, mb: 1 }}>
              <Button variant='outlined' sx={{ mr: 1 }} onClick={onCancel}>
                Annuler
              </Button>
              <SubmitHandlerButton
                onSubmit={handleSubmit(onSubmit)}
                label='Réinitialiser'
                disabledSubmit={!isDirty || !isValid}
                variant='contained'
                fullWidth
              />
            </Box>
          </form>
        </Paper>
      </Box>
    </Container>
  );
}
