import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import ProprietaireForm from '@/features/item-form/ProprietaireForm';

import Container from '@mui/material/Container';

export default function Proprietaire() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const emptyItem = {
    contact: {
      civlite: null,
      nom: null,
      prenom: null,
      adresse: null,
      commune: null,
      code_postal: null,
      departement: null,
      pays: null,
      email: null,
      telephone_fixe: null,
      telephone_portable: null,
      profession: null,
      date_naissance: null,
      newsletter: false,
      rendez_vous_annuel_pressenti: false,
      voeux: null,
      commentaire: null,
      contact_neosylva: null,
      relation_contractuelle_neosylva: false,
      entreprises_list: [],
    },
    origine_commerciale: null,
    personnalite_juridique: null,
    raison_sociale: null,
    code_postal_siege: null,
    commune_siege: null,
    siret: null,
    proprietes_list: [],
  };

  const item = useItem(itemId, 'proprietaires', emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <ProprietaireForm item={item} />}
    </Container>
  );
}
