import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import RecetteForm from '@/features/item-form/RecetteForm';

import Container from '@mui/material/Container';

export default function Recette() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const emptyItem = {
    date_previsionnelle_paiement: null,
    montant_previsionnel: null,
    montant_reel: null,
    date_paiement: null,
    intervention: null,
  };

  const item = useItem(itemId, 'recettes', emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <RecetteForm item={item} />}
    </Container>
  );
}
