import React from 'react';

import PropTypes from 'prop-types';

import AutoCompleteSearchField from '@/features/AutoCompleteSearchField';

export default function ProprietaireAutocompleteField({
  proprietaire,
  setProprietaire,
  required,
  customFilter,
  disabled,
}) {
  return (
    <AutoCompleteSearchField
      label='Propriétaire'
      required={required && 'Propriétaire requis.'}
      itemState={[proprietaire, setProprietaire]}
      getOptionLabel={(item) => {
        let description = item.contact?.nom;
        if (item?.contact?.prenom) {
          description = `${item.contact.prenom} ${description}`;
        }
        return description || item || '';
      }}
      itemType='proprietaires'
      customFilter={customFilter}
      disabled={disabled}
    />
  );
}

ProprietaireAutocompleteField.propTypes = {
  proprietaire: PropTypes.object,
  setProprietaire: PropTypes.func.isRequired,
  required: PropTypes.bool,
  customFilter: PropTypes.func,
  disabled: PropTypes.bool,
};
