import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const ITEM_HEIGHT = 48;

export default function ManyItemsCell({ itemId, relatedItems, openNewTab }) {
  const {
    relatedItemsList,
    getRelatedName,
    relatedPath,
    getRelatedPath,
    relatedVerboseName,
    onClick,
  } = relatedItems;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    if (relatedItemsList.length > 0) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id={`positioned-button-${itemId}`}
        aria-controls={open ? `positioned-menu-${itemId}` : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {relatedItemsList.length}{' '}
        {relatedItemsList.length > 1
          ? `${relatedVerboseName}s`
          : relatedVerboseName}
      </Button>
      <Menu
        id={`positioned-menu-${itemId}`}
        aria-labelledby={`positioned-button-${itemId}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
            },
          },
        }}
      >
        {relatedItemsList.map((relatedItem, idx) => {
          let path;
          if (getRelatedPath) {
            path = getRelatedPath(relatedItem);
          } else {
            path = relatedPath ? `${relatedPath}/${relatedItem.id}` : '';
          }

          return (
            <MenuItem
              key={idx}
              onClick={() => {
                onClick && onClick(relatedItem);
                handleClose();
              }}
              component={path && RouterLink}
              to={path}
              {...(openNewTab && { target: '_blank' })}
            >
              {getRelatedName(relatedItem)}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

ManyItemsCell.propTypes = {
  itemId: PropTypes.number.isRequired,
  relatedItems: PropTypes.shape({
    relatedItemsList: PropTypes.array.isRequired,
    getRelatedName: PropTypes.func.isRequired,
    relatedPath: PropTypes.string,
    getRelatedPath: PropTypes.func,
    relatedVerboseName: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  }),
  openNewTab: PropTypes.bool,
};
