import React, { useRef } from 'react';

import PropTypes from 'prop-types';
import { Controller, useWatch } from 'react-hook-form';
import { v4 as uuid4 } from 'uuid';

import { createFileFromUrl } from '@/utils/file';

import { FileUploadOutlined } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

const UPLOAD_SIZE_LIMIT_MO = 50;
const ALLOWED_FILE_EXTENSIONS = [
  'docx',
  'doc',
  'xlsx',
  'xls',
  'text',
  'txt',
  'pdf',
  'ods',
  'odt',
  'heif',
  'heic',
  'bmp',
  'png',
  'jpeg',
  'jpg',
  'jfif',
  'pjp',
];

/**
 * Permet l'upload d'un fichier (limité à 50 Mo), et greffe les paramètres `local_url`, `filename` et `temp_uuid` au formulaire parent.
 *
 * `local_url`: URL locale du blob
 * `filename`: Nom du fichier uploadé, avec son extension
 * `temp_uuid`: UUID unique
 * @component
 * @param {object} control - control du formulaire
 * @param {function} setValue - setValue du formulaire
 * @param {Boolean} isRequired - `True` si l'upload est obligatoire pour valider le formulaire
 */
export default function DocumentUploadField({
  control,
  setValue,
  isRequired,
  disabled,
}) {
  const filenameWatch = useWatch({ control, name: 'filename' });
  const fileExtensionRef = useRef(null);
  const inputFileRef = useRef(null);

  return (
    <Controller
      name={'local_url'}
      control={control}
      rules={{
        validate: async (value) => {
          if (
            fileExtensionRef.current &&
            !ALLOWED_FILE_EXTENSIONS.includes(fileExtensionRef.current)
          ) {
            return "Ce type de fichier n'est pas autorisé.";
          }
          const blob = await createFileFromUrl(value, 'temp_name');
          if (blob.size > UPLOAD_SIZE_LIMIT_MO * 1024 * 1024) {
            return `Le fichier est trop volumineux (> ${UPLOAD_SIZE_LIMIT_MO} Mo).`;
          }
          return true;
        },
        required: !filenameWatch && isRequired,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={!filenameWatch ? `Ajouter un fichier (max. ${UPLOAD_SIZE_LIMIT_MO} Mo)` : ''}
            value={filenameWatch || ''}
            onClick={() => !disabled && inputFileRef.current?.click()}
            inputProps={{
              readOnly: true,
              style: { cursor: disabled ? 'auto' : 'pointer' },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='start'>
                  <FileUploadOutlined />
                </InputAdornment>
              ),
              style: { cursor: disabled ? 'auto' : 'pointer' },
            }}
            variant='standard'
            InputLabelProps={{
              shrink: false,
            }}
            error={!!error}
            helperText={error ? error.message : null}
            required={!filenameWatch && isRequired}
            disabled={disabled}
          />
          <input
            type='file'
            hidden
            ref={inputFileRef}
            accept={ALLOWED_FILE_EXTENSIONS.join(', .')}
            onChange={(event) => {
              const file = event.target.files?.[0];
              if (file) {
                fileExtensionRef.current = file.name.split('.').at(-1);
                value && URL.revokeObjectURL(value);
                setValue('filename', file.name, {
                  shouldValidate: true,
                });
                setValue('temp_uuid', uuid4());
              }
              onChange(file ? URL.createObjectURL(file) : value);
            }}
          />
        </Grid>
      )}
    />
  );
}

DocumentUploadField.propTypes = {
  control: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
};
