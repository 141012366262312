import React from 'react';

import NumberTextField from './NumberTextField';

export default function AreaTextField({ ...props }) {
  return (
    <NumberTextField
      {...props}
      decimal={4}
      pattern={{
        value: /^\d+([.,]\d{0,4})?$/,
        message: 'Surface invalide.',
      }}
    />
  );
}
