import React from 'react';

import PropTypes from 'prop-types';

import SwitchField from '@/components/controlled-fields/SwitchField';
import MultilineTextField from '@/components/controlled-fields/text-fields/MultilineTextField';
import TabPanelForm from '@/components/TabPanelForm';

import DocumentTable from '@/features/project-form/document/DocumentTable';

import Grid from '@mui/material/Grid';

export default function ProjetNeosylvaTab({
  tabValue,
  propriete,
  documentsList,
  contratProprietaire,
  form,
}) {
  const { control } = form;
  return (
    <TabPanelForm currentValue={tabValue} index={6}>
      <form>
        {propriete && (
          <>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={12} sm={12} md={8}>
                <MultilineTextField
                  control={control}
                  name='contrat_proprietaire.itineraires_sylvicoles_pressentis'
                  label='État actuel et itinéraire(s) sylvicole(s) pressenti(s)'
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
              <Grid item xs={12} sm={12} md={8}>
                <MultilineTextField
                  control={control}
                  name='contrat_proprietaire.points_vigilance'
                  label='Éléments pouvant conditionner la faisabilité du projet'
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
              <Grid item xs={12} sm={6} md={4}>
                <SwitchField
                  control={control}
                  name='contrat_proprietaire.eligibilite_presumee_lbc'
                  label='Éligibilité présumée au LBC'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <SwitchField
                  control={control}
                  name='contrat_proprietaire.eligibilite_presumee_aide_publique'
                  label='Éligibilité présumée à une aide publique'
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems='center' sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <DocumentTable items={documentsList} title={'Documents'} contratProprietaire={contratProprietaire} disableFileEdit />
              </Grid>
            </Grid>
          </>
        )}
      </form>
    </TabPanelForm>
  );
}

ProjetNeosylvaTab.propTypes = {
  tabValue: PropTypes.number.isRequired,
  propriete: PropTypes.object,
  documentsList: PropTypes.array,
  contratProprietaire: PropTypes.object,
  form: PropTypes.object.isRequired,
};
