import React from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import useChoices from '@/hooks/useChoices';

import SelectField from '@/components/controlled-fields/SelectField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import NumberTextField from '@/components/controlled-fields/text-fields/NumberTextField';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

export default function EspecePlanChasseFormDialog({
  editItem,
  open,
  setOpen,
  onSubmit,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const choices = useChoices('especes_plan_chasse');

  const EspecePlanChasseForm = ({ item }) => {
    const {
      handleSubmit,
      control,
      formState: { isDirty, isValid },
    } = useForm({
      defaultValues: item
        ? item
        : {
          espece: null,
          nb_bracelet: null,
          evolution: null,
        },
      reValidateMode: 'onBlur',
      mode: 'onChange',
    });

    return (
      <form>
        <DialogTitle>
          {item ? 'Éditer' : 'Créer'} une espèce de plan de chasse
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ p: 2 }} spacing={2} alignItems='center'>
            <Grid item xs={12} sm={6}>
              <BaseTextField
                control={control}
                name='espece'
                maxLength={256}
                required='Espèce requise.'
                label='Espèce'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumberTextField
                control={control}
                name='nb_bracelet'
                pattern={{
                  value: /^\d+$/,
                  message: 'Nombre de bracelets invalide.',
                }}
                label='Nombre de bracelets'
                decimal={0}
                type={'nb_bracelet'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectField
                control={control}
                name='evolution'
                label='Évolution'
                values={choices?.evolutions_types}
                renderValue={(value) => value.nom}
                itemMapper={(evolution_type) => ({
                  key: evolution_type.id,
                  label: evolution_type.nom,
                })}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            disabled={!isDirty || !isValid}
            onClick={handleSubmit(onSubmit)}
          >
            {item ? 'Éditer' : 'Créer'}
          </Button>
        </DialogActions>
      </form>
    );
  };

  EspecePlanChasseForm.propTypes = {
    item: PropTypes.object,
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
      <EspecePlanChasseForm item={editItem} />
    </Dialog>
  );
}

EspecePlanChasseFormDialog.propTypes = {
  editItem: PropTypes.object,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
