import React from 'react';

import BaseTextField from './BaseTextField';

export default function UpperCaseTextField({ ...props }) {
  return (
    <BaseTextField
      onBlur={(e, onChange) => onChange(e.target.value.toUpperCase())}
      {...props}
    />
  );
}
