import React from 'react';

import DiagnosticGestionnaireForm from '@/features/project-form/diagnostic-gestionnaire/DiagnosticGestionnaireForm';

import Container from '@mui/material/Container';

export default function DiagnosticGestionnaire() {
  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      <DiagnosticGestionnaireForm />
    </Container>
  );
}
