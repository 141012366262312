import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import TabPanelForm from '@/components/TabPanelForm';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

export default function EnjeuxEconomiquesTab({ form, tabValue, choices }) {
  const { control } = form;
  return (
    <TabPanelForm currentValue={tabValue} index={2}>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'acces_massif'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel id='acces-massif-select-label'>
                    Accès massif
                  </InputLabel>
                  <Select
                    labelId='acces-massif-select-label'
                    id='acces-massif-select'
                    onChange={onChange}
                    value={value || ''}
                    label='Accès massif'
                    error={!!error}
                    renderValue={(value) => value.nom}
                  >
                    {choices &&
                      choices.acces_massif_types.map((acces) => (
                        <MenuItem key={acces.id} value={acces}>
                          {acces.nom}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'acces_grumier'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel id='acces-grumier-select-label'>
                    Accès grumiers
                  </InputLabel>
                  <Select
                    labelId='acces-grumier-select-label'
                    id='acces-grumier-select'
                    onChange={onChange}
                    value={value || ''}
                    label='Accès grumiers'
                    error={!!error}
                    renderValue={(value) => value.nom}
                  >
                    {choices &&
                      choices.acces_grumier_types.map((acces) => (
                        <MenuItem key={acces.id} value={acces}>
                          {acces.nom}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'nb_place_depot'}
              control={control}
              rules={{
                pattern: {
                  value: /^\d+$/,
                  message: 'Nombre de places de dépôt invalide.',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Nombre de places de dépôt'}
                  type={'number'}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  onBlur={(e) => {
                    let val = parseFloat(e.target.value);
                    if (val) {
                      val = val.toFixed(0);
                    }
                    onChange(val);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name={'besoin_acces_supplementaire'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Accessibilité et équipement de la forêt'}
                  fullWidth
                  error={!!error}
                  multiline
                  minRows={3}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name={'historique_gestion'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Historique de gestion sur la propriété'}
                  fullWidth
                  error={!!error}
                  multiline
                  minRows={3}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name={'description_filiere_bois'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Description de la filière bois'}
                  fullWidth
                  error={!!error}
                  multiline
                  minRows={3}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name={'charges'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Charges'}
                  fullWidth
                  error={!!error}
                  multiline
                  minRows={3}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name={'ressources'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Ressources'}
                  fullWidth
                  error={!!error}
                  multiline
                  minRows={3}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name={'equipement_defense_incendie'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Équipements pour la défense contre les incendies'}
                  fullWidth
                  error={!!error}
                  multiline
                  minRows={3}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name={'enjeux_economiques_gestion_forestiere'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={
                    'Prise en compte des enjeux économiques de la gestion forestière'
                  }
                  fullWidth
                  error={!!error}
                  multiline
                  minRows={3}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </TabPanelForm>
  );
}

EnjeuxEconomiquesTab.propTypes = {
  form: PropTypes.object.isRequired,
  tabValue: PropTypes.number.isRequired,
  choices: PropTypes.object,
};
