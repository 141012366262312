import { useDispatch } from 'react-redux';

import { setIsLoading } from '@/slices/isLoadingSlice';

export default function useMultipleConfirmationDialog(...dialogs) {
  const dispatch = useDispatch();

  const handleDialogs = async () => {
    for (const dialog of dialogs) {
      const isConfirmed = await dialog();
      if (!isConfirmed) {
        dispatch(setIsLoading(false));
        return false;
      }
    }

    dispatch(setIsLoading(false));
    return true;
  };

  return handleDialogs;
}
