import React from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';

import FormSubmitButtons from '@/features/FormSubmitButtons';

import { passwordResetDemand } from '@/slices/authSlice';
import { setIsLoading } from '@/slices/isLoadingSlice';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function PasswordResetDemand() {
  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: { email: '' },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const onSubmit = (passwordResetData) => {
    dispatch(passwordResetDemand(passwordResetData))
      .then(({ payload }) => {
        if (payload) {
          navigate('/');
        }
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };

  const onCancel = () => {
    navigate('/');
  };

  const emailRegex = /^(([^<>()\\[\]\\.,;:\s@'"]+(\.[^<>()\\[\]\\.,;:\s@'"]+)*)|("'.+"'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 2,
            textAlign: 'center',
          }}
        >
          <Typography mb={2} variant='h5' component='div'>
            Réinitialisation du mot de passe
          </Typography>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <BaseTextField 
                  name='email'
                  control={control}
                  label='E-mail'
                  required='E-mail requis.'
                  pattern={{
                    value: emailRegex,
                    message: 'Adresse e-mail invalide.',
                  }}

                />
              </Grid>
            </Grid>
            <Box>
              <FormSubmitButtons
                onSubmit={handleSubmit(onSubmit)}
                submitLabel='Envoyer'
                disabledSubmit={!isDirty || !isValid}
                onCancel={onCancel}
              />
            </Box>
          </form>
        </Paper>
      </Box>
    </Container>
  );
}
