import store from './store';

import axios from 'axios';
import { buildWebStorage, setupCache } from 'axios-cache-interceptor';

import { setMessage } from '@/slices/messageSlice';

import { removeUser } from './slices/authSlice';

const CACHE_PREFIX = `axios-cache-${process.env.REACT_APP_STORAGE_VERSION}:`;
class APIService {
  constructor() {
    this.errorMessage = 'Une erreur inattendue est survenue.';
    const instance = axios.create({
      baseURL: process.env.REACT_APP_NEOSYLVA_API_URL,
    });
    this.axios = setupCache(instance, {
      storage: buildWebStorage(localStorage, CACHE_PREFIX),
    });
  }

  authToken() {
    return store.getState().persistAuthReducer.user?.access_token;
  }

  authHeader() {
    return { Authorization: `Bearer ${this.authToken()}` };
  }

  handleError(withCredentials, response, onError) {
    if (!response) {
      store.dispatch(setMessage(this.errorMessage));
      return;
    }
    if (withCredentials && response.status === 401) {
      this.clearCache();
      history.replaceState({ ...history.state, reload: true }, '');
      store.dispatch(removeUser());
    }
    if (onError) {
      onError(response);
    }
  }

  clearCache(itemType = null) {
    if (itemType) {
      localStorage.removeItem(`${CACHE_PREFIX}${itemType}/choices`);
    } else {
      for (const key of Object.keys(localStorage)) {
        key.startsWith(CACHE_PREFIX) && localStorage.removeItem(key);
      }
    }
  }

  async get({ withCredentials = true, url, opts, onError, cache = false }) {
    return this.axios
      .get(url, {
        ...opts,
        headers: withCredentials && this.authHeader(),
        cache,
      })
      .catch(({ response }) => {
        this.handleError(withCredentials, response, onError);
      });
  }

  async delete({ withCredentials = true, url, onError }) {
    return this.axios
      .delete(url, { headers: withCredentials && this.authHeader() })
      .catch(({ response }) => {
        this.handleError(withCredentials, response, onError);
      });
  }

  async post({ withCredentials = true, url, opts, data, onError }) {
    return this.axios
      .post(url, data, {
        ...opts,
        headers: withCredentials && this.authHeader(),
      })
      .catch(({ response }) => {
        this.handleError(withCredentials, response, onError);
      });
  }

  async patch({ withCredentials = true, url, data, onError }) {
    return this.axios
      .patch(url, data, { headers: withCredentials && this.authHeader() })
      .catch(({ response }) => {
        this.handleError(withCredentials, response, onError);
      });
  }
}

export default new APIService();
