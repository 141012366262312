import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import ClearIcon from '@mui/icons-material/Clear';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export default function SelectField({
  control,
  name,
  required,
  label,
  values,
  itemMapper,
  baseId,
  disabled,
  renderValue = (value) => value,
  onClear,
}) {
  baseId = baseId ? baseId : name;
  const idSelect = `${baseId}-select`;
  const idLabel = `${idSelect}-label`;

  const getValueFromChoices = (selectedValue) => {
    if (!selectedValue || !values) {
      return '';
    }

    if (typeof selectedValue === 'object') {
      return values.find(
        (item) => itemMapper(item).key === itemMapper(selectedValue).key
      );
    }

    return values.find((item) => item === selectedValue);
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: required,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl
          fullWidth
          required={typeof required === 'string' ? Boolean(required) : required}
        >
          <InputLabel id={idLabel}>{label}</InputLabel>
          <Select
            inputProps={{
              role: 'input',
            }}
            id={idSelect}
            labelId={idLabel}
            onChange={onChange}
            value={getValueFromChoices(value)}
            label={label}
            error={!!error}
            renderValue={renderValue}
            disabled={disabled}
            endAdornment={
              onClear && !disabled ? (
                <IconButton
                  sx={{
                    visibility: value ? 'visible' : 'hidden',
                  }}
                  onClick={onClear}
                >
                  <ClearIcon />
                </IconButton>
              ) : null
            }
          >
            {values &&
              values.map((item) => {
                const mappedItem = itemMapper(item);
                return (
                  <MenuItem key={mappedItem.key} value={item}>
                    {mappedItem.label}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      )}
    />
  );
}

SelectField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  itemMapper: PropTypes.func.isRequired,
  values: PropTypes.array,
  renderValue: PropTypes.func,
  baseId: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.bool,
  onClear: PropTypes.func,
};
