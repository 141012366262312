import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import ContactForm from '@/features/item-form/ContactForm';

import Container from '@mui/material/Container';

export default function Contact() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const emptyItem = {
    civlite: null,
    nom: null,
    prenom: null,
    adresse: null,
    commune: null,
    code_postal: null,
    departement: null,
    pays: null,
    email: null,
    telephone_fixe: null,
    telephone_portable: null,
    profession: null,
    date_naissance: null,
    newsletter: null,
    rendez_vous_annuel_pressenti: null,
    voeux: null,
    commentaire: null,
    contact_neosylva: null,
    activites_list: [],
    relation_contractuelle_neosylva: false,
    entreprises_list: [],
  };

  const item = useItem(itemId, 'contacts', emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <ContactForm item={item} />}
    </Container>
  );
}
