import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';

import { passwordChange } from '@/slices/authSlice';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

export default function ChangePasswordFormDialog({ open, setOpen, userId }) {
  const handleClose = () => {
    setOpen(false);
  };

  const {
    handleSubmit,
    control,
    getValues,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: { password: null, password_verif: null },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(
      passwordChange({
        userId,
        password: data.password,
      })
    ).then(({ payload }) => {
      if (payload) {
        setOpen(false);
      }
    });
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const [showPasswordVerif, setShowPasswordVerif] = useState(false);

  const handleClickShowPasswordVerif = () =>
    setShowPasswordVerif(!showPasswordVerif);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
      <form>
        <DialogTitle>Changement de mot de passe</DialogTitle>
        <DialogContent>
          <Grid container sx={{ p: 2 }} spacing={2} alignItems='center'>
            <Grid item xs={12}>
              <BaseTextField
                name='password'
                label='Nouveau mot de passe'
                control={control}
                required='Nouveau mot de passe requis.'
                minLength={{
                  value: 8,
                  message: 'Le mot de passe doit avoir au moins 8 caractères.',
                }}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <BaseTextField
                name='password_verif'
                label='Confirmation du mot de passe'
                control={control}
                required='Confirmation du mot de passe requise.'
                validate={(value) =>
                  value === getValues('password') ||
                  'Les mots de passe ne correspondent pas.'
                }
                type={showPasswordVerif ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={handleClickShowPasswordVerif}>
                        {showPasswordVerif ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            disabled={!isDirty || !isValid}
            onClick={handleSubmit(onSubmit)}
          >
            Sauvegarder
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

ChangePasswordFormDialog.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
};
