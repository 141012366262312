import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import CellExpand from '@/components/CellExpand';

import TableForm from '@/features/project-form/TableForm';

import IndivisaireFormDialog from './IndivisaireFormDialog';

export default function IndivisaireTable({ items, control, disabledEdit }) {
  const columns = [
    {
      field: 'nom',
      headerName: 'Nom',
      width: 150,
      editable: false,
      renderCell: ({ row }) => (
        <CellExpand value={row.proprietaire.contact.nom} />
      ),
      valueGetter: (_, row) => row.proprietaire.contact.nom,
    },
    {
      field: 'prenom',
      headerName: 'Prénom',
      width: 150,
      editable: false,
      valueGetter: (_, row) => row.proprietaire.contact.prenom,
    },
    {
      field: 'nature_indivisaire',
      headerName: 'Nature indivisaire',
      width: 150,
      editable: false,
      valueGetter: (_, row) => row.nature_indivisaire?.nom,
    },
    {
      field: 'adresse',
      headerName: 'Adresse',
      width: 210,
      editable: false,
      valueGetter: (_, row) => row.proprietaire.contact.adresse,
    },
    {
      field: 'code_postal',
      headerName: 'Code postal',
      width: 140,
      editable: false,
      valueGetter: (_, row) => row.proprietaire.contact.code_postal,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 170,
      editable: false,
      valueGetter: (_, row) => row.proprietaire.contact.email,
    },
    {
      field: 'telephone_fixe',
      headerName: 'Téléphone fixe',
      width: 150,
      editable: false,
      valueGetter: (_, row) => row.proprietaire.contact.telephone_fixe,
    },
    {
      field: 'telephone_portable',
      headerName: 'Téléphone portable',
      width: 150,
      editable: false,
      valueGetter: (_, row) =>
        row.proprietaire.contact.telephone_portable,
    },
  ];

  const [preparedItems, setPreparedItems] = useState([]);

  const proprietaireWatch = useWatch({
    control,
    name: 'proprietaire',
  });

  const natureIndivisaireWatch = useWatch({
    control,
    name: 'proprietaire.indivisaire.nature_indivisaire',
  });

  useEffect(() => {
    setPreparedItems(
      items?.map((item) => {
        if (item.principale) {
          item = {
            proprietaire: {
              contact: { ...proprietaireWatch.contact },
            },
            nature_indivisaire: natureIndivisaireWatch,
            principale: true,
            disabledEdit: true,
          };
        }
        return item;
      })
    );
  }, [items, proprietaireWatch, natureIndivisaireWatch]);

  return (
    <TableForm
      itemsList={preparedItems}
      columns={columns}
      itemType={'indivisaires'}
      tableTitle={'Indivisaires'}
      addBtnTxt={'Ajouter un indivisaire'}
      ModalForm={IndivisaireFormDialog}
      disabledEdit={disabledEdit}
      modalProps={{
        othersIndivisairesContactsIdList: items?.map(
          (indivisaire) => indivisaire.proprietaire.contact.id
        ),
      }}
    />
  );
}

IndivisaireTable.propTypes = {
  items: PropTypes.array,
  proprietaireId: PropTypes.number,
  control: PropTypes.object.isRequired,
  disabledEdit: PropTypes.bool,
};
