
export const createFileFromUrl = async (url, name) => {
  const file = await fetch(url)
    .then((r) => r.blob())
    .then((blob) => new File([blob], name));
  return file;
};

export const downloadFromUrl = (url, fileName) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
