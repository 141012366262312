import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import StationForm from '@/features/item-form/StationForm';

import Container from '@mui/material/Container';

export default function Station() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const emptyItem = {
    numero: null,
    code: null,
    facteurs_limitants: null,
    essences_principales_adaptees: [],
    essences_secondaires_adaptees: [],
    exposition: null,
    topographie: null,
  };

  const item = useItem(itemId, 'stations', emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <StationForm item={item} />}
    </Container>
  );
}
