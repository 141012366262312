import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import TabPanelForm from '@/components/TabPanelForm';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';

export default function GestionTab({
  form,
  tabValue,
  choices,
}) {
  const { control } = form;

  return (
    <TabPanelForm currentValue={tabValue} index={1}>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'objectifs_gestion_list'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel id='gestion-select-label'>
                  Objectifs gestion
                  </InputLabel>
                  <Select
                    labelId='gestion-select-label'
                    id='gestion-select'
                    onChange={onChange}
                    value={value || []}
                    label='Objectifs gestion'
                    error={!!error}
                    renderValue={(selected) => (
                      <Box
                        sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                      >
                        {selected.map((value, idx) => (
                          <Chip key={idx} label={value} />
                        ))}
                      </Box>
                    )}
                    multiple
                  >
                    {choices &&
                    choices.objectifs_gestion_types.map((objectif, idx) => (
                      <MenuItem key={idx} value={objectif}>
                        <Checkbox
                          checked={value && value.indexOf(objectif) > -1}
                        />
                        <ListItemText primary={objectif} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name={'historique_gestion'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Historique gestion'}
                  fullWidth
                  error={!!error}
                  multiline
                  minRows={3}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              name={'certification_forestiere'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label='Certification forestiere'
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </TabPanelForm>
  );
}

GestionTab.propTypes = {
  form: PropTypes.object.isRequired,
  tabValue: PropTypes.number.isRequired,
  choices: PropTypes.object,
};