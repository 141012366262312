import React, { useEffect, useState } from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';
import { Controller, useForm, useWatch } from 'react-hook-form';

import useDebounce from '@/hooks/useDebounce';

import AreaTextField from '@/components/controlled-fields/text-fields/AreaTextField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';

import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

const API_ADRESSE_URL = 'https://api-adresse.data.gouv.fr/search/';

async function getCommunes(search) {
  return axios.get(API_ADRESSE_URL, {
    params: { q: search, limit: 25, type: 'municipality' },
  });
}

async function getParcelles(params) {
  if (params.prefixe === '') {
    params.prefixe = null;
  }

  return axios.get(`${process.env.REACT_APP_CADASTRE_API_URL}/parcelles/idu`, {
    params,
    headers: {
      'dfoncier-api-key': process.env.REACT_APP_CADASTRE_API_KEY,
    },
  });
}

function AutocompleteCommune({
  control,
  setValue,
  clearParcelle,
  defaultValue,
}) {
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    async function searchAdresse() {
      const { data } = await getCommunes(debouncedSearchTerm);

      if (!data) {
        return;
      }

      setResults(data.features);

      setIsSearching(false);
    }

    if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
      setIsSearching(true);
      searchAdresse(debouncedSearchTerm);
    } else {
      setResults([]);
      setIsSearching(false);
    }
  }, [debouncedSearchTerm]);

  return (
    <Controller
      name={'nom_commune'}
      control={control}
      rules={{ required: 'Nom commune requis.' }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          loading={isSearching}
          defaultValue={defaultValue}
          filterOptions={(options) => options}
          onChange={(_, feature) => {
            const featProps = feature?.properties;
            onChange(featProps?.name);
            setValue('code_commune', featProps?.citycode);

            setResults([]);

            clearParcelle();
          }}
          onInputChange={(event) => {
            if (!event) {
              return;
            }
            setSearchTerm(event.target.value);
          }}
          getOptionLabel={(option) =>
            typeof option === 'object' ? option.properties.name : option
          }
          renderOption={(props, option) => (
            <li {...props} key={option.properties.id}>
              {option.properties.name} ({option.properties.citycode})
            </li>
          )}
          options={results}
          renderInput={(params) => (
            <TextField
              {...params}
              label={'Nom commune'}
              fullWidth
              required={true}
              value={value ? value : ''}
              error={!!error}
              helperText={error?.message}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isSearching && <CircularProgress size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
}

AutocompleteCommune.propTypes = {
  control: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  clearParcelle: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
};

export default function ParcelleCadastralePressentieSousContratFormDialog({
  editItem,
  open,
  setOpen,
  onSubmit,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const ParcelleCadastralePressentieSousContratForm = ({ item }) => {
    const {
      handleSubmit,
      control,
      setValue,
      formState: { isDirty, isValid },
    } = useForm({
      defaultValues: item,
      reValidateMode: 'onBlur',
      mode: 'onChange',
    });

    const [parcelles, setParcelles] = useState(null);

    const [selectedParcelle, setSelectedParcelle] = useState(null);

    const codeCommune = useWatch({
      control,
      name: 'code_commune',
    });

    const prefixe = useWatch({
      control,
      name: 'prefixe',
    });

    const section = useWatch({
      control,
      name: 'section',
    });

    const numero = useWatch({
      control,
      name: 'numero',
    });

    useEffect(() => {
      async function searchParcelles(params) {
        const { data } = await getParcelles(params);

        if (!data || !data.features) {
          return;
        }

        const features = data.features;

        setParcelles(features);

        if (features.length === 1) {
          const [firstFeature] = features;
          setSelectedParcelle(firstFeature);
          onSelectedParcelleChange(firstFeature);
        }
      }

      if (section && numero) {
        setSelectedParcelle(null);
        searchParcelles({ insee: codeCommune, prefixe, section, numero });
      }
    }, [prefixe, section, numero]);

    const getParcelleName = ({ properties }) =>
      `${properties.prefixe}-${properties.section}-${properties.numero}`;

    const clearParcelle = () => {
      setValue('prefixe', null);
      setValue('section', null);
      setValue('numero', null);
      setValue('contenance', null);
      setParcelles(null);
      setSelectedParcelle(null);
    };

    const onSelectedParcelleChange = (feature) => {
      const featProps = feature?.properties;
      let contenance = featProps?.contenance;
      if (contenance) {
        contenance = contenance / 10000;
      }
      setValue('contenance', contenance, {
        shouldValidate: true,
      });

      if (!prefixe && featProps?.prefixe) {
        setValue('prefixe', featProps.prefixe, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }

      if (!featProps) {
        clearParcelle();
      }
    };

    return (
      <form>
        <DialogTitle>
          {item ? 'Éditer' : 'Créer'} une parcelle cadastrale
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ p: 2 }} spacing={2} alignItems='center'>
            <Grid item xs={12} sm={8}>
              <AutocompleteCommune
                control={control}
                setValue={setValue}
                clearParcelle={clearParcelle}
                defaultValue={item?.nom_commune}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <BaseTextField
                name='code_commune'
                control={control}
                maxLength={5}
                required='Code commune requis.'
                label='Code commune'
                disabled
              />
            </Grid>
          </Grid>
          <Grid container sx={{ p: 2 }} spacing={2} alignItems='center'>
            <Grid item xs={6} sm={4}>
              <BaseTextField
                name='prefixe'
                control={control}
                maxLength={3}
                label='Préfixe'
                disabled={!codeCommune}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <BaseTextField
                name='section'
                control={control}
                maxLength={2}
                label='Section'
                required='Section requise.'
                disabled={!codeCommune}
              />
            </Grid>
            <Grid item xs={5} sm={4}>
              <BaseTextField
                name='numero'
                control={control}
                maxLength={4}
                label='Numéro'
                required='Numéro requis.'
                disabled={!codeCommune}
              />
            </Grid>
            <Grid item xs={7}>
              <Autocomplete
                loading={!parcelles}
                disabled={!parcelles}
                value={selectedParcelle}
                onChange={(_, feature) => {
                  setSelectedParcelle(feature);
                  onSelectedParcelleChange(feature);
                }}
                getOptionLabel={getParcelleName}
                renderOption={(props, option) => (
                  <li {...props} key={option.properties.id}>
                    {getParcelleName(option)}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label={'Parcelle'} fullWidth />
                )}
                options={parcelles ? parcelles : []}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <AreaTextField
                name='contenance'
                control={control}
                label='Surface cadastrale (ha)'
                disabled={!selectedParcelle}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            disabled={!isDirty || !isValid || !selectedParcelle}
            onClick={handleSubmit(onSubmit)}
          >
            {item ? 'Éditer' : 'Créer'}
          </Button>
        </DialogActions>
      </form>
    );
  };

  ParcelleCadastralePressentieSousContratForm.propTypes = {
    item: PropTypes.object,
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <ParcelleCadastralePressentieSousContratForm item={editItem} />
    </Dialog>
  );
}

ParcelleCadastralePressentieSousContratFormDialog.propTypes = {
  editItem: PropTypes.object,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
