import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import ConstatRepriseEssenceForm from '@/features/item-form/ConstatRepriseEssenceForm';

import Container from '@mui/material/Container';

export default function ConstatRepriseEssence() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const itemType = 'constats_reprises_essences';

  const emptyItem = {
    taux_reprise: null,
    date_reprise: null,
    essence_parcelle: null,
    observations_reprise: null,
    hauteur_moyenne: null,
  };

  const item = useItem(itemId, itemType, emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <ConstatRepriseEssenceForm item={item} />}
    </Container>
  );
}
