import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import useIsNeosylva from '@/hooks/useIsNeosylva';

import FolderSharedIcon from '@mui/icons-material/FolderShared';
import ForestIcon from '@mui/icons-material/Forest';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleIcon from '@mui/icons-material/People';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';

import ListItemLink from '../components/ListItemLink';

export default function LeftDrawer() {
  const isAdmin = useSelector((store) => store.persistAuthReducer.user?.is_admin);
  const isCmf = useSelector((store) => store.persistAuthReducer?.user?.role === 'neosylva_cmf');

  const isNeosylva = useIsNeosylva();

  const [open, setOpen] = useState(false);

  const toggle = ({ key, type }) => {
    if (type === 'keydown' && (key === 'Tab' || key === 'Shift')) {
      return;
    }
    setOpen(!open);
  };

  return (
    <>
      <Drawer anchor='left' open={open} onClose={toggle}>
        <Box
          sx={{ width: 250 }}
          role='presentation'
          onClick={toggle}
          onKeyDown={toggle}
        >
          <List>
            <ListItemLink to='/' primary='Accueil' icon={<HomeIcon />} />
            {isAdmin && (
              <>
                <Divider />
                <ListSubheader component='div'>Administration</ListSubheader>
                <ListItemLink
                  to='/users'
                  primary='Utilisateurs'
                  icon={<PeopleIcon />}
                />
                <ListItemLink
                  to='/departements-cmf'
                  primary='Départements CMF'
                  icon={<FolderSharedIcon />}
                />
                <ListItemLink
                  to='/essences'
                  primary='Essences'
                  icon={<ForestIcon />}
                />
              </>
            )}
            <Divider />
            <ListSubheader component='div'>Formulaires</ListSubheader>
            {isNeosylva && (
              <>
                <ListItemLink
                  to='/creer-diagnostic-neosylva'
                  primary='Créer un nouveau diagnostic Néosylva'
                />
                <ListItemLink
                  to='/modifier-diagnostic-neosylva'
                  primary='Modifier un diagnostic Néosylva'
                />
              </>
            )}
            <ListItemLink
              to='/diagnostic-gestionnaire'
              primary='Diagnostic gestionnaire'
            />
            {isNeosylva && (
              <>
                <Divider />
                <ListSubheader component='div'>Gestion des contacts</ListSubheader>
                <ListItemLink to='/contacts' primary='Base de données contact' />
                <ListItemLink to='/nouveau-contact' primary='Créer un nouveau contact' />
              </>
            )}
            {isNeosylva && !isCmf && (
              <>
                <Divider />
                <ListSubheader component='div'>Gestion des contrats</ListSubheader>
                <ListItemLink to='/tableau-bord-cmf' primary='Tableau de bord CMF' />
                <ListItemLink to='' primary='Tableau de bord Gestionnaire' />
                <ListItemLink to='' primary='Tableau de bord ETF' />
                <ListItemLink to='' primary='Tableau de bord Propriétaire' />
              </>
            )}
            {isNeosylva && (
              <>
                <Divider />
                <ListSubheader component='div'>Tableaux</ListSubheader>
                {isAdmin && (
                  <ListItemLink
                    to='/rendez-vous-annuels'
                    primary='Rendez-vous annuels'
                  />
                )}
                <ListItemLink to='/entreprises' primary='Entreprises' />
                {isAdmin && (
                  <ListItemLink to='/proprietes' primary='Propriétés'>
                    <ListItemLink
                      to='/unites-gestion'
                      primary='Unités de gestion'
                    >
                      <ListItemLink to='/interventions' primary='Interventions'>
                        <ListItemLink to='/recettes' primary='Recettes' />
                        <ListItemLink to='/depenses' primary='Dépenses' />
                      </ListItemLink>
                      <ListItemLink
                        to='/services-env'
                        primary='Services environnementaux'
                      />
                      <ListItemLink
                        to='/stocks-carbone'
                        primary='Stocks carbone'
                      />
                      <ListItemLink
                        to='/peuplements-initiaux'
                        primary='Peuplements initiaux'
                      />
                      <ListItemLink
                        to='/essences-parcelles'
                        primary='Essences parcelles'
                      >
                        <ListItemLink
                          to='/constats-reprises-essences'
                          primary="Constats de reprises d'essences"
                        />
                      </ListItemLink>
                    </ListItemLink>
                    <ListItemLink
                      to='/parcelles-cadastrales'
                      primary='Parcelles cadastrales'
                    />
                    <ListItemLink
                      to='/especes-plan-chasse'
                      primary='Espèces plan de chasse'
                    />
                    <ListItemLink
                      to='/certifications'
                      primary='Certifications'
                    />
                    <ListItemLink
                      to='/elements-biodiversite'
                      primary='Éléments de biodiversité'
                    />
                    <ListItemLink to='/stations' primary='Stations' />
                  </ListItemLink>
                )}
                {isAdmin && (
                  <ListItemLink to='/documents-gestion-durable' primary='DGD'>
                    <ListItemLink
                      to='/interventions-passees'
                      primary='Interventions passées'
                    />
                  </ListItemLink>
                )}
                <ListItemLink to='/proprietaires' primary='Propriétaires' />
                {isAdmin && (
                  <>
                    <ListItemLink
                      to='/contrats-proprietaires'
                      primary='Contrats propriétaires'
                    />
                    <ListItemLink
                      to='/contrats-financeur'
                      primary='Contrats financeur'
                    />
                    <ListItemLink
                      to='/financeurs-carbone'
                      primary='Financeurs carbone'
                    />
                    <ListItemLink
                      to='/responsables-etf'
                      primary='Responsables ETF'
                    />
                    <ListItemLink to='/contrats-etf' primary='Contrats ETF' />
                  </>
                )}
                <ListItemLink
                  to='/gestionnaires-pro'
                  primary='Gestionnaires professionnels'
                />
                {isAdmin && (
                  <ListItemLink to='/programmes-rd' primary='Programmes R&D' />
                )}
              </>
            )}
          </List>
        </Box>
      </Drawer>
      <IconButton
        size='large'
        edge='start'
        color='inherit'
        aria-label='menu'
        sx={{ mr: 2 }}
        onClick={toggle}
      >
        <MenuIcon />
      </IconButton>
    </>
  );
}
