import React from 'react';

import PropTypes from 'prop-types';

import EssenceFormDialog from './EssenceFormDialog';

export default function EssenceSecondaireFormDialog(props) {
  const essenceProps = { ...props };

  if (essenceProps.editItem?.proportion) {
    essenceProps.totalProportion -= essenceProps.editItem?.proportion;
  }
  return <EssenceFormDialog {...essenceProps} type={'Secondaire'} />;
}

EssenceSecondaireFormDialog.propTypes = {
  editItem: PropTypes.object,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  totalProportion: PropTypes.number,
};
