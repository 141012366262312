import { useMemo } from 'react';

import { useSelector } from 'react-redux';

export default function useIsNeosylva() {
  const currentItem = useSelector((store) => store.persistAuthReducer.user);

  return useMemo(
    () =>
      currentItem?.is_admin ||
      currentItem?.role === 'neosylva' ||
      currentItem?.role === 'neosylva_cmf',
    [currentItem]
  );
}
