import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import useItems from '@/hooks/useItems';

import CheckboxSelectField from '@/components/controlled-fields/CheckboxSelectField';
import SwitchField from '@/components/controlled-fields/SwitchField';
import AutoCompleteTextField from '@/components/controlled-fields/text-fields/AutoCompleteTextField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import MultilineTextField from '@/components/controlled-fields/text-fields/MultilineTextField';
import DividerTitle from '@/components/DividerTitle';
import TabPanelForm from '@/components/TabPanelForm';

import CertificationForestiereTable from '@/features/project-form/certification-forestiere/CertificationForestiereTable';
import DgdTable from '@/features/project-form/dgd/DgdTable';

import Grid from '@mui/material/Grid';

export default function GestionnaireCertificationSyndicatsTab({
  tabValue,
  form,
  dgdLists,
  certificationsForestiereList,
  choices,
}) {

  const { control } = form;
  const gestionnaires = useItems('gestionnaires_pro');

  const adhesionSyndicatWatch = useWatch({
    control,
    name: 'propriete.adhesion_syndicat'
  });

  return (
    <TabPanelForm currentValue={tabValue} index={1}>
      <form>
        <Grid container sx={{ mt: 1 }} spacing={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <SwitchField
                control={control}
                name='propriete.gestionnaire_preexistant'
                label='Existence préalable d’un gestionnaire'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={7}>
              <AutoCompleteTextField
                control={control}
                name='propriete.gestionnaire'
                label='Gestionnaire forestier'
                options={gestionnaires}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(gestionnaire) => {
                  if (!gestionnaire) {
                    return '';
                  }
                  let gestionnaireDescription = gestionnaire.contact.nom;
                  if (gestionnaire.contact.prenom) {
                    gestionnaireDescription += ` ${gestionnaire.contact.prenom}`;
                  }
                  if (gestionnaire.contact.departement) {
                    gestionnaireDescription += ` (${gestionnaire.contact.departement})`;
                  }
                  return gestionnaireDescription;
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={7}>
              <CheckboxSelectField
                control={control}
                name='propriete.objectifs_gestion_list'
                label='Objectifs gestion'
                values={choices?.objectifs_gestion_types}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={7}>
              <MultilineTextField
                control={control}
                name='propriete.historique_gestion'
                label='Historique de gestion'
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2} direction='row'>
            <Grid item xs={12} sm={6} md={3}>
              <SwitchField
                control={control}
                name='propriete.adhesion_syndicat'
                label='Adhésion syndicat propriétaire privé'
              />
            </Grid>
            {adhesionSyndicatWatch && (
              <Grid item xs={12} sm={6} md={3}>
                <BaseTextField
                  control={control}
                  name='propriete.adhesion_syndicat_detail'
                  label='Syndicat propriétaire privé'
                />
              </Grid>
            )}
          </Grid>
          <Grid container item spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} >
              <DividerTitle text='Document de gestion durable' />
            </Grid>
            <Grid item xs={12}>
              <SwitchField
                control={control}
                name='propriete.existence_dgd'
                label='Existence DGD'
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <DgdTable
                dgdLists={dgdLists}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <DividerTitle text='Certification' />
            </Grid>
            <Grid item xs={12}>
              <SwitchField
                control={control}
                name='propriete.certification_forestiere'
                label='Certification forestière'
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <CertificationForestiereTable
                certificationsList={certificationsForestiereList}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </TabPanelForm>
  );
}

GestionnaireCertificationSyndicatsTab.propTypes = {
  tabValue: PropTypes.number,
  form: PropTypes.object.isRequired,
  dgdLists: PropTypes.array,
  certificationsForestiereList: PropTypes.array,
  choices: PropTypes.object
};