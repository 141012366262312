import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import useFetchFilteredItems from '@/hooks/useFetchFilteredItems';
import useIsDifferentAddress from '@/hooks/useIsDifferentAddress';
import useItems from '@/hooks/useItems';
import useOnMount from '@/hooks/useOnMount';
import useSetFormValues from '@/hooks/useSetFormValues';

import DatePickerField from '@/components/controlled-fields/DatePickerField';
import SelectField from '@/components/controlled-fields/SelectField';
import SwitchField from '@/components/controlled-fields/SwitchField';
import AreaTextField from '@/components/controlled-fields/text-fields/AreaTextField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import CodePostalTextField from '@/components/controlled-fields/text-fields/CodePostalTextField';
import TabPanelForm from '@/components/TabPanelForm';

import AdresseField from '@/features/AdresseField';
import DepartementField from '@/features/DepartementField';
import ParcelleCadastralePressentieSousContratTable from '@/features/project-form/parcelle-cadastrale-pressentie-sous-contrat/ParcelleCadastralePressentieSousContratTable';
import ProprieteAutocompleteField from '@/features/project-form/ProprieteAutocompleteField';

import Grid from '@mui/material/Grid';

export default function RenseignementProprieteTab({
  tabValue,
  proprietaire,
  proprieteState,
  parcellesList,
  contratProprietaire,
  proprieteFields,
  differentAddress,
  form,
  choices,
}) {

  const usersList = useItems('users');
  const { propriete, setPropriete } = proprieteState;
  const { control, setValue, trigger, register } = form;

  const fetchFilteredItems = useFetchFilteredItems();

  const proprietesList = useSelector(
    (store) => store.itemReducer.filteredItems.proprietes
  );

  useEffect(() => {
    trigger();
  }, [differentAddress]);

  const setFormValues = useSetFormValues();

  useEffect(() => {
    if (!propriete) {
      return;
    }

    fetchFilteredItems({
      filter: { propriete_id: propriete.id },
      itemTypes: ['parcelles_cadastrales_pressenties_sous_contrat'],
    });

    setFormValues(proprieteFields, propriete, 'propriete');
  }, [propriete]);

  const addressesFields = [
    [proprietaire?.contact?.adresse, propriete?.adresse],
    [proprietaire?.contact?.code_postal, propriete?.code_postal],
    [proprietaire?.contact?.commune, propriete?.commune],
  ];

  useIsDifferentAddress(
    addressesFields,
    [setValue, 'different_address_propriete'],
    propriete && proprietaire,
  );

  useOnMount(() => {
    register('propriete.cmf_neosylva', { required: true });
  });

  return (
    <TabPanelForm currentValue={tabValue} index={1}>
      {(proprietaire || contratProprietaire) && (
        <form>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={6} md={3}>
              <ProprieteAutocompleteField
                propriete={propriete}
                setPropriete={setPropriete}
                proprietesList={proprietesList}
                required
              />
            </Grid>
            {propriete && (
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <BaseTextField
                    control={control}
                    name={'propriete.nom_commun'}
                    maxLength={256}
                    required='Nom de la propriété requis.'
                    label='Nom de la propriété'
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <SwitchField
                    control={control}
                    name='different_address_propriete'
                    label='Adresse de la propriété différente de l’adresse du propriétaire'
                  />
                </Grid>
              </>
            )}
          </Grid>
          {propriete && (
            <>
              {differentAddress && (
                <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
                  <Grid item xs={12} sm={7} md={5}>
                    <AdresseField
                      control={control}
                      fieldName={'propriete.adresse'}
                      relatedFiedlNames={{
                        commune: 'propriete.commune',
                        codePostal: 'propriete.code_postal',
                      }}
                      required={true}
                      label={'Adresse propriété'}
                      setValue={setValue}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <BaseTextField
                      control={control}
                      name='propriete.commune'
                      maxLength={256}
                      required='Commune propriété requise.'
                      label={'Commune propriété'}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <CodePostalTextField
                      control={control}
                      name='propriete.code_postal'
                      label='Code postal propriété'
                      required='Code postal propriété requis.'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <DepartementField
                      label={'Département propriété'}
                      departements={choices?.departements}
                      control={control}
                      departementFieldName={'propriete.departement'}
                      codePostalFieldName={'propriete.code_postal'}
                      setValue={setValue}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
                <Grid item xs={12} sm={6} md={3}>
                  <DatePickerField
                    control={control}
                    name='contrat_proprietaire.date_premiere_visite_neosylva'
                    label='Date première visite Néosylva'
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <AreaTextField
                    control={control}
                    name='contrat_proprietaire.surface_pressentie_sous_contrat'
                    label='Surface pressentie sous contrat (ha)'
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <AreaTextField
                    control={control}
                    name='propriete.surface_totale_propriete'
                    label='Surface totale propriété (ha)'
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <SelectField
                    control={control}
                    required='CMF Néosylva requis'
                    name='propriete.cmf_neosylva'
                    label='CMF Néosylva'
                    baseId='neosylva-cmf'
                    values={
                      usersList &&
                      usersList.filter(
                        (user) => user.role.name === 'neosylva_cmf'
                      )
                    }
                    itemMapper={(user) => ({
                      key: user.username,
                      label: `${user.first_name} ${user.last_name}`,
                    })}
                    renderValue={(user) => `${user.first_name} ${user.last_name}`}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
                <Grid item xs={10}>
                  <ParcelleCadastralePressentieSousContratTable
                    items={parcellesList}
                    title={'Parcelles cadastrales pressenties sous contrat'}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </form>
      )}
    </TabPanelForm>
  );
}

RenseignementProprieteTab.propTypes = {
  tabValue: PropTypes.number,
  proprietaire: PropTypes.object,
  proprieteState: PropTypes.object.isRequired,
  parcellesList: PropTypes.array,
  contratProprietaire: PropTypes.object,
  proprieteFields: PropTypes.array.isRequired,
  differentAddress: PropTypes.bool.isRequired,
  form: PropTypes.object,
  choices: PropTypes.object,
};
