import React from 'react';

import PropTypes from 'prop-types';

import CheckboxSelectField from '@/components/controlled-fields/CheckboxSelectField';
import SelectField from '@/components/controlled-fields/SelectField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import MultilineTextField from '@/components/controlled-fields/text-fields/MultilineTextField';
import NumberTextField from '@/components/controlled-fields/text-fields/NumberTextField';
import DividerTitle from '@/components/DividerTitle';
import TabPanelForm from '@/components/TabPanelForm';

import AccesOuvrageTable from '@/features/project-form/acces-ouvrage/AccesOuvrageTable';
import EspecePlanChasseTable from '@/features/project-form/espece-plan-chasse/EspecePlanChasseTable';

import Grid from '@mui/material/Grid';

export default function EnjeuxEconomiquesTab({
  form,
  tabValue,
  choices,
  propriete,
  accesOuvragesList,
  especesPlanChasseList,
}) {
  const { control } = form;
  return (
    <TabPanelForm currentValue={tabValue} index={1}>
      {propriete && (
        <form>
          <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
            <Grid item xs={12}>
              <DividerTitle text={'Equilibre forêt-gibier'} />
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
            <Grid container item spacing={2} alignItems='center'>
              <Grid item xs={12} md={5}>
                <Grid item xs={12}>
                  <SelectField
                    control={control}
                    name='propriete.degats_constates'
                    baseId='degats-constates'
                    label='Dégâts constatés'
                    renderValue={(value) => value.nom}
                    values={choices?.degats_constates_types}
                    itemMapper={(degat) => ({
                      key: degat.id,
                      label: degat.nom,
                    })}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <CheckboxSelectField
                    control={control}
                    name='propriete.natures_degats_list'
                    baseId='degat-nature'
                    label='Natures dégâts constatés'
                    values={choices?.natures_degats_types}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={7}>
                <MultilineTextField
                  control={control}
                  name='propriete.commentaire_chasse_pression_gibier'
                  label='Pression gibier constatée'
                />
              </Grid>
            </Grid>
            <Grid container item spacing={2} alignItems='center'>
              <Grid item xs={12} sm={6}>
                <EspecePlanChasseTable items={especesPlanChasseList} />
              </Grid>
              <Grid item xs={6}>
                <BaseTextField
                  control={control}
                  name='propriete.identite_detenteur_droit_chasse'
                  maxLength={256}
                  label='Identité du détenteur du droit de chasse'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
            <Grid item xs={12}>
              <DividerTitle text={'Accès et ouvrages de la propriété'} />
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
            <Grid container item spacing={2} alignItems='center'>
              <Grid item xs={12} md={5}>
                <Grid item xs={12}>
                  <SelectField
                    control={control}
                    name='propriete.acces_massif'
                    baseId='acces-massif'
                    label='Accès massif'
                    renderValue={(value) => value.nom}
                    values={choices?.acces_massif_types}
                    itemMapper={(acces) => ({
                      key: acces.id,
                      label: acces.nom,
                    })}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <SelectField
                    control={control}
                    name='propriete.acces_grumier'
                    baseId='acces-grumier'
                    label='Accès grumiers'
                    renderValue={(value) => value.nom}
                    values={choices?.acces_grumier_types}
                    itemMapper={(acces) => ({
                      key: acces.id,
                      label: acces.nom,
                    })}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <NumberTextField
                    control={control}
                    name='propriete.nb_place_depot'
                    label='Nombre de places de dépôt'
                    pattern={{
                      value: /^\d+$/,
                      message: 'Nombre de places de dépôt invalide.',
                    }}
                    decimal={0}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={7}>
                <MultilineTextField
                  control={control}
                  name='propriete.besoin_acces_supplementaire'
                  label='Accessibilité et équipement de la forêt'
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
              <AccesOuvrageTable items={accesOuvragesList} />
            </Grid>
          </Grid>
        </form>
      )}
    </TabPanelForm>
  );
}

EnjeuxEconomiquesTab.propTypes = {
  form: PropTypes.object.isRequired,
  tabValue: PropTypes.number.isRequired,
  choices: PropTypes.object,
  propriete: PropTypes.object,
  accesOuvragesList: PropTypes.array,
  especesPlanChasseList: PropTypes.array,
};
