import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Controller, useForm, useWatch } from 'react-hook-form';

import useChoices from '@/hooks/useChoices';
import useFormItem from '@/hooks/useFormItem';

import AutocompleteAPI from '@/components/AutoCompleteAPI';
import DatePickerField from '@/components/controlled-fields/DatePickerField';
import SelectField from '@/components/controlled-fields/SelectField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import MailTextField from '@/components/controlled-fields/text-fields/MailTextField';

import ChangePasswordFormDialog from '@/features/ChangePasswordFormDialog';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import APIService from '@/APIService';
import FormSubmitButtons from '../FormSubmitButtons';

export default function UserForm({ item }) {
  const itemType = 'users';

  const choices = useChoices(itemType);
  const itemPath = `/${itemType}`;

  const { onCancel, onSubmit } = useFormItem(item.id, itemType, itemPath);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid },
  } = useForm({
    defaultValues: item,
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  const [open, setOpen] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const gestionnaire = useWatch({
    control,
    name: 'gestionnaire',
  });

  useEffect(() => {
    if (!gestionnaire) {
      return;
    }

    setValue('last_name', gestionnaire.contact.nom, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue('first_name', gestionnaire.contact.prenom, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue('email', gestionnaire.contact.email, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue(
      'phone',
      gestionnaire.contact.telephone_portable ||
      gestionnaire.contact.telephone_fixe,
      {
        shouldValidate: true,
      }
    );
  }, [gestionnaire]);

  const role = useWatch({
    control,
    name: 'role',
  });

  return (
    <>
      <Box elevation={3} component={Paper} sx={{ width: '100%', p: 3 }}>
        <Typography mb={2} variant='h5' component='div'>
          {item.id ? 'Édition' : 'Création'} utilisateur
        </Typography>
        {item.id && (
          <Button
            variant='contained'
            sx={{ mb: 3 }}
            type='button'
            onClick={() => setOpen(true)}
          >
            Changer le mot de passe
          </Button>
        )}
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <SelectField
                control={control}
                name='role'
                required='Rôle requis'
                label='Rôle'
                renderValue={(value) => value.verbose_name}
                values={choices?.roles}
                itemMapper={(role) => ({
                  key: role.name,
                  label: role.verbose_name,
                })}
              />
            </Grid>
            {role?.name === 'gestionnaire' && (
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name={'gestionnaire'}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <AutocompleteAPI
                      onChange={onChange}
                      error={error}
                      value={value}
                      getItemDescription={(gestionnaire) => {
                        let gestionnaireDescription = gestionnaire.contact.nom;
                        if (gestionnaire.contact.prenom) {
                          gestionnaireDescription += ` ${gestionnaire.contact.prenom}`;
                        }
                        if (gestionnaire.contact.departement) {
                          gestionnaireDescription += ` (${gestionnaire.contact.departement})`;
                        }
                        return gestionnaireDescription;
                      }}
                      label={'Gestionnaire'}
                      searchFunc={(searchTerm) =>
                        APIService.get({
                          url: 'gestionnaires_pro/search/',
                          opts: { params: { name: searchTerm } },
                        })
                      }
                      defaultValue={item.gestionnaire}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={3}>
              <BaseTextField
                name='username'
                control={control}
                required='Identifiant requis'
                maxLength={120}
                label='Identifiant'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <BaseTextField
                control={control}
                name='last_name'
                required='Nom requis'
                maxLength={256}
                label={'Nom'}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <BaseTextField
                control={control}
                name='first_name'
                required='Prénom requis'
                maxLength={256}
                label='Prénom'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DatePickerField
                control={control}
                name='birthday_date'
                label='Date de naissance'
                disableFuture
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <MailTextField
                control={control}
                name='email'
                required='E-mail requis'
                label='E-mail'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <BaseTextField
                name={'phone'}
                control={control}
                maxLength={16}
                label='Téléphone'
              />
            </Grid>
            {!item.id && (
              <Grid item xs={12} sm={6} md={3}>
                <BaseTextField
                  name={'password'}
                  control={control}
                  required='Mot de passe requis'
                  maxLength={120}
                  minLength={{
                    value: 8,
                    message:
                      'Le mot de passe doit avoir au moins 8 caractères.',
                  }}
                  label='Mot de passe'
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={2}>
              <Controller
                name={'is_admin'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    }
                    label='Administrateur'
                  />
                )}
              />
            </Grid>
          </Grid>
          <FormSubmitButtons
            onSubmit={handleSubmit(onSubmit)}
            submitLabel={item.id ? 'Éditer' : 'Créer'}
            disabledSubmit={!isValid}
            onCancel={onCancel}
          />
        </form>
      </Box>
      {item.id && (
        <ChangePasswordFormDialog
          open={open}
          setOpen={setOpen}
          userId={item.id}
        />
      )}
    </>
  );
}

UserForm.propTypes = {
  item: PropTypes.object.isRequired,
};
