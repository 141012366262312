import React from 'react';

import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import SubmitHandlerButton from './SubmitHandlerButton';

export default function FormSubmitButtons({
  disabledSubmit,
  onSubmit,
  onCancel,
  submitLabel,
  ...submitProps
}) {
  
  return (
    <>
      <Button
        onClick={onCancel}
        sx={{ mt: 3, mb: 1 , mr: 1 }}
      >
        Annuler
      </Button>
      <SubmitHandlerButton
        disabledSubmit={disabledSubmit}
        onSubmit={onSubmit}
        label={submitLabel}
        sx={{ mt: 3, mb: 1 }}
        variant={'contained'}
        {...submitProps}
      />
    </>
  );
}

FormSubmitButtons.propTypes={
  disabledSubmit: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitLabel: PropTypes.string.isRequired,
};