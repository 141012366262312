import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import TabPanelForm from '@/components/TabPanelForm';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';

export default function EquilibreForetGibierTab({
  form,
  tabValue,
  choices,
}) {
  const { control } = form;
  return (
    <TabPanelForm currentValue={tabValue} index={5}>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              name={'acca'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label='ACCA'
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              name={'societe_chasse'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label='Société de chasse'
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              name={'reservataire'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label='Le propriétaire est réservataire (Alsace-Moselle)'
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              name={'plan_chasse'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label="Existence d'un plan de chasse"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'degats_constates'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel id='degats-constates-select-label'>
                    Dégâts constatés
                  </InputLabel>
                  <Select
                    labelId='degats-constates-select-label'
                    id='degats-constates-select'
                    onChange={onChange}
                    value={value || ''}
                    label='Dégâts constatés'
                    error={!!error}
                    renderValue={(value) => value.nom}
                  >
                    {choices &&
                      choices.degats_constates_types.map((acces) => (
                        <MenuItem key={acces.id} value={acces}>
                          {acces.nom}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'natures_degats_list'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel id='degat-nature-select-label'>
                    Nature dégâts constatés
                  </InputLabel>
                  <Select
                    labelId='degat-nature-select-label'
                    id='degat-nature-select'
                    onChange={onChange}
                    value={value || []}
                    label='Natures dégâts constatés'
                    error={!!error}
                    renderValue={(selected) => (
                      <Box
                        sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                      >
                        {selected.map((value, idx) => (
                          <Chip key={idx} label={value} />
                        ))}
                      </Box>
                    )}
                    multiple
                  >
                    {choices &&
                      choices.natures_degats_types.map((nature, idx) => (
                        <MenuItem key={idx} value={nature}>
                          <Checkbox
                            checked={value && value.indexOf(nature) > -1}
                          />
                          <ListItemText primary={nature} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name={'commentaire_chasse_pression_gibier'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Pression gibier constatée'}
                  fullWidth
                  error={!!error}
                  multiline
                  minRows={3}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'identite_detenteur_droit_chasse'}
              control={control}
              rules={{ maxLength: 256 }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Identité du détenteur du droit de chasse'}
                  inputProps={{ maxLength: 256 }}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </TabPanelForm>
  );
}

EquilibreForetGibierTab.propTypes = {
  form: PropTypes.object.isRequired,
  tabValue: PropTypes.number.isRequired,
  choices: PropTypes.object,
};