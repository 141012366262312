import React from 'react';

import PropTypes from 'prop-types';

import TableForm from '@/features/project-form/TableForm';

import ElementBiodiversiteFormDialog from './ElementBiodiversiteFormDialog';

export default function ElementBiodiversiteTable({ items }) {
  const columns = [
    {
      field: 'numero',
      headerName: 'Élément de biodiversité n°',
      width: 160,
      editable: false,
    },
    {
      field: 'nature',
      headerName: 'Nature',
      width: 220,
      editable: false,
      valueGetter: (value) => value?.nom,
    },
    {
      field: 'surface',
      headerName: 'Surface en ha / linéaire en ml',
      width: 180,
      editable: false,
      valueFormatter: (value) => {
        if (typeof value === 'string') {
          value = Number(value);
        }
        return value ? value.toFixed(4) : null;
      },
    },
    {
      field: 'preco_gestion',
      headerName: 'Préconisations de gestion',
      width: 220,
      editable: false,
    },
  ];

  return (
    <TableForm
      itemsList={items || []}
      columns={columns}
      itemType={'elements_biodiversite'}
      tableTitle={'Éléments de biodiversité'}
      addBtnTxt={'Ajouter un élément de biodiversité'}
      ModalForm={ElementBiodiversiteFormDialog}
    />
  );
}

ElementBiodiversiteTable.propTypes = {
  items: PropTypes.array,
};
