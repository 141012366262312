import React from 'react';

import PropTypes from 'prop-types';

import { Controller, useWatch } from 'react-hook-form';

import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';

import APIService from '@/APIService';

import useItems from '@/hooks/useItems';

import AutocompleteAPI from '@/components/AutoCompleteAPI';
import SwitchField from '@/components/controlled-fields/SwitchField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import TabPanelForm from '@/components/TabPanelForm';
import AdresseField from '@/features/AdresseField';

export default function InformationsGeneralesTab({
  form,
  tabValue,
  choices,
  item,
}) {
  const usersList = useItems('users');

  const { control, setValue, getValues } = form;

  const gestionnaires = useItems('gestionnaires_pro');

  const catalogueStation = useWatch({
    control,
    name: 'catalogue_station',
  });

  const watchAdhesionSyndicat = useWatch({ control, name: 'adhesion_syndicat' });

  return (
    <TabPanelForm currentValue={tabValue} index={0}>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'nom_commun'}
              control={control}
              rules={{ maxLength: 256, required: 'Nom commun requis.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Nom commun'}
                  inputProps={{ maxLength: 256 }}
                  fullWidth
                  required={true}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'proprietaires_list'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <AutocompleteAPI
                  getItemDescription={(item) => {
                    let description = item.contact.nom;
                    if (item.contact.prenom) {
                      description = `${item.contact.prenom} ${description}`;
                    }
                    return description;
                  }}
                  label={'Propriétaires'}
                  searchFunc={(searchTerm) =>
                    APIService.get({
                      url: 'proprietaires/search/',
                      opts: { params: { name: searchTerm } },
                    })
                  }
                  multiple={true}
                  onChange={onChange}
                  error={error}
                  value={value}
                  defaultValue={item.proprietaires_list}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={7} md={5}>
            <AdresseField
              control={control}
              fieldName={'adresse'}
              relatedFiedlNames={{
                commune: 'commune',
                codePostal: 'code_postal',
              }}
              label={'Adresse'}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'commune'}
              control={control}
              rules={{ maxLength: 256 }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Nom commune'}
                  inputProps={{ maxLength: 256 }}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              name={'code_postal'}
              control={control}
              rules={{
                maxLength: {
                  value: 5,
                  message: 'Le code postal doit avoir 5 caractères.',
                },
                minLength: {
                  value: 5,
                  message: 'Le code postal doit avoir 5 caractères.',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Code postal'}
                  inputProps={{
                    maxLength: 5,
                    pattern: '[0-9]*',
                  }}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'departement'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel id='dept-select-label'>Département</InputLabel>
                  <Select
                    labelId='dept-select-label'
                    id='dept-select'
                    value={value || ''}
                    label='Département'
                    onChange={onChange}
                    error={!!error}
                  >
                    {choices &&
                      choices.departements.map((val, idx) => (
                        <MenuItem key={idx} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'surface_totale_propriete'}
              control={control}
              rules={{
                pattern: {
                  value: /^\d+(\.\d{0,4})?$/,
                  message: 'Surface totale propriété invalide.',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Surface totale propriété (ha)'}
                  type={'number'}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  onBlur={(e) => {
                    let val = parseFloat(e.target.value);
                    if (val) {
                      val = val.toFixed(4);
                    }
                    onChange(val);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={5} md={2}>
            <SwitchField
              control={control}
              name='adhesion_syndicat'
              label='Adhésion syndicat propriétaire privé'
            />
          </Grid>
          {watchAdhesionSyndicat && (
            <Grid item xs={12} sm={7} md={3}>
              <BaseTextField
                control={control}
                name='adhesion_syndicat_detail'
                label='Syndicat propriétaire privé'
                maxLength={256}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              name={'existence_dgd'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label='Existence DGD'
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              name={'gestionnaire_preexistant'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label="Existence d'un gestionnaire"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'gestionnaire'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  loading={!gestionnaires}
                  disablePortal
                  onChange={(_, item) => onChange(item)}
                  getOptionLabel={(gestionnaire) => {
                    if (!gestionnaire) {
                      return '';
                    }
                    let gestionnaireDescription = gestionnaire.contact.nom;
                    if (gestionnaire.contact.prenom) {
                      gestionnaireDescription += ` ${gestionnaire.contact.prenom}`;
                    }
                    if (gestionnaire.contact.departement) {
                      gestionnaireDescription += ` (${gestionnaire.contact.departement})`;
                    }
                    return gestionnaireDescription;
                  }}
                  options={gestionnaires ? gestionnaires : []}
                  defaultValue={getValues('gestionnaire')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label='Gestionnaire'
                      error={!!error}
                      value={value ? value : ''}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Controller
              name={'cmf_neosylva'}
              control={control}
              rules={{
                required: 'CMF Néosylva requis.',
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth required={true}>
                  <InputLabel id='neosylva-cmf-select-label'>
                    CMF Néosylva
                  </InputLabel>
                  <Select
                    labelId='neosylva-cmf-select-label'
                    id='neosylva-cmf-select'
                    onChange={onChange}
                    value={value || ''}
                    label='CMF Néosylva'
                    error={!!error}
                    defaultValue={null}
                    renderValue={(value) =>
                      `${value.first_name} ${value.last_name}`
                    }
                  >
                    {usersList &&
                      usersList
                        .filter((user) => user.role.name === 'neosylva_cmf')
                        .map((user) => (
                          <MenuItem key={user.username} value={user}>
                            {user.first_name} {user.last_name}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name={'droits_usage_servitudes_divers'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={"Droits d'usage et servitudes diverses"}
                  fullWidth
                  error={!!error}
                  multiline
                  minRows={3}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              name={'catalogue_station'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label='Catalogue station'
                />
              )}
            />
          </Grid>
          {catalogueStation && (
            <Grid item xs={12} sm={12} md={4}>
              <Controller
                name={'catalogue_station_detail'}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    onChange={onChange}
                    value={value ? value : ''}
                    label={'Catalogue station référence'}
                    fullWidth
                    error={!!error}
                    multiline
                    minRows={3}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      </form>
    </TabPanelForm>
  );
}

InformationsGeneralesTab.propTypes = {
  form: PropTypes.object.isRequired,
  tabValue: PropTypes.number.isRequired,
  choices: PropTypes.object,
  item: PropTypes.object,
};
