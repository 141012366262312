import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import ServiceEnvForm from '@/features/item-form/ServiceEnvForm';

import Container from '@mui/material/Container';

export default function ServiceEnv() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const emptyItem = {
    type: null,
    recette: null,
    date_paiement: null,
    unite_gestion: null,
  };

  const item = useItem(itemId, 'services_env', emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <ServiceEnvForm item={item} />}
    </Container>
  );
}
