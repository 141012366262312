import React from 'react';

import { useParams } from 'react-router-dom';

import Container from '@mui/material/Container';

import useItem from '@/hooks/useItem';

import ProgrammeRdForm from '@/features/item-form/ProgrammeRdForm';

export default function ProgrammeRd() {
  const params = useParams();
  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const emptyItem = {
    nom: null,
  };

  const item = useItem(itemId, 'programmes_rd', emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <ProgrammeRdForm item={item} />}
    </Container>
  );
}
