import { jwtDecode } from 'jwt-decode';

import APIService from '@/APIService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setMessage } from './messageSlice';

export const passwordChange = createAsyncThunk(
  'auth/password_change',
  async ({ password, userId }, { rejectWithValue, dispatch }) => {
    const passwordChangeRes = await APIService.post({
      url: `auth/password_change/${userId}`,
      data: { password },
      onError: (res) => {
        const message = res.data.detail || res.data.message;
        if (message) {
          dispatch(setMessage(message));
        }
        rejectWithValue(res.data);
      },
    });

    const message = passwordChangeRes?.data?.message;
    if (message) {
      dispatch(setMessage(message));
    }

    return passwordChangeRes?.data;
  }
);

export const passwordReset = createAsyncThunk(
  'auth/password_reset',
  async ({ password, token }, { rejectWithValue, dispatch }) => {
    const passwordResetRes = await APIService.post({
      url: `auth/password_reset/${token}`,
      withCredentials: false,
      data: { password },
      onError: (res) => {
        const message = res.data.detail || res.data.message;
        if (message) {
          dispatch(setMessage(message));
        }
        rejectWithValue(res.data);
      },
    });

    const message = passwordResetRes?.data?.message;
    if (message) {
      dispatch(setMessage(message));
    }

    return passwordResetRes?.data;
  }
);

export const passwordResetDemand = createAsyncThunk(
  'auth/password_reset_demand',
  async ({ email }, { rejectWithValue, dispatch }) => {
    const passwordResetRes = await APIService.post({
      url: 'auth/password_reset_demand',
      data: { email },
      withCredentials: false,
      onError: (res) => {
        const message = res.data.detail || res.data.message;
        if (message) {
          dispatch(setMessage(message));
        }
        rejectWithValue(res.data);
      },
    });

    const message = passwordResetRes?.data?.message;
    if (message) {
      dispatch(setMessage(message));
    }

    return passwordResetRes?.data;
  }
);

export const loginUser = createAsyncThunk(
  'auth/login',
  async ({ username, password }, { rejectWithValue, dispatch }) => {
    const loginRes = await APIService.post({
      url: 'auth/login',
      withCredentials: false,
      data: { username, password },
      onError: (res) => {
        const message = res.data.detail || res.data.message;
        if (message) {
          dispatch(setMessage(message));
        }
        rejectWithValue(res.data);
      },
    });

    if (loginRes.data?.access_token) {
      return loginRes.data;
    }
  }
);

export const logoutUser = createAsyncThunk(
  'users/logout',
  async (message, { dispatch, rejectWithValue }) => {
    const logoutRes = await APIService.post({
      url: 'auth/logout',
      onError: (response) => rejectWithValue(response.data),
    });
    
    if (message) {
      dispatch(setMessage(message));
    }

    dispatch(removeUser());

    return logoutRes.data;
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: { isLoggedIn: false, user: null },
  reducers: {
    removeUser: (state) => {
      state.isLoggedIn = false;
      state.user = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      state.isLoggedIn = true;
      state.user = {
        token_expiration: jwtDecode(payload.access_token).exp * 1000,
        ...payload
      };
    });
    builder.addCase(loginUser.rejected, (state) => {
      state.isLoggedIn = false;
    });
  },
});

export const { removeUser } = authSlice.actions;

export default authSlice.reducer;
