import React from 'react';

import PropTypes from 'prop-types';

import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function Error({ resetErrorBoundary }) {
  return (
    <Container
      component='main'
      maxWidth={false}
      sx={{ my: 2, textAlign: 'center', width: 'auto' }}
    >
      <Box
        elevation={3}
        component={Paper}
        sx={{ width: 'auto', height: 'auto', p: 3 }}
      >
        <ErrorIcon fontSize='large' color='secondary' sx={{ mb: 1 }} />
        <Typography mb={2} variant='h5' component='div'>
          Une erreur critique est survenue
        </Typography>
        <Button variant='contained' onClick={resetErrorBoundary}>
          Page Principale
        </Button>
      </Box>
    </Container>
  );
}

Error.propTypes = {
  resetErrorBoundary: PropTypes.func.isRequired,
};
