import React from 'react';

import { Controller, useWatch } from 'react-hook-form';

import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

import TabPanelForm from '@/components/TabPanelForm';

export default function EnjeuxSociauxTab({ form, tabValue }) {
  const { control } = form;

  const frequentationPublic = useWatch({
    control,
    name: 'frequentation_public',
  });

  return (
    <TabPanelForm currentValue={tabValue} index={3}>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              name={'frequentation_public'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label='Fréquentation public'
                />
              )}
            />
          </Grid>
          {frequentationPublic && (
            <Grid item xs={12} sm={12} md={4}>
              <Controller
                name={'importance_frequentation'}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    onChange={onChange}
                    value={value ? value : ''}
                    label={'Importance  et nature de la fréquentation'}
                    fullWidth
                    error={!!error}
                    multiline
                    minRows={3}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              name={'mesure_accueil_public'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label='Mesures pour accueillir et canaliser le public'
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              name={'mesure_reduction_frequentation'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label='Mesures pour dissuader voire interdire la fréquentation'
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              name={'balisage_particulier'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label="Existence d'un balisage particulier"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              name={'convention_ouverture_publique'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label="Existence d'une convention d'ouverture au public"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name={'impact_frequentation_gestion'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={
                    'Prise en compte des enjeux sociaux dans la gestion forestière'
                  }
                  fullWidth
                  error={!!error}
                  multiline
                  minRows={3}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </TabPanelForm>
  );
}

EnjeuxSociauxTab.propTypes = {
  form: PropTypes.object.isRequired,
  tabValue: PropTypes.number.isRequired,
};
