import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import useItems from '@/hooks/useItems';

import SwitchField from '@/components/controlled-fields/SwitchField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import MultilineTextField from '@/components/controlled-fields/text-fields/MultilineTextField';
import TabPanelForm from '@/components/TabPanelForm';

import GestionnaireAutocompleteField from '@/features/project-form/GestionnaireAutocompleteField';

import Grid from '@mui/material/Grid';

export default function SituationAdministrativeTab({
  tabValue,
  edition,
  setGestionnaire,
  propriete,
  form,
}) {
  const { control, setValue } = form;
  
  const gestionnaires = useItems('gestionnaires_pro');

  const adhesionSyndicat = useWatch({
    control,
    name: 'propriete.adhesion_syndicat',
  });

  const gestionnaireWatch = useWatch({
    control,
    name: 'propriete.gestionnaire',
  });

  useEffect(() => {
    edition && gestionnaireWatch && setGestionnaire(gestionnaireWatch);
  }, [gestionnaireWatch]);

  return (
    <TabPanelForm currentValue={tabValue} index={2}>
      {propriete && (
        <form>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={6}>
              <SwitchField
                control={control}
                name='propriete.existence_dgd'
                label='Existence DGD'
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
            <Grid item xs={12} sm={6} md={3}>
              <SwitchField
                control={control}
                name='propriete.gestionnaire_preexistant'
                label="Existence d'un gestionnaire"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <GestionnaireAutocompleteField
                control={control}
                name={'propriete.gestionnaire'}
                verboseName={'Gestionnaire forestier pressenti'}
                gestionnaires={gestionnaires}
                setValue={setValue}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
            <Grid item xs={12} sm={6} md={3}>
              <SwitchField
                control={control}
                name='propriete.certification_forestiere'
                label='Certification forestiere'
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
            <Grid item xs={12} sm={6} md={3}>
              <SwitchField
                control={control}
                name='propriete.adhesion_syndicat'
                label='Adhésion syndicat propriétaire privé'
              />
            </Grid>
            {adhesionSyndicat && (
              <Grid item xs={12} sm={6} md={3}>
                <BaseTextField
                  control={control}
                  name='propriete.adhesion_syndicat_detail'
                  maxLength={256}
                  label='Syndicat propriétaire privé'
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
            <Grid item xs={12} sm={12} md={6}>
              <MultilineTextField
                control={control}
                name='propriete.droits_usage_servitudes_divers'
                label="Droits d'usage et servitudes diverses"
              />
            </Grid>
          </Grid>
        </form>
      )}
    </TabPanelForm>
  );
}

SituationAdministrativeTab.propTypes = {
  tabValue: PropTypes.number.isRequired,
  edition: PropTypes.bool,
  setGestionnaire: PropTypes.func.isRequired,
  propriete: PropTypes.object,
  form: PropTypes.object.isRequired,
};
