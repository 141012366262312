import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import ElementBiodiversiteForm from '@/features/item-form/ElementBiodiversiteForm';

import Container from '@mui/material/Container';

export default function ElementBiodiversite() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const itemType = 'elements_biodiversite';

  const emptyItem = {
    numero: null,
    nature: null,
    surface: null,
    preco_gestion: null,
    propriete: null,
  };

  const item = useItem(itemId, itemType, emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <ElementBiodiversiteForm item={item} />}
    </Container>
  );
}
