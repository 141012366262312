import { createSlice } from '@reduxjs/toolkit';

export const getDefaultModels = (defaultPagination) => {
  return {
    filter: { items: [] },
    visibility: {},
    sorting: [],
    pagination: defaultPagination,
  };
};

const datagridSlice = createSlice({
  name: 'datagrid',
  initialState: {},
  reducers: {
    setModel: (state, { payload }) => {
      state[payload.key][payload.stateType] = payload.newState;
    },
    setDatagridDefaultModels: (state, { payload }) => {
      state[payload.key] = getDefaultModels(payload.defaultPaginationModel);
    },
  },
});

export const { setModel, setDatagridDefaultModels } = datagridSlice.actions;

export default datagridSlice.reducer;
