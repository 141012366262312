import React from 'react';

import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import useChoices from '@/hooks/useChoices';
import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useDownloadDocument from '@/hooks/useDownloadDocument';
import useItems from '@/hooks/useItems';

import CellExpand from '@/components/CellExpand';
import CustomDataGrid from '@/components/CustomDataGrid';
import ManyItemsCell from '@/components/ManyItemsCell';
import XLSXExportButton from '@/components/XLSXExportButton';

import { deleteItem } from '@/slices/itemSlice';

import { fromISODate, fromISODateTime } from '@/utils/date';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';

import APIService from '@/APIService';

export default function ContratsProprietaires() {
  const { open } = useConfirmationDialog();
  const downloadDocument = useDownloadDocument();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const itemType = 'contrats_proprietaires';

  const choices = useChoices(itemType);

  const items = useItems(itemType);

  const handleEditItem = (itemId) => {
    navigate(`/contrat-proprietaire/${itemId}`);
  };

  const handleDeleteItem = async (itemId, itemName) => {
    const isConfirmed = await open({
      title: 'Suppression',
      content: `Confirmer la suppression de ${itemName} ?`,
    });

    if (isConfirmed) {
      dispatch(deleteItem({ itemId, itemType }));
    }
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<EditIcon />}
          label='Éditer'
          onClick={() => handleEditItem(params.id)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<DeleteIcon />}
          label='Supprimer'
          onClick={() => handleDeleteItem(params.id, params.row.code_chantier)}
        />,
      ],
    },
    {
      field: 'code_chantier',
      headerName: 'Code chantier',
      width: 220,
      editable: false,
      renderCell: ({ row }) => <CellExpand value={row.code_chantier} />,
    },
    {
      field: 'eligibilite_presumee_lbc',
      headerName: 'Éligibilité présumée au LBC',
      width: 160,
      type: 'boolean',
      editable: false,
    },
    {
      field: 'eligibilite_presumee_aide_publique',
      headerName: 'Éligibilité présumée à une aide publique',
      width: 160,
      type: 'boolean',
      editable: false,
    },
    {
      field: 'points_vigilance',
      headerName: 'Éléments pouvant conditionner la faisabilité du projet',
      width: 300,
      editable: false,
    },
    {
      field: 'date_signature_contrat_seing_prive',
      headerName: 'Date de signature du contrat sous seing privé',
      width: 220,
      editable: false,
      valueFormatter: (value) => (fromISODate(value)),
    },
    {
      field: 'date_fin_contrat',
      headerName: 'Date de fin du contrat',
      width: 220,
      editable: false,
      valueFormatter: (value) => (fromISODate(value)),
    },
    {
      field: 'date_signature_acte_notarie',
      headerName: "Date de signature de l'acte notarié",
      width: 220,
      editable: false,
      valueFormatter: (value) => (fromISODate(value)),
    },
    {
      field: 'date_premiere_visite_neosylva',
      headerName: 'Date première visite Néosylva',
      width: 220,
      editable: false,
      valueFormatter: (value) => (fromISODate(value)),
    },
    {
      field: 'date_visite_gestionnaire',
      headerName: 'Date de visite du gestionnaire',
      width: 220,
      editable: false,
      valueFormatter: (value) => (fromISODate(value)),
    },
    {
      field: 'montant_forfaitaire',
      headerName: 'Montant forfaitaire',
      width: 160,
      editable: false,
      valueFormatter: (value) => (value ? value.toFixed(2) : null),
    },
    {
      field: 'pourcentage_services_environnementaux',
      headerName: 'Pourcentage services environnementaux',
      width: 160,
      editable: false,
      valueFormatter: (value) => (value ? value.toFixed(2) : null),
    },
    {
      field: 'montant_total_previsionnel_operations',
      headerName: 'Montant total prévisionnel des opérations',
      width: 160,
      editable: false,
      valueFormatter: (value) => (value ? value.toFixed(2) : null),
    },
    {
      field: 'date_paiement_forfaitaire',
      headerName: 'Date paiement forfaitaire',
      width: 160,
      editable: false,
    },
    {
      field: 'surface_pressentie_sous_contrat',
      headerName: 'Surface pressentie sous contrat (ha)',
      width: 160,
      editable: false,
    },
    {
      field: 'itineraires_sylvicoles_pressentis',
      headerName: 'État actuel et itinéraire(s) sylvicole(s) pressenti(s)',
      width: 300,
      editable: false,
    },
    {
      field: 'etat',
      headerName: 'État',
      type: 'singleSelect',
      valueOptions: choices?.etats.map((value) => value.nom),
      width: 180,
      editable: false,
      valueGetter: (value) => value?.nom,
      renderCell: ({ row }) => <CellExpand value={row.etat?.nom} />,
    },
    {
      field: 'cmf_neosylva',
      headerName: 'CMF Neosylva',
      width: 160,
      editable: false,
      valueGetter: (_, row) => row.propriete?.cmf_neosylva?.username,
      renderCell: ({ row }) => (
        <CellExpand value={row.propriete?.cmf_neosylva?.username} />
      ),
    },
    {
      field: 'points_vigilance',
      headerName: 'Éléments pouvant conditionner la faisabilité du projet',
      width: 300,
      editable: false,
    },
    {
      field: 'referent_technique',
      headerName: 'Référent technique contractuel',
      width: 260,
      valueGetter: (value) => value?.nom, // For filtering ...
      renderCell: ({ row }) =>
        row.referent_technique && (
          <Link
            component={RouterLink}
            to={`/contact/${row.referent_technique.id}`}
          >
            {row.referent_technique.nom}
          </Link>
        ),
    },
    {
      field: 'proprietaires_list',
      headerName: 'Propriétaires',
      width: 260,
      valueGetter: (value) =>
        value.map((proprietaire) => proprietaire.contact.nom),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.proprietaires_list,
            getRelatedName: (proprietaire) => proprietaire.contact.nom,
            relatedPath: '/proprietaire',
            relatedVerboseName: 'propriétaire',
          }}
        />
      ),
    },
    {
      field: 'propriete',
      headerName: 'Propriété',
      width: 260,
      valueGetter: (value) => value.nom_commun, // For filtering ...
      renderCell: ({ row }) => (
        <Link component={RouterLink} to={`/propriete/${row.propriete.id}`}>
          {row.propriete.nom_commun}
        </Link>
      ),
    },
    {
      field: 'unites_gestion_list',
      headerName: 'Unités de gestion',
      width: 260,
      valueGetter: (value) => value.map((parcelle) => parcelle.nom),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.unites_gestion_list,
            getRelatedName: (parcelle) => parcelle.nom,
            relatedPath: '/unite-gestion',
            relatedVerboseName: 'parcelle',
          }}
        />
      ),
    },
    {
      field: 'documents_list',
      headerName: 'Documents',
      width: 260,
      valueGetter: (_, row) =>
        row.sharepoints_list
          .concat(row.files_list)
          .map((document) => document.name),
      renderCell: ({ row }) => (
        <ManyItemsCell
          openNewTab={true}
          itemId={row.id}
          relatedItems={{
            getRelatedName: (document) =>
              document.repository
                ? document.name
                : `${document.name} (${document.filename})`,
            relatedItemsList: row.sharepoints_list.concat(row.files_list),
            getRelatedPath: (document) => document.repository,
            relatedVerboseName: 'document',
            onClick: (document) =>
              !document.repository &&
              APIService.get({
                url: `contrats_proprietaires/${row.id}/files/${document.id}`,
                opts: { responseType: 'blob' },
              }).then(downloadDocument),
          }}
        />
      ),
    },
    {
      field: 'creation_datetime',
      headerName: 'Création',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
    {
      field: 'last_modification_datetime',
      headerName: 'Dernière modification',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
  ];

  const handleFormRedirect = () => {
    navigate('/contrat-proprietaire');
  };

  return (
    <Box component='main' mx={3} mt={2}>
      <Typography mb={1} variant='h5' component='div'>
        Contrats propriétaires
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm='auto'>
          <Button variant='contained' onClick={handleFormRedirect} fullWidth>
            Ajouter un contrat
          </Button>
        </Grid>
        <Grid item xs={12} sm='auto'>
          <XLSXExportButton itemType={itemType} fullWidth />
        </Grid>
      </Grid>
      <CustomDataGrid columns={columns} rows={items} />
    </Box>
  );
}
