import React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useItems from '@/hooks/useItems';

import CustomDataGrid from '@/components/CustomDataGrid';
import XLSXExportButton from '@/components/XLSXExportButton';

import { clearCurrentItem } from '@/slices/itemSlice';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';

export default function Essences() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const itemType = 'departements_cmf';

  const usersList = useItems('users');

  const items = useItems(itemType);

  const handleEditItem = (itemId) => {
    dispatch(clearCurrentItem());
    navigate(`/departement-cmf/${itemId}`);
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<EditIcon />}
          label='Éditer'
          onClick={() => handleEditItem(params.id)}
        />,
      ],
    },
    {
      field: 'numero',
      headerName: 'N° dpt',
      width: 80,
      editable: false,
    },
    {
      field: 'nom',
      headerName: 'Département',
      width: 200,
      editable: false,
    },
    {
      field: 'user',
      headerName: 'CMF',
      type: 'singleSelect',
      valueOptions:
        usersList &&
        usersList
          .filter((user) => user.role.name === 'neosylva_cmf')
          .map((user) => user.username),
      width: 160,
      editable: false,
      valueGetter: (value) => value.username,
    },
  ];

  return (
    <Box component='main' mx={3} mt={2}>
      <Typography mb={1} variant='h5' component='div'>
        Départements CMF
      </Typography>
      <Grid container>
        <Grid item xs={12} sm='auto'>
          <XLSXExportButton itemType={itemType} fullWidth />
        </Grid>
      </Grid>
      <CustomDataGrid columns={columns} rows={items} defaultPageSize={100} />
    </Box>
  );
}
