import React from 'react';

import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { downloadFromUrl } from '@/utils/file';

import Button from '@mui/material/Button';

import APIService from '@/APIService';

export default function XLSXExportButton({ itemType, ...props }) {
  const handleXLSXExport = () => {
    document.body.style.cursor = 'wait';
    APIService.get({
      url: `${itemType}/xlsx-export/`,
      opts: { responseType: 'blob' },
    })
      .then((res) => {
        const href = URL.createObjectURL(new Blob([res.data]));
        const currentDatetime = format(new Date(), 'ddMMyyyy_HHmmss');
        downloadFromUrl(href, `${itemType}_${currentDatetime}.xlsx`);
        URL.revokeObjectURL(href);
      })
      .finally(() => (document.body.style.cursor = 'default'));
  };

  return (
    <Button variant='contained' onClick={handleXLSXExport} {...props}>
      Export Excel
    </Button>
  );
}

XLSXExportButton.propTypes = {
  itemType: PropTypes.string.isRequired,
};
