import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export default function CheckboxSelectField({
  control,
  name,
  required,
  label,
  baseId,
  values,
}) {
  baseId = baseId ? baseId : name;
  const idSelect = `${baseId}-select`;
  const idLabel = `${idSelect}-label`;
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth required={required}>
          <InputLabel id={idLabel}>{label}</InputLabel>
          <Select
            id={idSelect}
            labelId={idLabel}
            onChange={onChange}
            multiple
            value={value || []}
            label={label}
            error={!!error}
            renderValue={(selected) => (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 0.5,
                }}
              >
                {selected.map((value, index) => (
                  <Chip key={index} label={value} />
                ))}
              </Box>
            )}
          >
            {values &&
              values.map((item, index) => (
                <MenuItem key={index} value={item}>
                  <Checkbox checked={value && value.indexOf(item) > -1} />
                  <ListItemText primary={item} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    />
  );
}

CheckboxSelectField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  values: PropTypes.array,
  baseId: PropTypes.string,
  renderValue: PropTypes.func,
  required: PropTypes.bool,
};
