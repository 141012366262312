import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

const filter = createFilterOptions();

export default function GestionnaireAutocompleteField({
  control,
  defaultGestionnaire,
  gestionnaires,
  disabled,
  name,
  verboseName,
  required,
  setValue,
}) {
  const [open, setOpen] = useState(false);

  const requiredRule = required ? { required: `${verboseName} requis.` } : {};

  const [defaultNom, setDefaultNom] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = ({ nom, prenom }) => {
    setValue(
      name,
      {
        contact: { nom, prenom },
      },
      { shouldValidate: true }
    );
    handleClose();
  };

  const GestionnaireForm = ({ defaultNom }) => {
    const {
      handleSubmit,
      control: addGestionnaireControl,
      formState: { isDirty, isValid },
    } = useForm({
      defaultValues: { nom: defaultNom, prenom: '' },
      reValidateMode: 'onBlur',
      mode: 'onChange',
    });

    return (
      <form>
        <DialogTitle>Ajouter un nouveau gestionnaire</DialogTitle>
        <DialogContent>
          <Grid container sx={{ p: 2 }} spacing={2} alignItems='center'>
            <Grid item xs={12} sm={6}>
              <Controller
                name={'nom'}
                control={addGestionnaireControl}
                rules={{ maxLength: 256, required: 'Nom requis.' }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    onChange={onChange}
                    value={value ? value : ''}
                    label={'Nom'}
                    inputProps={{ maxLength: 256 }}
                    fullWidth
                    required={true}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name={'prenom'}
                control={addGestionnaireControl}
                rules={{ maxLength: 256, required: 'Prénom requis.' }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    onChange={onChange}
                    value={value ? value : ''}
                    label={'Prénom'}
                    inputProps={{ maxLength: 256 }}
                    fullWidth
                    required={true}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            disabled={!isDirty || !isValid}
            onClick={handleSubmit(onSubmit)}
          >
            Ajouter
          </Button>
        </DialogActions>
      </form>
    );
  };

  GestionnaireForm.propTypes = {
    defaultNom: PropTypes.string.isRequired,
  };

  const getGestionnaireDescription = (option) => {
    let gestionnaireDescription = option.contact.nom;
    if (option.contact.prenom) {
      gestionnaireDescription += ` ${option.contact.prenom}`;
    }
    if (option.contact.departement) {
      gestionnaireDescription += ` (${option.contact.departement})`;
    }
    return gestionnaireDescription;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={requiredRule}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <Autocomplete
            loading={!gestionnaires}
            required={required}
            value={value}
            disabled={disabled}
            onChange={(_, newValue) => {
              if (typeof newValue === 'string') {
                setTimeout(() => {
                  setOpen(true);
                  setDefaultNom(newValue);
                });
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                setOpen(true);
                setDefaultNom(newValue.inputValue);
              } else {
                onChange(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;

              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option.contact.nom
              );

              if (
                inputValue !== '' &&
                inputValue.length <= 256 &&
                !isExisting &&
                !!gestionnaires
              ) {
                filtered.push({
                  inputValue,
                  contact: { nom: `Ajouter le gestionnaire "${inputValue}"` },
                });
              }

              return filtered;
            }}
            renderOption={(props, option) => (
              <li {...props}>{getGestionnaireDescription(option)}</li>
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return getGestionnaireDescription(option);
            }}
            options={gestionnaires || []}
            defaultValue={defaultGestionnaire}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                required={required}
                label={verboseName}
                error={!!error}
                value={value ? value : ''}
                helperText={error ? error.message : null}
              />
            )}
          />
          <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
            {defaultNom && <GestionnaireForm defaultNom={defaultNom} />}
          </Dialog>
        </>
      )}
    />
  );
}

GestionnaireAutocompleteField.propTypes = {
  control: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  verboseName: PropTypes.string.isRequired,
  required: PropTypes.bool,
  gestionnaires: PropTypes.array,
  defaultGestionnaire: PropTypes.object,
  setValue: PropTypes.func.isRequired,
};
