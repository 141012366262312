import React from 'react';

import PropTypes from 'prop-types';

import Clear from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';

/**
 * GridToolbarQuickFilter
 * Filter all fields except boolean fields
*/
export default function CustomGridToolBar({ hasModel, onReset }) {
  return (
    <GridToolbarContainer>
      <Grid container justifyContent='space-between'>
        <Grid item xs={12} sm={6}>
          <GridToolbarQuickFilter
            quickFilterParser={(searchInput) =>
              searchInput.split(',').map((value) => value.trim())
            }
            quickFilterFormatter={(quickFilterValue) => quickFilterValue.join(', ')}
            debounceMs={500}
          />
        </Grid>
        {hasModel && (
          <Grid item>
            <Button onClick={onReset} startIcon={<Clear/>} >
              Réinitialiser
            </Button>
          </Grid>
        )}
      </Grid>
    </GridToolbarContainer>
  );
}

CustomGridToolBar.propTypes = {
  hasModel: PropTypes.bool.isRequired,
  onReset: PropTypes.func.isRequired,
};
