import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useOnMount from '@/hooks/useOnMount';

import {
  getDefaultModels,
  setDatagridDefaultModels,
  setModel,
} from '@/slices/datagridSlice';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';

import CustomGridToolBar from './CustomGridToolBar';

export default function CustomDataGrid({
  columns,
  rows,
  defaultPageSize = 10,
  getRowId,
  getRowHeight,
  autoHeight = false,
  columnGroupingModel,
  sx,
  alternateRowColor,
}) {
  const dispatch = useDispatch();

  const location = useLocation();

  const username = useSelector(
    (store) => store.persistAuthReducer.user?.username
  );

  const key = `${username}${location.pathname}`;

  const datagridModels = useSelector(
    (store) => store.persistDatagridReducer[key]
  );

  const [hasModel, setHasModel] = useState(false);

  useEffect(() => {
    const hasSortModel = datagridModels?.sorting.length > 0;

    const hasFilterModel =
      datagridModels?.filter.quickFilterValues?.some((value) => value !== '') ||
      datagridModels?.filter.items.some((item) => item.value);

    const hasVisibilityModel =
      datagridModels &&
      Object.values(datagridModels.visibility).some((value) => !value);

    setHasModel(hasSortModel || hasFilterModel || hasVisibilityModel);
  }, [datagridModels]);

  const apiRef = useGridApiRef();

  const defaultPaginationModel = {
    page: 0,
    pageSize: defaultPageSize,
  };

  const setModels = (models) => {
    apiRef.current.setFilterModel(models.filter);
    apiRef.current.setSortModel(models.sorting);
    apiRef.current.setColumnVisibilityModel(models.visibility);
    apiRef.current.setPaginationModel(models.pagination);
  };

  useOnMount(() => {
    if (datagridModels) {
      setModels(datagridModels);
    } else {
      dispatch(setDatagridDefaultModels({ key, defaultPaginationModel }));
      apiRef.current.setPaginationModel(defaultPaginationModel);
    }
  });

  const onReset = () => {
    dispatch(setDatagridDefaultModels({ key, defaultPaginationModel }));
    const defaultModel = getDefaultModels(defaultPaginationModel);
    setModels(defaultModel);
  };

  const handleSaveState = (stateType, state) => {
    dispatch(
      setModel({
        stateType: stateType,
        newState: state,
        key: key,
      })
    );
  };

  return (
    <Box
      component={Paper}
      elevation={3}
      sx={{
        width: '100%',
        my: 2,
        flex: '1 1 0px',
        overflow: 'auto',
        height: '100%',
      }}
    >
      <DataGrid
        apiRef={apiRef}
        loading={!rows}
        autoHeight={autoHeight}
        rows={rows || []}
        columns={columns}
        pageSizeOptions={[10, 15, 20, 30, 50, 100]}
        checkboxSelection={false}
        columnGroupingModel={columnGroupingModel}
        getRowId={getRowId}
        getRowHeight={getRowHeight}
        onPaginationModelChange={(value) => {
          handleSaveState('pagination', value);
        }}
        onSortModelChange={(value) => {
          handleSaveState('sorting', value);
        }}
        onColumnVisibilityModelChange={(value) => {
          handleSaveState('visibility', value);
        }}
        onFilterModelChange={(value) => {
          handleSaveState('filter', value);
        }}
        slots={{
          toolbar: CustomGridToolBar,
          loadingOverlay: LinearProgress,
        }}
        slotProps={{
          toolbar: { hasModel, onReset },
        }}
        sx={{
          ...sx,
          '.datagrid-odd-row': {
            backgroundColor: '#f8f8f8',
          },
          '.datagrid-even-row': {
            backgroundColor: 'white',
          },
        }}
        getRowClassName={alternateRowColor && (({ id }) => id % 2 === 0 ? 'datagrid-even-row' : 'datagrid-odd-row')}
      />
    </Box>
  );
}

CustomDataGrid.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array,
  getRowId: PropTypes.func,
  defaultPageSize: PropTypes.number,
  getRowHeight: PropTypes.func,
  autoHeight: PropTypes.bool,
  columnGroupingModel: PropTypes.array,
  sx: PropTypes.object,
  alternateRowColor: PropTypes.bool,
};
