import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import ChangePasswordFormDialog from '@/features/ChangePasswordFormDialog';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function Account() {
  const user = useSelector((store) => store.persistAuthReducer.user);

  const [open, setOpen] = useState(false);

  return (
    <Container component='main' maxWidth='md'>
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
        }}
      >
        <Paper elevation={3} sx={{ minWidth: 520, p: 3 }}>
          <Typography variant='h6' component='div'>
            {user?.username}
          </Typography>
          <Button
            variant='contained'
            sx={{ mt: 3 }}
            type='button'
            onClick={() => setOpen(true)}
          >
            Changer le mot de passe
          </Button>
        </Paper>
      </Box>
      {user?.id && (
        <ChangePasswordFormDialog
          open={open}
          setOpen={setOpen}
          userId={user.id}
        />
      )}
    </Container>
  );
}
