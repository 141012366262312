import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import useFetchFilteredItems from '@/hooks/useFetchFilteredItems';

import SwitchField from '@/components/controlled-fields/SwitchField';
import AreaTextField from '@/components/controlled-fields/text-fields/AreaTextField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import CodePostalTextField from '@/components/controlled-fields/text-fields/CodePostalTextField';
import DividerTitle from '@/components/DividerTitle';
import TabPanelForm from '@/components/TabPanelForm';

import AdresseField from '@/features/AdresseField';
import DepartementField from '@/features/DepartementField';
import ParcelleCadastralePressentieSousContratTable from '@/features/project-form/parcelle-cadastrale-pressentie-sous-contrat/ParcelleCadastralePressentieSousContratTable';
import ProprieteAutocompleteSearchField from '@/features/project-form/ProprieteAutocompleteSearchField';

import { setFilteredItems } from '@/slices/itemSlice';

import Grid from '@mui/material/Grid';

export default function ProprieteTab({
  form,
  tabValue,
  choices,
  parcellesList,
  proprieteState,
  createPropriete,
}) {
  const { control, setValue, reset } = form;

  const dispatch = useDispatch();

  const fetchFilteredItems = useFetchFilteredItems();

  const differentAddress = useWatch({
    control,
    name: 'propriete.different_address',
  });

  const { propriete, setPropriete } = proprieteState;

  useEffect(() => {
    if (propriete?.id) {
      fetchFilteredItems({
        filter: { propriete_id: propriete.id },
        itemType: ['parcelles_cadastrales_pressenties_sous_contrat'],
      });
    } else {
      reset();
      setValue('propriete.nom_commun', propriete?.inputValue);
      dispatch(
        setFilteredItems({
          itemsList: [],
          itemType: 'parcelles_cadastrales_pressenties_sous_contrat',
        })
      );
    }
  }, [propriete]);

  return (
    <TabPanelForm currentValue={tabValue} index={1}>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <ProprieteAutocompleteSearchField
              propriete={propriete}
              setPropriete={setPropriete}
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
          <Grid item xs={12} sm={6} md={2}>
            <SwitchField
              control={control}
              name='propriete.different_address'
              label='Adresse de la propriété différente de l’adresse du propriétaire'
              disabled={!createPropriete}
            />
          </Grid>
          {differentAddress && (
            <>
              <Grid item xs={12} sm={7} md={5}>
                <AdresseField
                  control={control}
                  fieldName={'propriete.adresse'}
                  relatedFiedlNames={{
                    commune: 'propriete.commune',
                    codePostal: 'propriete.code_postal',
                  }}
                  label={'Adresse propriété'}
                  setValue={setValue}
                  disabled={!createPropriete}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <BaseTextField
                  control={control}
                  name='propriete.commune'
                  maxLength={256}
                  label='Commune propriété'
                  disabled={!createPropriete}
                />
              </Grid>
              <Grid item xs={5} sm={4} md={2}>
                <CodePostalTextField
                  control={control}
                  name={'propriete.code_postal'}
                  label={'Code postal propriété'}
                  disabled={!createPropriete}
                />
              </Grid>
              <Grid item xs={7} sm={4} md={3}>
                <DepartementField
                  label={'Département propriété'}
                  departements={choices?.departements}
                  control={control}
                  departementFieldName={'propriete.departement'}
                  codePostalFieldName={'propriete.code_postal'}
                  setValue={setValue}
                  disabled={!createPropriete}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <AreaTextField
              control={control}
              name='propriete.surface_totale_propriete'
              label='Surface totale propriété (ha)'
              disabled={!createPropriete}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
          <Grid item xs={12}>
            <SwitchField
              control={control}
              name='propriete.existence_dgd'
              label='Existence DGD'
              disabled={!createPropriete}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
          <Grid item xs={12} sm={10}>
            <DividerTitle text={'Parcellaire cadastrale'} />
          </Grid>
          <Grid item xs={12}>
            <ParcelleCadastralePressentieSousContratTable
              items={parcellesList}
              title={'Parcelles cadastrales'}
              disabledEdit={!createPropriete}
            />
          </Grid>
        </Grid>
      </form>
    </TabPanelForm>
  );
}

ProprieteTab.propTypes = {
  form: PropTypes.object.isRequired,
  tabValue: PropTypes.number.isRequired,
  choices: PropTypes.object,
  parcellesList: PropTypes.array,
  isProp: PropTypes.bool,
  proprieteState: PropTypes.object.isRequired,
  createPropriete: PropTypes.bool.isRequired,
};