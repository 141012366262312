import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import DocumentGestionDurableForm from '@/features/item-form/DocumentGestionDurableForm';

import Container from '@mui/material/Container';

export default function DocumentGestionDurable() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const emptyItem = {
    type: null,
    surface: null,
    numero_adherent: null,
    proprietes_list: [],
  };

  const item = useItem(itemId, 'dgd', emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <DocumentGestionDurableForm item={item} />}
    </Container>
  );
}
