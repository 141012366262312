import { useDispatch } from 'react-redux';

import useGoBackHistory from '@/hooks/useGoBackHistory';

import { setIsLoading } from '@/slices/isLoadingSlice';
import { addItem, patchItem } from '@/slices/itemSlice';

export default function useFormItem(itemId, itemType, itemPath) {
  const dispatch = useDispatch();

  const goBackHistory = useGoBackHistory();

  const onSubmit = (data) => {
    let actionPromise;

    if (itemId) {
      actionPromise = dispatch(patchItem({ data, itemId, itemType }));
    } else {
      actionPromise = dispatch(addItem({ data, itemType }));
    }

    actionPromise
      .then(({ payload }) => {
        if (payload) {
          goBackHistory({ defaultPath: itemPath });
        }
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };

  const onCancel = () => {
    goBackHistory({ defaultPath: itemPath });
  };

  return {
    onCancel,
    onSubmit,
  };
}
