import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import ClearIcon from '@mui/icons-material/Clear';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export default function IndivisaireReferentSelect({
  control,
  name,
  onClear,
  indivisaires,
  disabled,
}) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth>
          <InputLabel id='indivisaires-select-label'>Indivisaires</InputLabel>
          <Select
            labelId='indivisaires-select-label'
            id='indivisaires-select'
            onChange={onChange}
            value={value || ''}
            label='Indivisaires'
            error={!!error}
            defaultValue={null}
            disabled={disabled}
            endAdornment={
              <IconButton
                sx={{
                  visibility: value && !disabled ? 'visible' : 'hidden',
                }}
                onClick={onClear}
              >
                <ClearIcon />
              </IconButton>
            }
            renderValue={(value) => {
              let description = value.proprietaire.contact.nom;
              if (value.proprietaire.contact.prenom) {
                description = `${value.proprietaire.contact.prenom} ${description}`;
              }
              return description;
            }}
          >
            {indivisaires &&
              indivisaires
                .filter((indivisaire) => !indivisaire.principale)
                .map((indivisaire, indivsaireIdx) => (
                  <MenuItem key={indivsaireIdx} value={indivisaire}>
                    {indivisaire.proprietaire.contact.prenom
                      ? `${indivisaire.proprietaire.contact.prenom} ${indivisaire.proprietaire.contact.nom}`
                      : indivisaire.proprietaire.contact.nom}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      )}
    />
  );
}
IndivisaireReferentSelect.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onClear: PropTypes.func.isRequired,
  indivisaires: PropTypes.array,
  disabled: PropTypes.bool,
};
