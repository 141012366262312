import { useCallback } from 'react';

export default function useSetFormValues() {
  return useCallback((fields, item, itemName) => {
    fields.forEach((field) => {
      const [name, isRequired, setValue, nestedItem] = field;

      let fieldName, fieldValue;
      if (nestedItem) {
        fieldName = `${itemName}.${nestedItem}.${name}`;
        fieldValue = item[nestedItem][name];
      } else {
        fieldName = `${itemName}.${name}`;
        fieldValue = item[name];
      }
      setValue(fieldName, fieldValue, {
        shouldDirty: isRequired,
        shouldValidate: true,
      });
    });
  });
}
