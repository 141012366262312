import React from 'react';

import { useParams } from 'react-router-dom';

import useItem from '@/hooks/useItem';

import UniteGestionForm from '@/features/item-form/UniteGestionForm';

import Container from '@mui/material/Container';

export default function UniteGestion() {
  const params = useParams();

  const itemId = params.id ? parseInt(params.id, 10) : undefined;

  const itemType = 'unites_gestion';

  const emptyItem = {
    observations_peuplement: null,
    surface_sig_ug: null,
    eligibilite_carbone: false,
    foret_semi_naturelle: null,
    peuplement_objectif: null,
    peuplement_initial: null,
    interventions_list: null,
    services_env_list: null,
    stock_carbone: null,
    essences_list: null,
    densite: null,
    itineraire_sylvicole_preconise: null,
    nom: null,
    contrat_proprietaire: null,
  };

  const item = useItem(itemId, itemType, emptyItem);

  return (
    <Container component='main' maxWidth={false} sx={{ my: 2 }}>
      {item && <UniteGestionForm item={item} />}
    </Container>
  );
}
