import React from 'react';

import PropTypes from 'prop-types';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

export default function DividerTitle({ text }) {
  return (
    <Divider
      component='div'
      role='presentation'
      textAlign='left'
      sx={{
        '&::before, &::after': {
          borderColor: 'primary.main',
        },
      }}
    >
      <Typography
        variant='overline'
        component='div'
        sx={{ fontWeight: 'bold', color: 'primary.main' }}
      >
        { text }
      </Typography>
    </Divider>
  );
}

DividerTitle.propTypes = {
  text: PropTypes.string.isRequired,
};
