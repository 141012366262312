import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import TabPanelForm from '@/components/TabPanelForm';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

export default function EnjeuxEnvironnementauxTab({ form, tabValue, choices }) {
  const { control } = form;
  return (
    <TabPanelForm currentValue={tabValue} index={4}>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'zonages_identifies_list'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel id='zonage-select-label'>
                    Zonages identifiés
                  </InputLabel>
                  <Select
                    labelId='zonage-select-label'
                    id='zonage-select'
                    onChange={onChange}
                    value={value || []}
                    label='Zonages identifiés'
                    error={!!error}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value, idx) => (
                          <Chip key={idx} label={value} />
                        ))}
                      </Box>
                    )}
                    multiple
                  >
                    {choices &&
                      choices.zonages_identifies_types.map((zonage, idx) => (
                        <MenuItem key={idx} value={zonage}>
                          <Checkbox
                            checked={value && value.indexOf(zonage) > -1}
                          />
                          <ListItemText primary={zonage} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name={'zonages_identifies_commentaire'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Autre(s)/ commentaires'}
                  fullWidth
                  error={!!error}
                  multiline
                  minRows={3}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </TabPanelForm>
  );
}

EnjeuxEnvironnementauxTab.propTypes = {
  form: PropTypes.object.isRequired,
  tabValue: PropTypes.number.isRequired,
  choices: PropTypes.object,
};
