import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import CheckboxSelectField from '@/components/controlled-fields/CheckboxSelectField';
import SwitchField from '@/components/controlled-fields/SwitchField';
import MultilineTextField from '@/components/controlled-fields/text-fields/MultilineTextField';
import TabPanelForm from '@/components/TabPanelForm';

import Grid from '@mui/material/Grid';

export default function EnjeuxSocioEnvTab({
  tabValue,
  propriete,
  form,
  choices
}) {
  const { control } = form;
  const frequentationPublic = useWatch({
    control,
    name: 'propriete.frequentation_public',
  });
  return (
    <TabPanelForm currentValue={tabValue} index={4}>
      {propriete && (
        <form>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={4} md={3}>
              <SwitchField
                control={control}
                name='propriete.frequentation_public'
                label='Fréquentation public'
              />
            </Grid>
            {frequentationPublic && (
              <Grid item xs={12} sm={8} md={5}>
                <MultilineTextField
                  control={control}
                  name='propriete.importance_frequentation'
                  label='Importance et nature de la fréquentation'
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
            <Grid item xs={12} sm={8} md={6}>
              <CheckboxSelectField
                control={control}
                name='propriete.zonages_identifies_list'
                label='Zonages identifiés'
                values={choices?.zonages_identifies_types}
                baseId='zonage-constate'
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
            <Grid item xs={12} sm={8} md={6}>
              <MultilineTextField
                control={control}
                name='propriete.zonages_identifies_commentaire'
                label='Autre(s) zonage(s)/ commentaires'
              />
            </Grid>
          </Grid>
        </form>
      )}
    </TabPanelForm>
  );
}

EnjeuxSocioEnvTab.propTypes = {
  tabValue: PropTypes.number.isRequired,
  propriete: PropTypes.object,
  form: PropTypes.object.isRequired,
  choices: PropTypes.object,
};
