import { useEffect, useMemo } from 'react';

import { useWatch } from 'react-hook-form';

/**
 * Control the required state for a group of fields.
 * When one field in a group is filled, the whole group is required.
 * @param {Array<Array<String>>} fieldsGroup An List of fields group
 * @param {Control} control The form control
 * @param {Function} trigger Function to manualy trigger fields validation
 * @returns The required mapper
 */
export default function useConditionalRequired(fieldsGroup, control, trigger) {

  // Flat and memoize the field group
  const flattenFieldsGroup = useMemo(() => fieldsGroup.flat());
  const watcher = useWatch({
    control,
    name: flattenFieldsGroup,
  });

  /**
   * Build and memoize a mapper, that determine for every field if it is required or not.
   */
  const mapper = useMemo(() => {
    // Map every field with its value
    const fieldValueMapper = watcher.reduce(
      (mapper, value, index) => ({ ...mapper, [flattenFieldsGroup[index]]: value }), {}
    );

    let mapper = {};

    // True if all fields are empty, False otherwise
    const areFieldsEmpty = !watcher.some(Boolean);

    // Iterate over all fields group
    fieldsGroup.forEach(group => {
      // All the fields are required if any field of the group is filled, or if all the fields of all the groups are empty
      const isGroupRequired = group.some(field => areFieldsEmpty || fieldValueMapper[field]);
      // Register the group fields in the required mapper
      const mappedGroup = group
        .map(field => ({ [field.split('.').pop()]: isGroupRequired }))
        .reduce((mapper, value) => ({
          ...mapper,
          ...value,
        }));
      mapper = {
        ...mapper,
        ...mappedGroup,
      };
    });

    return mapper;
  }, [...watcher]);

  /**
   * Manually trigger validation after render (because of conditional required).
   * A condition ensure that the validation is not triggered on first render, 
   * when every fields are empty.
   */
  useEffect(() => {
    !Object.values(mapper).every(Boolean) && trigger(fieldsGroup.flat());
  }, [mapper]);

  return mapper;
}