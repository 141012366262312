import React from 'react';

import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import useChoices from '@/hooks/useChoices';
import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useItems from '@/hooks/useItems';

import CSVExportButton from '@/components/CSVExportButton';
import CustomDataGrid from '@/components/CustomDataGrid';

import { deleteItem } from '@/slices/itemSlice';

import { fromISODateTime } from '@/utils/date';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';

export default function ElementsBiodiversite() {
  const { open } = useConfirmationDialog();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const itemType = 'elements_biodiversite';

  const choices = useChoices(itemType);

  const items = useItems(itemType);

  const handleEditItem = (itemId) => {
    navigate(`/element-biodiversite/${itemId}`);
  };

  const handleDeleteItem = async (itemId, elementNature) => {
    const isConfirmed = await open({
      title: 'Suppression',
      content: `Confirmer la suppression de l'élement de biodiversité ${elementNature}?`,
    });

    if (isConfirmed) {
      dispatch(deleteItem({ itemId, itemType }));
    }
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<EditIcon />}
          label='Éditer'
          onClick={() => handleEditItem(params.id)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<DeleteIcon />}
          label='Supprimer'
          onClick={() => handleDeleteItem(params.id, params.row.nature)}
        />,
      ],
    },
    {
      field: 'numero',
      headerName: 'Élément de biodiversité n°',
      width: 160,
      editable: false,
    },
    {
      field: 'nature',
      headerName: 'Nature',
      type: 'singleSelect',
      valueOptions: choices?.natures.map((value) => value.nom),
      width: 160,
      editable: false,
      valueGetter: (value) => value?.nom,
    },
    {
      field: 'surface',
      headerName: 'Surface en ha / linéaire en ml',
      width: 180,
      editable: false,
      valueFormatter: (value) => (value ? value.toFixed(4) : null),
    },
    {
      field: 'preco_gestion',
      headerName: 'Préconisations de gestion',
      width: 220,
      editable: false,
    },
    {
      field: 'propriete',
      headerName: 'Propriété',
      width: 260,
      valueGetter: (value) => value.nom_commun, // For filtering ...
      renderCell: ({ row }) => (
        <Link component={RouterLink} to={`/propriete/${row.propriete.id}`}>
          {row.propriete.nom_commun}
        </Link>
      ),
    },
    {
      field: 'creation_datetime',
      headerName: 'Création',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
    {
      field: 'last_modification_datetime',
      headerName: 'Dernière modification',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
  ];

  const handleFormRedirect = () => {
    navigate('/element-biodiversite');
  };

  return (
    <Box component='main' mx={3} mt={2}>
      <Typography mb={1} variant='h5' component='div'>
        Éléments de biodiversité
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm='auto'>
          <Button variant='contained' onClick={handleFormRedirect} fullWidth>
            Ajouter un élément de biodiversité
          </Button>
        </Grid>
        <Grid item xs={12} sm='auto'>
          <CSVExportButton itemType={itemType} fullWidth />
        </Grid>
      </Grid>
      <CustomDataGrid columns={columns} rows={items} />
    </Box>
  );
}
